import { memo } from 'react';
import { ImgHTMLAttributes, SyntheticEvent, useRef } from 'react';

import { createImageState } from './state';
import { UiImageLazy } from './ui-image-lazy';
import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import { mergeComponents } from '@repo/common/utils/component';

export interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  maxRetry?: number;

  placeholder?: string;
}

const _UiImage = memo((props: Props) => {
  const { src, placeholder, onError, ...restProps } = props;

  const state = useRef(createImageState()).current;

  const [innerSrc, hasError] = [state.use.src(), state.use.hasError()];

  const [setup, fail] = [state.use.setup(), state.use.fail(), state.use.success()];

  useIsomorphicEffect(() => {
    if (!src) return;
    setup(src);
  }, [src]);

  const onInnerError = (node: SyntheticEvent<HTMLImageElement>) => {
    fail();
    onError?.(node);
  };

  // const _onInnerLoad = (_node: SyntheticEvent<HTMLImageElement>) => {
  //   success();
  //   // onLoadedData?.(node);
  // };

  return <img src={hasError && placeholder ? placeholder : innerSrc} onError={onInnerError} {...restProps} />;
});

export const UiImage = mergeComponents(_UiImage, {
  Lazy: UiImageLazy,
});
