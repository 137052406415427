import { useRef } from 'react';

import { useIsomorphicEffect } from '@repo/common/hooks/use-isomorphic-effect';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useUpdateMeMutation } from '@repo/modules/entity/user-queries';

import { useSelectBrandConfig } from '@/entities/brand';

import { CookiesDictionary } from '@/shared/constants';

export const useRedtrackClickId = () => {
  const updateMe = useUpdateMeMutation();
  const brandConfig = useSelectBrandConfig();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const isAlreadyHasId = useRef(false);

  const features = brandConfig?.features;

  useIsomorphicEffect(() => {
    if (!features?.third_party?.red_track?.src) return;

    const handleClickId = async () => {
      const rtkclickid = extractCookieValue(CookiesDictionary.RED_TRACK);

      if (!rtkclickid) return;

      if (isAlreadyHasId.current && intervalRef.current) {
        return clearInterval(intervalRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      await updateMe.mutateAsync({
        rtkclickid_store: rtkclickid,
      });
      isAlreadyHasId.current = true;
    };

    intervalRef.current = setInterval(handleClickId, 200);

    handleClickId();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [features?.third_party?.red_track?.src]);
};
