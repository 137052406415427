import { useFeatureGetter } from '../api';

const KEY = 'onboarding-features';

export type OnboardingFeaturesFlag = {
  canSkipStep: boolean;
};

export const useOnboardingFeaturesFlag = () => {
  return useFeatureGetter<OnboardingFeaturesFlag>(KEY, {
    canSkipStep: false,
  });
};
