import { NavItem } from '@/entities/layout';

import { Icon } from '@/shared/ui';

export const NAVIGATION_LINKS = ({
  isCreateAi,
  isCustomizeAi,
  enabledGenerateImage,
}: {
  enabledGenerateImage?: boolean;
  isCreateAi?: boolean;
  isCustomizeAi?: boolean;
}): NavItem[] => {
  const generateImage: NavItem[] = enabledGenerateImage
    ? [
        {
          label: 'Generate Image',
          to: '/generate-image',
          icon: <Icon k="picture" />,
        },
      ]
    : [];

  const createAi: NavItem[] = isCreateAi
    ? [
        {
          label: 'Create Ai',
          to: '/create-ai',
          icon: <Icon k="create" />,
        },
      ]
    : [];

  const customizeAi: NavItem[] = isCustomizeAi
    ? [
        {
          label: 'Customize Ai',
          to: '/customise-ai',
          icon: <Icon k="pen" />,
        },
      ]
    : [];

  const result: NavItem[] = [
    {
      label: 'Discover',
      to: '/discover',
      icon: <Icon k="discover" />,
    },
    {
      label: 'Chats',
      to: '/conversations',
      icon: <Icon k="chats" />,
      hasUnreadBadge: true,
    },
    ...createAi,
    ...customizeAi,
    ...generateImage,
    {
      label: 'Settings',
      to: '/settings',
      icon: <Icon k="settings" />,
    },
  ];
  return result;
};

export const NAVIGATION_LINKS_TAB_BAR = ({
  isCreateAi,
  isCustomizeAi,
  enabledGenerateImage,
}: {
  enabledGenerateImage?: boolean;
  isCreateAi?: boolean;
  isCustomizeAi?: boolean;
}): NavItem[] => {
  const generateImage: NavItem[] = enabledGenerateImage
    ? [
        {
          label: 'Generate',
          to: '/generate-image',
          icon: <Icon k="picture" />,
        },
      ]
    : [];

  const createAi: NavItem[] = isCreateAi
    ? [
        {
          label: 'Create Ai',
          to: '/create-ai',
          icon: <Icon k="create" />,
        },
      ]
    : [];

  const customizeAi: NavItem[] = isCustomizeAi
    ? [
        {
          label: 'Customize Ai',
          to: '/customise-ai',
          icon: <Icon k="pen" />,
        },
      ]
    : [];

  const result: NavItem[] = [
    {
      label: 'Discover',
      to: '/discover',
      icon: <Icon k="discover" />,
    },
    {
      label: 'Chats',
      to: '/conversations',
      icon: <Icon k="chats" />,
      hasUnreadBadge: true,
    },
    ...createAi,
    ...customizeAi,
    ...generateImage,
    {
      label: 'Settings',
      to: '/settings',
      icon: <Icon k="settings" />,
    },
  ];
  return result;
};
