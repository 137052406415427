import { useMemo } from 'react';
import { useCallback } from 'react';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';

import { UiButton } from '../../../ui-button';
import { UiButtonProps } from '../../../ui-button';
import { UiIcon } from '../../../ui-icon';
import { UiTypography } from '../../../ui-typography';
import { useDialog } from '../../_hooks';
import { dialog } from '../../_model';
import { AlertProps } from '../../types';
import { DialogContent } from '../dialog-content';
import { FloatingWrapper } from '../floating-wrapper';
import type { Nullable } from '@repo/common/types/helpers';
import { isPrimitiveNode } from '@repo/common/utils/component';
import { renderSlot } from '@repo/common/utils/component';
import { cn } from '@repo/common/utils/component';

export interface Props extends AlertProps {}

export const Alert = (props: Props) => {
  const {
    id,
    titleSlot,
    subtitleSlot,
    okSlot,
    cancelSlot,
    hiddenCancelBtn,
    hiddenOkBtn,
    onOk,
    onCancel,
    cancelProps,
    okProps,
    cancelText,
    okText,
    className,
    classNames,
    closeBtnProps,
    showCloseBtn = false,
    closeOnEsc = true,
    variant = 'warning',
    closeDelayMs = null,
    onClose,
    onMount,
    onUnmount,
    onExit,
    ...restProps
  } = props;

  const closeTimeout = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  const onInternalClose = useCallback(() => {
    if (onClose) {
      return onClose(id);
    }
    dialog.close(id);
  }, [onClose, id]);

  const { context, refs, getFloatingProps } = useDialog({
    isOpen: true,
    onClose: onInternalClose,
    id,
    closeOnEsc,
  });

  useLayoutEffect(() => {
    if (closeDelayMs) {
      closeTimeout.current = setTimeout(onInternalClose, closeDelayMs);
    }
    onMount?.();

    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
        closeTimeout.current = null;
      }
      onUnmount?.();
    };
  }, []);

  const onInnerOkClick = useCallback(() => {
    onOk?.();
    onInternalClose();
  }, [onOk, onInternalClose]);
  const onInnerCancelClick = useCallback(() => {
    onCancel?.();
    onInternalClose();
  }, [onCancel, onInternalClose]);

  const _okProps: UiButtonProps = useMemo(
    () => ({
      onClick: onInnerOkClick,
      children: okText ?? 'Ok',
      variant: 'secondary',
      ...okProps,
    }),
    [okProps, okText, onInnerOkClick],
  );
  const _cancelProps: UiButtonProps = useMemo(
    () => ({
      onClick: onInnerCancelClick,
      children: cancelText ?? 'Cancel',
      variant: 'grey',
      ...cancelProps,
    }),
    [cancelProps, cancelText, onInnerCancelClick],
  );

  const _okSlot = useMemo(() => {
    if (okSlot) return renderSlot(okSlot);
    return <UiButton {..._okProps} />;
  }, [okSlot, _okProps]);

  const _cancelSlot = useMemo(() => {
    if (cancelSlot) return renderSlot(cancelSlot);
    return <UiButton {..._cancelProps} />;
  }, [cancelSlot, _cancelProps]);

  const _titleSlot = useMemo(() => {
    if (isPrimitiveNode(titleSlot))
      return (
        <UiTypography variant="3xl" weight="semibold" className="ui-text-primary">
          {titleSlot}
        </UiTypography>
      );

    return renderSlot(titleSlot);
  }, [titleSlot]);

  const _subtitleSlot = useMemo(() => {
    if (isPrimitiveNode(subtitleSlot))
      return (
        <UiTypography variant="2sm" className="ui-text-secondary">
          {subtitleSlot}
        </UiTypography>
      );

    return renderSlot(subtitleSlot);
  }, [subtitleSlot]);

  return (
    <FloatingWrapper onExit={onExit} id={id} context={context}>
      <DialogContent
        onExit={onExit}
        innerRef={refs.setFloating}
        closeBtnProps={closeBtnProps}
        showCloseBtn={showCloseBtn}
        onClose={onInternalClose}
        fullScreen={false}
        {...getFloatingProps({
          className: cn('ui-z-alert', classNames?.root, className),
          ...restProps,
        })}
      >
        <div className="ui-text-center ui-max-w-[400px] ui-w-[93vw] ui-p-4 tablet:ui-pt-8">
          {variant && <UiIcon k="warning" className="ui-text-brand-light ui-text-[56px]" />}
          <div className="ui-pt-3 tablet:ui-pt-5 ui-flex ui-flex-col ui-gap-2">
            {_titleSlot}
            {_subtitleSlot}
          </div>
          <div className="ui-flex ui-items-center ui-gap-2 grid-cols-2 ui-pt-6">
            {!hiddenCancelBtn && _cancelSlot}
            {!hiddenOkBtn && _okSlot}
          </div>
        </div>
      </DialogContent>
    </FloatingWrapper>
  );
};
