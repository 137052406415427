import { HTMLAttributes } from 'react';

import { Typography } from '../../../../common';
import { CloseBtn } from '../../_close-btn';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  closeBtnClass?: string;
  withCloseBtn?: boolean;
}

export const Header = (props: Props) => {
  const { label, className, closeBtnClass, children, withCloseBtn = true, ...restProps } = props;

  return (
    <div className={cn('flex justify-between items-center px-6 pt-6', className)} {...restProps}>
      <div>
        {label && (
          <Typography variant="4xl" weight="bold">
            {label}
          </Typography>
        )}
        {children}
      </div>
      {withCloseBtn && <CloseBtn iconSize="md" variant="grey" className={cn(closeBtnClass)} />}
    </div>
  );
};
