import { HTMLAttributes, useEffect, useState } from 'react';

import './styles.scss';

import { useBrandFeatures } from '@/entities/brand';
import { ModalBlurredInfo } from '@/entities/modals';

import { cn } from '@/shared/libs/utils';
import { BlurCover } from '@/shared/ui';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  imgSrc?: string;

  imgWidth?: number | string;

  imgHeight?: number | string;

  isBlurred?: boolean;
}

export const ModalPreviewImg = (props: Props) => {
  const { className, imgSrc, isBlurred, imgWidth, imgHeight, ...restProps } = props;

  const features = useBrandFeatures();

  const { 0: isDropCover, 1: setIsBlurCover } = useState(false);

  const blurIntent = features?.blur_image_intent ?? 'blur';

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isBlurred) {
        setIsBlurCover(true);
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className={cn(
        'modal-preview-image relative bg-cover bg-center bg-no-repeat ',
        'after:absolute after:h-1/2 after:w-full after:bottom-[-2px] after:left-0',
        className,
      )}
      {...restProps}
    >
      <BlurCover
        className={cn('w-full h-full', { 'rounded-2xl sm:rounded-none h-[calc(100%_-_4rem)] sm:h-full': isBlurred })}
        intent={isBlurred ? blurIntent : 'none'}
        dropCover={isDropCover}
        blur="xl"
      >
        <img
          alt="Character"
          src={imgSrc}
          width={imgWidth}
          height={imgHeight}
          className="w-full h-full object-cover object-top"
        />
        {isBlurred && <ModalBlurredInfo />}
      </BlurCover>
    </div>
  );
};
