import * as Sentry from '@sentry/react';
import merge from 'lodash.merge';

export class SentryService {
  public readonly defaultSentryOptions = {
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Script error.',
      '_avast_submit',
    ],
    denyUrls: [
      /pagead\/js/i,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /127\.0\.0\.1:4001\/isrunning/i,
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  };

  private readonly sentryInitOptions;

  private readonly defaultUserOptions = {
    id: 1,
    name: 'test-user',
  };

  constructor(sentryInitOptions: Sentry.BrowserOptions) {
    this.sentryInitOptions = sentryInitOptions;
  }

  public init = (options?: Partial<Sentry.BrowserOptions>) => {
    if (window.location.hostname === 'localhost') return;

    const sentryOptions = this.mergeSentryOptions(options ?? {});

    Sentry.init({
      ...sentryOptions,
      ...this.sentryInitOptions,
    });
    this.createUser(this.defaultUserOptions);
  };

  public createUser(userOptions: { id: number | string; name: string }) {
    return Sentry.setUser({
      ...this.defaultUserOptions,
      ...userOptions,
    });
  }

  public captureException(error: unknown) {
    Sentry.captureException(error);
  }

  private mergeSentryOptions(options: Sentry.BrowserOptions) {
    return merge(this.defaultSentryOptions, options);
  }
}

const createSentryService = () => {
  let sentryService: SentryService;
  return (options?: Sentry.BrowserOptions) => {
    if (!sentryService) {
      sentryService = new SentryService(options ?? {});
    }
    return sentryService;
  };
};

export const sentryService = createSentryService();
