import { ComponentPropsWithoutRef, MouseEventHandler, memo } from 'react';

import './styles.scss';

import { cn } from '@/shared/libs/utils';
import { Card, Typography } from '@/shared/ui';

export interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'onClick'> {
  imgSrc: string;

  label: string;

  isActive?: boolean;

  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const BadgeWithImage = memo((props: Props) => {
  const { imgSrc, label, isActive = false, onClick, ...restProps } = props;

  return (
    <Card isActive={isActive} className="badge-with-image relative flex shrink-0 flex-col" {...restProps}>
      <button onClick={onClick} className="absolute w-full h-full z-2" />
      <div className="badge-with-image__img shrink overflow-hidden">
        <img src={imgSrc} className="w-full object-cover" />
      </div>
      <div
        className={cn('badge-with-image__label', 'flex flex-col items-center justify-center w-full p-3 text-center')}
      >
        <Typography className="text-inherit" as="span" weight="bold">
          {label}
        </Typography>
      </div>
    </Card>
  );
});
