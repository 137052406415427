import { AvatarRoot, AvatarRootProps } from '@ark-ui/react';
import { cn } from '@repo/common/utils/component';
import { VariantProps, cva } from 'class-variance-authority';

const avatarRoot = cva('ui-avatar-root ui-overflow-hidden', {
  variants: {
    size: {
      sm: 'ui-size-8',
      md: 'ui-size-11',
      lg: 'ui-size-12',
      xl: 'ui-size-16',
    },
    rounded: {
      base: 'ui-avatar-rounded-base',
      sm: 'ui-rounded-sm ui-avatar-rounded-sm',
      md: 'ui-rounded-md ui-avatar-rounded-md',
      lg: 'ui-rounded-lg ui-avatar-rounded-lg',
      xl: 'ui-rounded-xl ui-avatar-rounded-xl',
      full: 'ui-rounded-full ui-avatar-rounded-full',
    },
  },
});

export interface Props extends AvatarRootProps, VariantProps<typeof avatarRoot> {}

export const UiAvatarRoot = (props: Props) => {
  const { className, size = 'lg', rounded = 'sm', ...restProps } = props;
  return <AvatarRoot className={cn(avatarRoot({ rounded, size }), className)} {...restProps} />;
};
