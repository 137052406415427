import { client } from './client';
import { FeatureBookItems } from '@repo/common/services/features-book';

export const getFeaturesBook = async () => {
  try {
    const response = await client.get<FeatureBookItems>('features/list');

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export type ExperimentViewedPayload = {
  experiment_key: string;
  variation_id: number;
  timestamp: string;
};

export const postExperimentViewed = async (payload: ExperimentViewedPayload) => {
  try {
    await client.post<unknown, unknown, { results: ExperimentViewedPayload[] }>('/features/experiment-viewed', {
      results: [payload],
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
