import { useFeatureGetter } from '../api';

const KEY = 'auth_flow_variant';

export enum AuthFlowVariantFlag {
  NONE,
  AUTH_BEFORE_ACTION,
  AUTH_REQUIRED,
}

export const useAuthFlowVariantGetter = () => {
  return useFeatureGetter<number>(KEY, AuthFlowVariantFlag.NONE);
};
