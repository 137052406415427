import { CharacterEntity } from '@repo/api/chat';

export const characterAdapter = (character: CharacterEntity) => {
  const { name, age, picture_url, bio, public_pic_urls = [], override, interests = [], ...other } = character;

  const {
    age: overrideAge,
    bio: overrideBio,
    name: overrideName,
    picture_url: overridePictureUrl,
    public_pic_urls: overridePublicPicUrls,
    interests: overrideInterests,
    ...otherOverride
  } = override ?? {};

  return {
    ...other,
    interests: overrideInterests ?? interests ?? [],
    age: overrideAge ?? age,
    bio: overrideBio ?? bio,
    name: overrideName ?? name,
    picture_url: overridePictureUrl ?? new URL(picture_url).href,
    public_pic_urls: overridePublicPicUrls?.map((url) => new URL(url).href) ?? public_pic_urls ?? [],
    override,
    character: {
      sexy_meter: 5,
      fun_meter: 5,
      mystic_meter: 5,
      ...(otherOverride ?? {}),
    },
  };
};

export const charactersAdapter = (characters: CharacterEntity[]) => {
  return characters.map(characterAdapter);
};

export type CharacterAdaptedEntity = ReturnType<typeof characterAdapter>;
