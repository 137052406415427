import { ChatRepository } from '@repo/api/chat';
import { InnerUseQueryOptions } from '@repo/common/types/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CharacterAdaptedEntity, charactersAdapter } from '../../../shared/adapters';

const KEY = 'CHARACTER_LIST';

export const useGetCharacterList = (options?: InnerUseQueryOptions<CharacterAdaptedEntity[]>) => {
  return useQuery({
    ...options,
    queryKey: [KEY],
    queryFn: async () => {
      const res = await ChatRepository.getCharacters();
      return charactersAdapter(res);
    },
  });
};

export const useInvalidateCharacterListQuery = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};

export const invalidateCharacterListQuery = (qc: QueryClient) => qc.invalidateQueries({ queryKey: [KEY] });

export const characterListQueryKey = () => [KEY];
