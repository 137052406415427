import { enableGenerateImagePageKey } from '@repo/common/services/features-book';
import { redirect } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_layout/_generate-image/generate-image')({
  beforeLoad: ({ context }) => {
    const { featuresGetter } = context;

    const val = featuresGetter(enableGenerateImagePageKey(), true);

    if (!val) {
      throw redirect({ to: '/discover' });
    }
  },
});
