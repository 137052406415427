import ReactDOM from 'react-dom/client';

import './polyfills';
import { LocalStorageKey, localStorageService } from '@/core/services/storage';
import { UiLcpHack } from '@repo/ui-kit/ui-lcp-hack';

import { App } from '@/app/app';

import { authStorageGetters } from '@/entities/auth';

import { createApiClient } from '@/shared/api/client';

import '@repo/ui-kit/styles.css';

import '@repo/modules/styles.css';

import './styles/index.css';

localStorageService.setItem(LocalStorageKey.Consent, true);

createApiClient(authStorageGetters.getToken);

const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <>
      <UiLcpHack />
      <App />
    </>,
  );
}
