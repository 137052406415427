import { useLayoutEffect } from 'react';

import { useInvalidateFeaturesBook } from '@repo/common/services/features-book';

import { IAuthStorageValue, authStorageEvents } from '@/entities/auth';

import { Analytic } from '@/shared/services/analytic';
import { Nullable } from '@/shared/types';

export const useWatchAuthStorageUpdating = () => {
  const invalidateFeaturesBook = useInvalidateFeaturesBook();

  const onSubChange = ({ sub }: { sub?: Nullable<string> }) => {
    if (!sub) return;
    Analytic.setUserId(sub);
  };

  const onTokenChange = ({ token }: { token?: Nullable<string> }) => {
    if (!token) return;

    invalidateFeaturesBook();
  };

  const onAuthChange = ({ sub, token }: Partial<IAuthStorageValue>) => {
    onTokenChange({ token });
    onSubChange({ sub });
  };

  useLayoutEffect(() => {
    const disposer = authStorageEvents.onAuthChange(onAuthChange);

    return () => {
      disposer();
    };
  }, []);
};
