import { createContext, useContext } from 'react';

import type { FeatureBookItems, FeaturesBookStatus } from './types';

interface ContextValue {
  features: FeatureBookItems;
  status: FeaturesBookStatus;
  invalidate: () => void;
}

export const FeaturesBookContext = createContext<ContextValue | null>(null);

export const useFeaturesBookContext = () => {
  const ctx = useContext(FeaturesBookContext);

  if (ctx === null) {
    throw new Error('GroupBookProvider was not found in the tree');
  }

  return ctx;
};
