import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Root } from '@radix-ui/react-scroll-area';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<typeof Root> {}

export const UiScrollAreaRoot = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, ...restProps } = props;
  return <Root ref={ref} className={cn('ui-scroll-area-root ui-overflow-hidden', className)} {...restProps}></Root>;
});
