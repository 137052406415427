import { HTMLAttributes, SVGProps, memo, useMemo } from 'react';

import { UiIconDictionary } from './ui-icon-dictionary';
import loadable from '@loadable/component';
import { cn } from '@repo/common/utils/component';

export const getIcon = (k: UiIconDictionary) => {
  return loadable(() => import(`./source/${k}.svg?react`));
};

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  svgProps?: SVGProps<SVGSVGElement>;
  k: UiIconDictionary;
}

export const UiIcon = memo((props: Props) => {
  const { k, svgProps, className, ...restProps } = props;

  const Component = useMemo(() => getIcon(k), [k]);

  return (
    <span className={cn('ui-inline-flex ui-items-center ui-justify-center', className)} {...restProps}>
      <Component {...svgProps} />
    </span>
  );
});
