import { chatStorage } from '@repo/modules/entities/chat/services';
import { createFileRoute } from '@tanstack/react-router';

import { conversationSearchSchema } from '@/entities/conversation';

export const Route = createFileRoute('/_layout/_layout-conversations/conversations')({
  validateSearch: conversationSearchSchema,
  onEnter: () => {
    if (!chatStorage.getVisitedChats()) {
      chatStorage.setVisitedChats(true);
    }
  },
});
