import { StoreApi, UseBoundStore } from 'zustand';

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

/**
 * The function `createSelectors` creates selectors based on the state keys of a store and returns an
 * updated store with the selectors attached.
 *
 * Usage example:
 *
 * ```ts
 * interface BearState {
 *  bears: number;
 *  increase: (by: number) => void;
 *  increment: () => void;
 * }
 *
 * const useBearStoreBase = create<BearState>()((set) => ({
 *   bears: 0,
 *   increase: (by) => set((state) => ({ bears: state.bears + by })),
 *   increment: () => set((state) => ({ bears: state.bears + 1 })),
 * }))
 *
 * const useBearStore = createSelectors(useBearStoreBase);
 *
 * // get the property
 * useBearStore.use.bears();
 *
 * // get the action
 * useBearStore.use.increment();
 * ```
 */
export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  let store = _store as WithSelectors<typeof _store>;

  store.use = {};
  for (let k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};
