import { useFeatureGetter } from '@repo/common/services/features-book';

export const useCustomiseAi = () => {
  const customizeAi = useFeatureGetter('customise-ai', {
    enabled: false,
    banner: {
      title: 'Edit models to suit your needs',
      imgSmUrl: '',
      imgLgUrl: '',
      bgUrl: '',
    },
  });
  return customizeAi();
};
