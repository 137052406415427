import { LocalStorageKey, localStorageService } from '@/core/services';
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: EntryIndexComponent,
  beforeLoad: ({ context }) => {
    const system = context?.brandConfig?.features?.system;

    const rootPath = system?.root_path ?? '/conversations';

    if (system && system.can_skip_onboarding) {
      localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
      throw redirect({
        to: rootPath,
      });
    }

    if (context.isPassOnboarding()) {
      throw redirect({
        to: rootPath,
      });
    }

    if (!context.isPassOnboarding()) {
      throw redirect({
        to: '/onboarding',
      });
    }
  },
});

function EntryIndexComponent() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
