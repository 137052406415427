import { memo } from 'react';

import { SpinnerProps } from '../type';
import { cn } from '@repo/common/utils/component';

export const SimpleKey = 'simple';

export interface Props extends SpinnerProps {}

export const Simple = memo((props: Props) => {
  const { className, style, ...restProps } = props;

  const styles = {
    '--color': 'var(--color-primary-spinner)',
    '--size': '84px',
    '--size-b': '4px',
    borderTop: 'var(--size-b) solid var(--color)',
    borderRight: 'var(--size-b) solid transparent',
    ...style,
  };

  return (
    <span
      style={styles}
      className={cn('inline-block rounded-[50%] size-[--size] animate-spin', className)}
      {...restProps}
    ></span>
  );
});
