import type { CSSProperties, HTMLAttributes } from 'react';

import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const header = cva('', {
  variants: {
    border: {
      none: '',
      bottom: 'border-b',
      top: 'border-t',
      left: 'border-l',
      right: 'border-r',
      full: 'border',
      bottomLeft: 'border-b border-l',
      bottomRight: 'border-b border-r',
    },
  },
  defaultVariants: {
    border: 'bottom',
  },
});

export interface Props extends HTMLAttributes<HTMLElement>, VariantProps<typeof header> {
  h?: CSSProperties['height'];
}

export const Header = (props: Props) => {
  const { className, style, border = 'bottom', h = 68, ...restProps } = props;

  const innerStyle = {
    ...style,
    height: h,
    flexBasis: h,
  };

  return (
    <header
      className={cn('section-view-header p-3 border-grey-600 flex items-center', header({ border }), className)}
      style={innerStyle}
      {...restProps}
    />
  );
};
