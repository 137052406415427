import { ChangeEvent, FormEventHandler, useEffect, useState } from 'react';

import { queryClient } from '@/core/config/react-query';
import { PAYMENT_STATUS } from '@/core/constants';
import { invalidateGetMeQuery } from '@repo/modules/entity/user-queries';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { z } from 'zod';
import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';

interface CheckoutFormState {
  status: PAYMENT_STATUS;
  error: string | null;
  email: string;
}

interface CheckoutFormAction {
  setError: (error: string | null) => void;
  setStatus: (status: PAYMENT_STATUS) => void;
  setEmail: (email: string) => void;
}

const initialState: CheckoutFormState = {
  status: PAYMENT_STATUS.IDLE,
  error: null,
  email: '',
};

const useCheckoutFormBase = create<CheckoutFormState & CheckoutFormAction>()((set) => ({
  ...initialState,

  setError: (error) => set({ error }),

  setStatus: (status) => set({ status }),

  setEmail: (email) => set({ email }),
}));

const useCheckoutForm = createSelectors(useCheckoutFormBase);

const emailSchema = z.string().email({ message: 'Invalid email address' });

interface UseCheckoutFormControllerProps {
  onSuccess?: () => void;
  onFailed?: (err: string | undefined) => void;
  onBeforeSubmit?: () => void;
}

export const useCheckoutFormController = (props?: UseCheckoutFormControllerProps) => {
  const { onSuccess, onFailed } = props || {};
  const { data: viewer } = useGetMeQuery();
  const [emailError, setEmailError] = useState<string>('');

  const {
    0: status,
    1: error,
    2: email,
  } = [useCheckoutForm.use.status(), useCheckoutForm.use.error(), useCheckoutForm.use.email()];
  const {
    0: setStatus,
    1: setError,
    2: setEmail,
  } = [useCheckoutForm.use.setStatus(), useCheckoutForm.use.setError(), useCheckoutForm.use.setEmail()];

  const { 0: isReadyPayment, 1: setIsReadyPayment } = useState(false);
  const { 0: isCompletedPaymentForm, 1: setIsCompletedPaymentForm } = useState(false);
  const { 0: isCompletedAddressForm, 1: setIsCompletedAddressForm } = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const processStripeError = (error: StripeError) => {
    const errMsg = error.message ?? '';
    setStatus(PAYMENT_STATUS.FAILED);
    setError(errMsg);
    onFailed?.(errMsg);
  };

  const processStripeSuccess = async () => {
    await new Promise((res) => {
      setTimeout(() => {
        invalidateGetMeQuery(queryClient);
        res(null);
      }, 3000);
    });
    onSuccess?.();
    setStatus(PAYMENT_STATUS.SUCCESS);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setStatus(PAYMENT_STATUS.PENDING);

    if (!stripe || !elements) {
      return;
    }

    setStatus(PAYMENT_STATUS.IN_PROGRESS);

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        return_url: window.location.href,
        payment_method_data: {
          billing_details: {
            email: email,
          },
        },
      },
    });

    if (result.error) {
      processStripeError(result.error);
    } else {
      processStripeSuccess();
    }
  };

  const onChangePaymentElement = (event: StripePaymentElementChangeEvent) => {
    setIsCompletedPaymentForm(event.complete);
  };

  const onChangeAddressElement = (event: StripeAddressElementChangeEvent) => {
    setIsCompletedAddressForm(event.complete);
  };

  const onReadyPaymentElement = () => {
    setIsReadyPayment(true);
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    const emailValidation = emailSchema.safeParse(value);
    if (!emailValidation.success) {
      setEmailError(emailValidation.error.errors[0].message);
    } else {
      setEmailError('');
    }
  };

  const isDisabledSubmit =
    !isReadyPayment ||
    !isCompletedPaymentForm ||
    !isCompletedAddressForm ||
    !email?.length ||
    Boolean(emailError.length) ||
    status === PAYMENT_STATUS.IN_PROGRESS;

  useEffect(() => {
    if (viewer?.email) {
      setEmail(viewer.email);
    }
  }, [viewer?.email]);

  return {
    error,
    status,
    email,
    emailError,
    isReadyPayment,
    isCompletedPaymentForm,
    isDisabledSubmit,
    handleSubmit,
    onChangePaymentElement,
    onReadyPaymentElement,
    onChangeAddressElement,
    onChangeEmail,
  };
};
