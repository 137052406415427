import {
  CloseDialogArgs,
  CloseDialogFunc,
  DialogStackValue,
  DialogState,
  HideComponentFunc,
  OpenDialogArgs,
  OpenDialogFunc,
} from '../types';
import { create } from 'zustand';

import { createSelectors } from '@/shared/libs/zustand-helpers';

const initialDialogState: DialogState = {
  stack: [],
};

export const useDialogBase = create<DialogState>()(() => ({
  ...initialDialogState,
}));

export const useDialogState = createSelectors(useDialogBase);

const toggleHiddenDialog: HideComponentFunc = (args) => {
  const { key } = args;

  useDialogBase.setState((state) => {
    const updatedStack = state.stack.map((dialog) => {
      if (dialog.key === key) {
        return {
          ...dialog,
          isHidden: !dialog.isHidden,
        };
      }
      return dialog;
    });
    return {
      ...state,
      stack: updatedStack,
    };
  });
};

const closeDialog: CloseDialogFunc = (args) => {
  const { key, afterClose } = args;

  new Promise((resolve) => {
    useDialogBase.setState((state) => {
      const updatedStack = state.stack.filter((item) => item.key !== key);

      return {
        ...state,
        stack: updatedStack.map((item, idx, arr) => {
          if (idx === arr.length - 1) {
            return {
              ...item,
              isHidden: false,
            };
          }
          return item;
        }),
      };
    });
    resolve(null);
  }).then(() => {
    afterClose?.();
  });
};

const updateStack = (stack: DialogStackValue[], args: Omit<OpenDialogArgs, 'beforeOpen'>) => {
  const { key, component, afterClose } = args;

  const existSameKey = stack.some((item) => item.key === key);

  if (existSameKey) {
    return stack.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          close: () => closeDialog({ key, afterClose }),
          component,
          isHidden: false,
        };
      }
      return item;
    });
  }
  return [...stack, { key, component, isHidden: false, close: () => closeDialog({ afterClose, key }) }];
};

const openDialog: OpenDialogFunc = (args) => {
  const { key, beforeOpen, afterClose } = args;

  new Promise((resolve) => {
    beforeOpen?.();
    resolve(null);
  }).then(() => {
    useDialogBase.setState((state) => {
      const updatedStack = updateStack(state.stack, args);

      return {
        ...state,
        stack: updatedStack.map((item) => {
          if (item.key === key) {
            return {
              ...item,
              component: item.component,
              isHidden: false,
            };
          }
          return { ...item, isHidden: true };
        }),
      };
    });
  });

  return (closeArgs?: Omit<CloseDialogArgs, 'key'>) => closeDialog({ key, afterClose, ...closeArgs });
};

export const dialog = {
  open: openDialog,
  close: closeDialog,
  toggleHidden: toggleHiddenDialog,
};
