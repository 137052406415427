import { HTMLAttributes } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Content = (props: Props) => {
  const { className, ...restProps } = props;

  return <div className={cn('w-full', className)} {...restProps} />;
};
