import { memo } from 'react';

import { useDialogState } from './_model';
import { Alert } from './_ui/alert';
import { Modal } from './_ui/modal';
import { DialogType } from './types';
import { Provider } from './ui-dialog-context';
import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';

export const UiDialogContainer = memo(() => {
  const stack = useDialogState.use.stack();

  return (
    <FloatingPortal id="dialog-portal">
      <AnimatePresence initial={false} mode="wait">
        {stack.map((item) => {
          if (item.isHidden) return null;

          const contextValue = { id: item.id, onClose: item.onClose };

          switch (item.type) {
            case DialogType.Modal:
              return (
                <Modal id={item.id} key={item.id} {...item?.props}>
                  <Provider value={contextValue}>{item.content}</Provider>
                </Modal>
              );
            case DialogType.Alert:
              return (
                <Alert id={item.id} key={item.id} {...item?.props}>
                  <Provider value={contextValue}>{item.content}</Provider>
                </Alert>
              );
            default:
              return null;
          }
        })}
      </AnimatePresence>
    </FloatingPortal>
  );
});
