import { characterRepository } from '../repository';
import { queryClient } from '@/core/config/react-query';
import { invalidateCharacterListQuery } from '@repo/modules/entity/chat-queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invalidateGetConversations } from '@/entities/conversation';

export const useUpdateCharacterMutation = () => {
  return useMutation({
    mutationFn: characterRepository.updateCharacter,
    onSuccess: async () => {
      await invalidateGetConversations();
      await invalidateCharacterListQuery(queryClient);
    },
  });
};

export const useCreateCharacterMutation = () => {
  return useMutation({
    mutationFn: characterRepository.createCharacter,
  });
};

export const useGenerateCharacterPhotoMutation = () => {
  return useMutation({
    mutationFn: characterRepository.generateCharacterPhoto,
  });
};

export const useUploadCharacterPhotoMutation = () => {
  const qc = useQueryClient();
  return useMutation({
    mutationFn: characterRepository.uploadCharacterPhoto,
    onSuccess: () => {
      invalidateCharacterListQuery(qc);
    },
  });
};
