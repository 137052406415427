import { memo } from 'react';

import { useRootLayoutContext } from '../../../libs';
import DoubleCaret from '@/assets/icons/double-caret.svg?react';

import { cn } from '@/shared/libs/utils';

export const Trigger = memo(() => {
  const { isOpenSidebar, onToggleSidebar } = useRootLayoutContext();
  return (
    <div className="pt-3 flex items-center justify-end w-full">
      <button
        className="flex items-center max-w-[48px] justify-center transition-colors py-1 w-full bg-grey-700 rounded-md hover:bg-grey-600/50"
        onClick={onToggleSidebar}
        aria-label="Toggle Sidebar"
      >
        <span
          className={cn('transition-transform ease-linear duration-300', {
            'rotate-180': isOpenSidebar,
          })}
        >
          <DoubleCaret />
        </span>
      </button>
    </div>
  );
});
