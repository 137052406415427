import { ChangeEventHandler, useCallback, useDeferredValue } from 'react';
import { useForm } from 'react-hook-form';

import { DIALOG_KEYS, SubscriptionTier } from '@/core/constants';
import onboardingJSON from '@/onboarding.json';
import { zodResolver } from '@hookform/resolvers/zod';
import { SUBSCRIPTION_PLAN } from '@repo/api/payment';
import {
  AuthFlowVariantFlag,
  useFeatureGetter,
  useGlobalFeatureFlagContext,
} from '@repo/common/services/features-book';
import { useViewerSubscriptionPlan } from '@repo/modules/entity/user-queries';
import { z } from 'zod';

import { BuySubscriptionModal, authModalAction } from '@/features/modals';

import { authStorageGetters } from '@/entities/auth';
import {
  CharacterAdapted,
  UpdateCharacterDto,
  UpdateCharacterSchema,
  useUpdateCharacterMutation,
  useUploadCharacterPhotoMutation,
} from '@/entities/character';
import { OnboardingStage, onboardingStageFactory } from '@/entities/onboarding';

import { isBlob } from '@/shared/libs/guards';
import { Nullable } from '@/shared/types';
import { dialog } from '@/shared/ui';

const EditFormSchema = UpdateCharacterSchema.omit({ picture_url: true }).extend({
  picture_url: z.string().or(z.instanceof(Blob)),
});

export type EditFormValues = z.infer<typeof EditFormSchema>;

const onboardingStages = onboardingStageFactory.createStages(onboardingJSON.stages as OnboardingStage[]);

export const interests = onboardingStages.find(onboardingStageFactory.isBadgeStage)?.options ?? [];

export const characterOptions = onboardingStages.find(onboardingStageFactory.isSlidersStage)?.options ?? [];

const defaultCharacterValue = characterOptions.reduce((acc: UpdateCharacterDto['character'], option) => {
  acc[option.meter] = 5;
  return acc;
}, {});

const createDefaultValues = (character: Nullable<CharacterAdapted>) => {
  return {
    name: character?.name ?? '',
    age: character?.age ?? 18,
    bio: character?.bio ?? '',
    picture_url: character?.picture_url ?? '',
    interests: character?.interests?.map((interest) => interest.trim()) ?? [],
    public_pic_urls: character?.public_pic_urls ?? [],
    character: character?.character ?? defaultCharacterValue,
  };
};

export const useEditFormController = (
  character: Nullable<CharacterAdapted>,
  chatId?: string,
  closeEdit?: () => void,
) => {
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<EditFormValues>({
    resolver: zodResolver(EditFormSchema),
    defaultValues: createDefaultValues(character),
  });

  const subscriptionPlan = useViewerSubscriptionPlan();

  const { mutateAsync: uploadPhoto, isPending: isPendingUpload } = useUploadCharacterPhotoMutation();

  const { mutateAsync: updateCharacter, isPending: isPendingUpdate } = useUpdateCharacterMutation();

  const deferredIsDirty = useDeferredValue(isDirty);

  const plan = useFeatureGetter('edit-character-subscription-plan', 3);

  const hasAccessToUpdate =
    plan() === SUBSCRIPTION_PLAN.PRO
      ? subscriptionPlan === SUBSCRIPTION_PLAN.PRO
      : subscriptionPlan === SUBSCRIPTION_PLAN.PRO_PLUS;

  const { authFlowVariant } = useGlobalFeatureFlagContext();

  const withAuth = AuthFlowVariantFlag.AUTH_BEFORE_ACTION === authFlowVariant;

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const subscriptionVariant = plan() === SUBSCRIPTION_PLAN.PRO ? SubscriptionTier.PRO : SubscriptionTier.PRO_PLUS;

  const handleClickInterest = (interest: string) => {
    const interests = getValues('interests');

    const newValue = interests.includes(interest) ? interests.filter((i) => i !== interest) : [...interests, interest];

    setValue('interests', newValue, { shouldDirty: true });
  };

  const onSubmitPicture: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const file = event.target.files?.[0] ?? null;

    if (!file) return;

    setValue('picture_url', file, { shouldDirty: true });
  }, []);

  const onSubmit = handleSubmit(async (dto) => {
    if (!chatId) return Promise.reject();

    const { picture_url, ...restDto } = dto;

    let img: string;

    if (isBlob(picture_url)) {
      img = (await uploadPhoto(picture_url)).photoUrl;
    } else {
      img = picture_url;
    }

    await updateCharacter(
      { chatId, character: { ...restDto, picture_url: img } },
      {
        onSuccess: () => [reset(dto)],
      },
    );
    closeEdit?.();
  });

  const onOpenSubscription = (withSubmit = true) => {
    dialog.open({
      key: DIALOG_KEYS.BUY_SUBSCRIPTION,
      component: (
        <BuySubscriptionModal
          subscriptionVariant={subscriptionVariant}
          onSuccessPayment={() => {
            withSubmit && onSubmit();
          }}
          analyticData={{ placement: 'edit', persona_id: character?.id, persona_name: character?.name }}
        />
      ),
    });
  };

  const onClickSubmitBtn = () => {
    if (hasAccessToUpdate) return;

    if (subscriptionPlan === SUBSCRIPTION_PLAN.BASIC && !isAuthenticated && withAuth) {
      authModalAction.open(
        {
          onSuccess: (user) => {
            if (!user.is_subscribed) onOpenSubscription(true);
          },
          analyticData: { persona_id: character?.id, persona_name: character?.name },
        },
        {
          afterClose: () => onOpenSubscription(false),
        },
      );
      return;
    }

    onOpenSubscription(true);
  };

  const isSubmitButtonDisabled = !isDirty || isPendingUpdate || isPendingUpload;

  const observedValues = {
    interests: watch('interests'),
    character: watch('character'),
    picture: watch('picture_url'),
  };

  return {
    control,
    observedValues,
    isDirty: deferredIsDirty,
    isSubmitButtonDisabled,
    hasAccessToUpdate,
    handleClickInterest,
    onClickSubmitBtn,
    onSubmit,
    onSubmitPicture,
  } as const;
};
