import { Suspense } from 'react';

import * as Sentry from '@sentry/react';
import env from '@beam-australia/react-env';
import { GlobalFeatureFlagProvider } from '@repo/common/services/features-book';

import { AppInitialize } from '@/app/app-initialize';
import { AppProviders } from '@/app/app-providers';
import { AppRouter, router } from '@/app/app-router';

import { sentryService } from '@/shared/services/sentry/sentry.service';
import { SplashScreen } from '@/shared/ui';

const DSN = env('SENTRY_DSN');
const MODE = env('ENV_MODE');

const _sentry = sentryService({
  dsn: DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  beforeSendSpan(span) {
    if (span.description?.includes('node_modules')) {
      return null;
    }
    return span;
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.5,
  sampleRate: 0.5,
  environment: MODE,
});

_sentry.init();

export function App() {
  return (
    <Suspense fallback={<SplashScreen spinnerKey="line-wobble" />}>
      <AppProviders>
        <AppInitialize>
          <GlobalFeatureFlagProvider>
            <AppRouter />
          </GlobalFeatureFlagProvider>
        </AppInitialize>
      </AppProviders>
    </Suspense>
  );
}
