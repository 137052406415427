import { UpdateUserDTO, UserEntity, UserRepository } from '@repo/api/user';
import { useMutation } from '@tanstack/react-query';
import {InnerUseMutationOptions} from "@repo/common/types/react-query";

export const useUpdateMeMutation = (options?: InnerUseMutationOptions<UserEntity, unknown, UpdateUserDTO>) => {
  return useMutation({
    ...options,
    mutationFn: (dto) => UserRepository.updateMe(dto),
  });
};
