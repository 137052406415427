import { HTMLAttributes } from 'react';

import { useConversationDrawerContext } from '../../../libs';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Drawer = (props: Props) => {
  const { className, children, ...restProps } = props;

  const { isOpen } = useConversationDrawerContext();

  return (
    <div
      className={cn(
        'flex flex-col h-full border-l border-grey-600 bg-body overflow-hidden',
        'max-w-0 transition-max-w',
        'absolute tablet:relative max-w-full w-full right-0 top-0 translate-x-full transition-transform duration-150 ease-linear border-l-0 xl:border-l z-sidebar',
        '[--header-height:69px]',
        {
          'xl:max-w-[330px] translate-x-0': isOpen,
        },
        className,
      )}
      {...restProps}
    >
      <div className={cn('flex flex-col h-full grow overflow-hidden', 'xl:w-[330px] w-full')}>{children}</div>
    </div>
  );
};
