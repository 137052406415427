import { ButtonHTMLAttributes } from 'react';

import { UiIcon } from '../../../ui-icon';
import { cn } from '@repo/common/utils/component';
import { VariantProps, cva } from 'class-variance-authority';

const closeButton = cva(
  'ui-dialog-close-button ui-flex ui-items-center ui-cursor-pointer ui-justify-center ui-size-5 ui-rounded-sm ui-text-[10px]',
  {
    variants: {
      variant: {
        primary: 'ui-dialog-close-button-primary',
        secondary: 'ui-dialog-close-button-secondary',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof closeButton> {}

export const CloseButton = (props: Props) => {
  const { className, variant, ...restProps } = props;
  return (
    <button className={cn(closeButton({ variant }), className)} {...restProps}>
      <UiIcon k="cross" />
    </button>
  );
};
