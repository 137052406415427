import { HTMLAttributes, SVGProps } from 'react';

import { IconKey, getIcon } from './collection';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  k: IconKey;
  svgProps?: SVGProps<SVGSVGElement>;
}

export const Icon = (props: Props) => {
  const { k, svgProps, className, ...restProps } = props;

  const icon = getIcon(k);

  return (
    <span className={cn('inline-flex items-center justify-center', className)} {...restProps}>
      {/* {cloneElement(icon as any as ReactSVGElement, { ...svgProps })}
       */}
      {typeof icon === 'function' && icon({ ...svgProps })}
    </span>
  );
};
