import { HTMLAttributes, SVGProps, memo, useMemo } from 'react';

import type { UiAnimatedIconDictionary } from './ui-animated-icon-dictionary';
import loadable from '@loadable/component';
import { cn } from '@repo/common/utils/component';

export const getIcon = (k: UiAnimatedIconDictionary) => {
  return loadable(() => import(`./animated-source/${k}.svg?react`));
};

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  svgProps?: SVGProps<SVGSVGElement>;
  k: UiAnimatedIconDictionary;
}

export const UiAnimatedIcon = memo((props: Props) => {
  const { k, svgProps, className, ...restProps } = props;

  const Component = useMemo(() => getIcon(k), [k]);

  return (
    <span className={cn('ui-inline-flex ui-items-center ui-justify-center', className)} {...restProps}>
      <Component {...svgProps} />
    </span>
  );
});
