import { ReactNode, useEffect } from 'react';

import { ThemeSchema } from '../../models';
import { createTheme } from '../../services';

import { createSafeContext } from '@/shared/libs/context';

const [Provider, useTheme] = createSafeContext('ThemeContext');

interface Props {
  children: ReactNode;

  theme?: ThemeSchema;
}

const ThemeProvider = (props: Props) => {
  const { children, theme } = props;

  useEffect(() => {
    if (theme) {
      const service = createTheme(theme);

      service.injectStyleTag();
    }
  }, [theme]);

  return <Provider value={{}}>{children}</Provider>;
};

export { ThemeProvider, useTheme };
