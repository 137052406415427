import { type ReactNode, useLayoutEffect, useState } from 'react';
import { default as MD, Components as MDComponents, type Options as MDOptions } from 'react-markdown';

import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { cn, processReactNode } from '@/shared/libs/utils';
import type { Nullable } from '@/shared/types';
import { SpinnerIcon } from '@/shared/ui';

const DEFAULT_PLUGINS = [remarkGfm];

type LoadState = {
  loading: boolean;
  error?: unknown;
};

const DEFAULT_COMPONENTS: Partial<MDComponents> = {
  strong: (props) => {
    return <strong className="font-bold text-white" {...props} />;
  },
  h1: (props) => {
    return <h1 className="text-5xl font-bold py-3 text-white" {...props} />;
  },
  h2: (props) => {
    return <h2 className="text-4xl font-bold py-2 text-white" {...props} />;
  },
  h3: (props) => {
    return <h3 className="text-3xl font-bold py-1 text-white" {...props} />;
  },
  a: (props) => {
    return <a target="_blank" rel="noopener noreferrer" className="underline text-blue-300" {...props} />;
  },
  p: (props) => {
    return <p className="pt-1" {...props} />;
  },
};

export interface Props extends MDOptions {
  remoteSrc?: string;

  loader?: () => ReactNode | ReactNode;
}

export const Markdown = (props: Props) => {
  const { remoteSrc, children, loader, components, remarkPlugins, className, ...restProps } = props;

  const [file, setFile] = useState<Nullable<string>>(null);
  const [loadState, setLoadState] = useState<LoadState>({
    loading: false,
    error: null,
  });

  const loadSrc = async (src: string) => {
    setLoadState(() => ({ loading: true, error: null }));
    try {
      const response = await fetch(src);
      const file = await response.text();
      setFile(file);
      setLoadState(() => ({ loading: false, error: null }));
    } catch (error) {
      setLoadState(() => ({ loading: false, error: error }));
    }
  };

  useLayoutEffect(() => {
    if (loadState.loading) return;
    if (remoteSrc) loadSrc(remoteSrc);
  }, [remoteSrc]);

  const content = file || children;

  return (
    <>
      {loadState.loading ? (
        (processReactNode(loader) ?? <SpinnerIcon className="m-auto text-brand-primary" />)
      ) : (
        <MD
          rehypePlugins={[rehypeRaw]}
          className={cn(' text-secondary', className)}
          remarkPlugins={[...DEFAULT_PLUGINS, ...(remarkPlugins ?? [])]}
          components={{ ...DEFAULT_COMPONENTS, ...(components ?? {}) }}
          {...restProps}
        >
          {content}
        </MD>
      )}
    </>
  );
};
