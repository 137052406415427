import { HTMLAttributes } from 'react';

import { Spinner, SpinnerKey, SpinnerProps } from '../common';

import { cn } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  spinnerKey?: SpinnerKey;
  spinnerClassName?: string;
  spinnerProps?: Omit<SpinnerProps, 'className' | 'k'>;
  isClear?: boolean;
}

export const SplashScreen = (props: Props) => {
  const { className, spinnerKey = 'simple', spinnerClassName, spinnerProps, isClear = false, ...restProps } = props;

  return (
    <div
      className={cn(
        'fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-body z-splash-screen',
        {
          'bg-black/30': isClear,
        },
        className,
      )}
      {...restProps}
    >
      <Spinner k={spinnerKey} className={cn(spinnerClassName)} {...spinnerProps} />
    </div>
  );
};
