import { HTMLAttributes, ReactNode, useMemo } from 'react';

import './styles.scss';
import { isPrimitiveNode } from '@repo/common/utils/component';

import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui/common';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: ReactNode;

  description?: ReactNode;

  imgSrc: string;

  imgAlt?: string;

  imgProps?: HTMLAttributes<HTMLImageElement>;
}

export const CardWithImage = (props: Props) => {
  const { label, description, className, imgSrc, children, imgAlt, imgProps, ...restProps } = props;

  const { className: imgClassName, ...restImgProps } = imgProps || {};

  const labelNode = useMemo(() => {
    if (isPrimitiveNode(label)) {
      return (
        <Typography as="h5" variant="4xl" weight="bold" className="relative text-white text-primary-font sm:pt-4">
          {label}
        </Typography>
      );
    }

    return label;
  }, [label]);

  return (
    <div className={cn('relative flex flex-col', 'sm:flex-row', className)} {...restProps}>
      <div
        className={cn(
          'card-with-image',
          'relative basis-[44dvh] h-[44dvh] grow',
          'sm:basis-1/2 sm:h-[508px]',
          'before:absolute before:bottom-0 before:left-0 before:w-full before:h-1/2',
        )}
      >
        <img src={imgSrc} alt={imgAlt} className={cn('w-full h-full object-cover', imgClassName)} {...restImgProps} />
      </div>
      <div
        className={cn(
          'relative -top-4 flex flex-col grow px-3 pb-1 sm:pb-6 basis-2/3',
          'sm:top-0 sm:basis-1/2  bg-transparent overflow-hidden',
        )}
      >
        {labelNode}
        {description && (
          <Typography as="p" variant="base" weight="medium" className="relative text-white text-primary-font flex pt-3">
            {description}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};
