import { createSelectors } from '@repo/common/utils/zustand';
import { create } from 'zustand';

interface State {
  src: string;

  hasError: boolean;

  retryCount: number;
}

interface Actions {
  setup: (src: string) => void;

  fail: () => void;

  retry: () => void;

  success: () => void;
}

export const initialState: State = {
  src: '',
  hasError: false,
  retryCount: 0,
};

export const createImageState = (maxRetry = 5) => {
  const state = create<State & Actions>((set) => ({
    ...initialState,
    setup: (src) => set({ ...initialState, src }),
    fail: () =>
      set((state) => {
        if (maxRetry < state.retryCount) {
          state.retry();
          return state;
        }
        return {
          ...state,
          hasError: true,
        };
      }),
    success: () => set({ hasError: false, retryCount: 0 }),
    retry: () =>
      set((state) => (maxRetry >= state.retryCount ? state : { ...state, retryCount: state.retryCount + 1 })),
  }));

  return createSelectors(state);
};
