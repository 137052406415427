import { DIALOG_KEYS } from '@/core/constants';
import { LocalStorageKey, localStorageService, sessionStorageService } from '@/core/services';
import { AuthFlowVariantFlag, useGlobalFeatureFlagContext } from '@repo/common/services/features-book';
import { useRouter } from '@tanstack/react-router';

import { authModalAction } from '@/features/modals';

import { authStorageGetters } from '@/entities/auth';
import { useBrandFeatures } from '@/entities/brand';

import { isMarkdownLink } from '@/shared/libs/guards';
import { cn } from '@/shared/libs/utils';
import { Button, Markdown, Modal, ModalRootProps, dialog } from '@/shared/ui';

export interface Props extends ModalRootProps {}

export const WarningModal = (props: Props) => {
  const { bodyClassName, ...restProps } = props;

  const router = useRouter();

  const features = useBrandFeatures();

  const isAuthenticated = authStorageGetters.getAuth().isAuthenticated;

  const { authFlowVariant } = useGlobalFeatureFlagContext();

  const withAuthRequired = AuthFlowVariantFlag.AUTH_REQUIRED === authFlowVariant;

  const handleConfirmClick = () => {
    localStorageService.setItem(LocalStorageKey.IsWarningModalShown, true);
    dialog.close({ key: DIALOG_KEYS.WARNING_MODAL });
    if (withAuthRequired && !isAuthenticated) {
      showAuthRequired();
    }
  };

  const showAuthRequired = () => {
    authModalAction.open({
      isRequired: true,
      onSuccess: () => {
        authModalAction.close();
      },
    });
  };

  const handleLeaveClick = () => {
    dialog.close({
      key: DIALOG_KEYS.WARNING_MODAL,
      afterClose: () => {
        router.history.back();
      },
    });
    localStorageService.clear();
    sessionStorageService.clear();
    window.open('https://google.com', '_self', '');
  };

  return (
    <Modal.Root
      closeOnEscape={false}
      closeOnClickOutside={false}
      alwaysCentered
      className="py-10 px-3 w-full max-w-[740px]"
      onEventClose={handleLeaveClick}
      bodyClassName={cn('bg-black', bodyClassName)}
      {...restProps}
    >
      <Modal.Content className="flex flex-col overflow-hidden bg-black">
        <div className="relative z-1 overflow-y-auto px-3 py-2  scrollbar-thin scrollbar-thumb-grey-500 scrollbar-track-grey-700">
          {isMarkdownLink(features?.documents?.warning ?? '') && <Markdown remoteSrc={features?.documents?.warning} />}
        </div>
        <div className="realtive z-2 flex justify-center py-4 px-3 gap-2">
          <Button onClick={handleLeaveClick} variant="secondary">
            Leave
          </Button>
          <Button onClick={handleConfirmClick}>Confirm</Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
