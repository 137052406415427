import { HTMLAttributes, Ref, useCallback, useRef } from 'react';

import { ConversationDrawerContextValue, ConversationDrawerProvider } from '../../libs';
import { Drawer } from './drawer';
import { DrawerContent } from './drawer-content';
import { DrawerHeader } from './drawer-header';

import { useMergeRefs, useUncontrolled } from '@/shared/hooks';
import { cn, mergeComponents } from '@/shared/libs/utils';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;

  defaultOpen?: boolean;

  innerRef?: Ref<HTMLDivElement>;

  onChangeOpen?: (isOpen: boolean) => void;
}

const _ConversationDrawer = (props: Props) => {
  const { className, children, isOpen, defaultOpen, innerRef, onChangeOpen, ...restProps } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const rootRefs = useMergeRefs(innerRef, rootRef);

  const [_isOpen, _onChangeOpen] = useUncontrolled({
    value: isOpen,
    defaultValue: defaultOpen,
    finalValue: false,
    onChange: onChangeOpen,
  });

  const onOpen = useCallback(() => {
    _onChangeOpen(true);
  }, [_onChangeOpen]);

  const onClose = useCallback(() => {
    _onChangeOpen(false);
  }, [_onChangeOpen]);

  const onToggle = useCallback(() => {
    _onChangeOpen(!_isOpen);
  }, [_onChangeOpen, isOpen]);

  const value: ConversationDrawerContextValue = { isOpen: _isOpen, rootRef, onOpen, onClose, onToggle };
  return (
    <ConversationDrawerProvider value={value}>
      <div
        ref={rootRefs}
        className={cn(
          'group/drawer grid grid-cols-[1fr_0px] tablet:data-[open=true]:grid-cols-[0px_1fr] laptop:data-[open=true]:grid-cols-[1fr_330px] transition-all',
          className,
        )}
        data-open={_isOpen}
        {...restProps}
      >
        {children}
      </div>
    </ConversationDrawerProvider>
  );
};

export const ConversationDrawer = mergeComponents(() => {}, {
  Root: _ConversationDrawer,
  Drawer,
  Header: DrawerHeader,
  Content: DrawerContent,
});
