import { HTMLAttributes } from 'react';

import { Icon, IconKey, Typography } from '../common';
import './styles.css';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const premiumBadge = cva('', {
  variants: {
    intent: {
      primary: 'premium-badge',
      secondary: 'premium-badge-secondary',
    },

    size: {
      default: '',
      lg: '',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'default',
  },
});

export interface Props extends HTMLAttributes<HTMLSpanElement>, VariantProps<typeof premiumBadge> {
  iconKey?: IconKey;

  label?: string;
}

export const PremiumBadge = (props: Props) => {
  const { className, iconKey = 'lock', label, intent = 'primary', size = 'default', ...restProps } = props;

  return (
    <span
      className={cn(
        'flex justify-center items-center min-w-6 aspect-square py-1',
        {
          'rounded-sm px-2 aspect-auto': Boolean(label),
          'px-1 rounded-full text-sm': !Boolean(label),
          'px-2 py-1 rounded-md': size === 'lg' && Boolean(label),
          'px-3 py-1': size === 'lg' && !Boolean(label),
        },
        premiumBadge({ intent }),
        className,
      )}
      {...restProps}
    >
      {label ? (
        <Typography as="span" weight="bold" variant={size === 'default' ? '2xs' : '4xl'} className=" text-inherit">
          {label}
        </Typography>
      ) : (
        <Icon k={iconKey} className="size" />
      )}
    </span>
  );
};
