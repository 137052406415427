import {
  ConstantposPack,
  ConstantposSubscription,
  CreateConstantposPaymentModel,
  type CreateFintmPaymentDTO,
  CreatePaymentIntentDto,
  CreateSubscriptionDto,
  type CreatedFintmPaymentModel,
  CreatedSubscriptionModel,
  CustomerModel,
  EpochCreateDto,
  EpochPack,
  EpochSubscription,
  type FintmPack,
  type FintmSubscription,
  PaymentGatewayCreateDto,
  PaymentGatewayPack,
  PaymentGatewaySubscription,
  SubscriptionModel,
  type SubscriptionQuery,
} from './models';
import { HTTP_DICTIONARY, SubscriptionTier } from '@/core/constants';
import { httpClient } from '@/core/services';

import { CookiesDictionary } from '@/shared/constants';
import { extractCookieValue } from '@/shared/libs/utils';

const getSubscriptionList = async (subscriptionType: SubscriptionTier, signal?: AbortSignal) => {
  return httpClient
    .get<SubscriptionModel[]>(`${HTTP_DICTIONARY.stripePrices}?lookup_keys=${subscriptionType}`, { signal })
    .then((r) => r.data);
};

const createCustomer = async () => {
  return httpClient.post<CustomerModel>(HTTP_DICTIONARY.createCustomer).then((r) => r.data);
};

const createSubscription = async (dto: CreateSubscriptionDto) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<CreatedSubscriptionModel>(HTTP_DICTIONARY.createSubscription, overrideDTO).then((r) => r.data);
};

const createPaymentIntent = async (dto: CreatePaymentIntentDto) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<CreatedSubscriptionModel>(HTTP_DICTIONARY.paymentIntent, overrideDTO).then((r) => r.data);
};

const processCreateSubscription = async (priceId: string) => {
  const { customerId } = await createCustomer();

  return createSubscription({ customerId, priceId });
};

const getFintmPacks = async () => {
  return httpClient.get<FintmPack[]>(HTTP_DICTIONARY.fintmPacks).then((r) => r.data);
};

const getFintmSubscriptions = async (query?: SubscriptionQuery) => {
  return httpClient
    .get<FintmSubscription[]>(HTTP_DICTIONARY.fintmPrices, {
      params: {
        lookup_keys: query?.lookup_keys?.join(',') ?? '',
      },
    })
    .then((r) => r.data);
};

const createFintmPayment = async (dto: CreateFintmPaymentDTO) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<CreatedFintmPaymentModel>(HTTP_DICTIONARY.createFintmPayment, overrideDTO).then((r) => r.data);
};

const getConstantposPacks = async () => {
  return httpClient.get<ConstantposPack[]>(HTTP_DICTIONARY.constantposPacks).then((r) => r.data);
};

const getConstantposSubscriptions = async (query?: SubscriptionQuery) => {
  return httpClient
    .get<ConstantposSubscription[]>(HTTP_DICTIONARY.constantposPrices, {
      params: {
        lookup_keys: query?.lookup_keys?.join(',') ?? '',
      },
    })
    .then((r) => r.data);
};

const createConstantposUpiPayment = async (dto: CreateConstantposPaymentModel) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<any>(HTTP_DICTIONARY.createConstantposUpiPayment, overrideDTO).then((r) => r.data);
};

const createConstantposSubscriptionPayment = async (dto: CreateConstantposPaymentModel) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<any>(HTTP_DICTIONARY.createConstantposSubscriptionPayment, overrideDTO).then((r) => r.data);
};

const getPaymentGatewayPacks = async () => {
  return httpClient.get<PaymentGatewayPack[]>(HTTP_DICTIONARY.paymentGatewayPacks).then((r) => r.data);
};

const getPaymentGatewaySubscriptions = async (query?: SubscriptionQuery) => {
  return httpClient
    .get<PaymentGatewaySubscription[]>(HTTP_DICTIONARY.paymentGatewayPrices, {
      params: {
        lookup_keys: query?.lookup_keys?.join(',') ?? '',
      },
    })
    .then((r) => r.data);
};

const createPaymentGateway = async (dto: PaymentGatewayCreateDto) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<any>(HTTP_DICTIONARY.paymentGatewayCreate, overrideDTO).then((r) => r.data);
};

const getEpochPacks = async () => {
  return httpClient.get<EpochPack[]>(`${HTTP_DICTIONARY.epochCredits}`).then((r) => r.data);
};

const getEpochCallsPacks = async () => {
  return httpClient.get<EpochPack[]>(`${HTTP_DICTIONARY.epochCallsPacks}`).then((r) => r.data);
};

const getEpochSubscriptions = async (query?: SubscriptionQuery) => {
  return httpClient
    .get<EpochSubscription[]>(HTTP_DICTIONARY.epochSubscription, {
      params: {
        lookup_keys: query?.lookup_keys?.join(',') ?? '',
      },
    })
    .then((r) => r.data);
};

const createEpochPaymentIntent = async (dto: EpochCreateDto) => {
  const redTrackCookie = dto.rtkclickid_store ? dto.rtkclickid_store : extractCookieValue(CookiesDictionary.RED_TRACK);

  const overrideDTO = {
    ...dto,
    ...(redTrackCookie && { rtkclickid_store: redTrackCookie }),
  };

  return httpClient.post<any>(HTTP_DICTIONARY.epochCreatePaymentIntent, overrideDTO).then((r) => r.data);
};

export const subscriptionRepository = {
  getSubscriptionList,
  createCustomer,
  createSubscription,
  createPaymentIntent,
  processCreateSubscription,
  getFintmPacks,
  createFintmPayment,
  getFintmSubscriptions,
  getConstantposPacks,
  getConstantposSubscriptions,
  createConstantposUpiPayment,
  createConstantposSubscriptionPayment,
  getPaymentGatewayPacks,
  getPaymentGatewaySubscriptions,
  createPaymentGateway,
  getEpochPacks,
  getEpochCallsPacks,
  getEpochSubscriptions,
  createEpochPaymentIntent,
} as const;
