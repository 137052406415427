import { ReactNode, useEffect, useLayoutEffect } from 'react';

import { DIALOG_KEYS } from '@/core/constants';
import { LocalStorageKey, localStorageService } from '@/core/services/storage';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { useNavigate, useRouterState } from '@tanstack/react-router';

import { WarningModal } from '@/features/modals';

import { useABTestGroup, useTestGroupB } from '@/entities/ab_test';
import { useBrandFeatures } from '@/entities/brand';

import { Analytic } from '@/shared/services/analytic';
import { SplashScreen, dialog } from '@/shared/ui';

interface Props {
  children: ReactNode;
}

export const RedirectProvider = (props: Props) => {
  const { children } = props;

  const isPassOnboarding = localStorageService.getItem(LocalStorageKey.IsPassOnboarding, false);

  const { data: viewer, isLoading } = useGetMeQuery();

  const features = useBrandFeatures();

  const abTestGroup = useABTestGroup(viewer?.split_test_groups);
  const isTestGroupB = useTestGroupB(viewer?.split_test_groups);

  const isWarningModalShown =
    !localStorageService.getItem(LocalStorageKey.IsWarningModalShown, false) && features?.documents_warning_modal;

  const navigate = useNavigate();

  const selected = useRouterState({
    select: (state) => state.location,
  });

  useEffect(() => {
    if (features?.deep_link && selected.pathname.includes('/chats')) {
      localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
    }

    if (isWarningModalShown) {
      dialog.open({
        key: DIALOG_KEYS.WARNING_MODAL,
        component: <WarningModal />,
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (features?.ab_test && abTestGroup) {
      Analytic.abTest(abTestGroup);
      if (isTestGroupB && !isPassOnboarding) {
        localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
        if (!selected.pathname.includes('/chats')) navigate({ to: '/discover' });
      }
    }
  }, [abTestGroup, isTestGroupB]);

  return <>{isLoading ? <SplashScreen spinnerKey="line-wobble" /> : children}</>;
};
