import '../../styles.scss';

import { useTimer } from '@/features/discount-banner/_model';

import { Icon } from '@/shared/ui';

export interface Props {
  createdAt: string;

  cycleDurationSeconds: number;
}

export const Timer = (props: Props) => {
  const { createdAt, cycleDurationSeconds } = props;
  const time = useTimer(createdAt, cycleDurationSeconds);

  return (
    <>
      <Icon k="timer" className="pr-2" />
      <div className="min-w-full text-lg font-semibold">
        {String(time.hours).padStart(2, '0')} : {String(time.minutes).padStart(2, '0')} :{' '}
        {String(time.seconds).padStart(2, '0')}
      </div>
    </>
  );
};
