import { useFeature } from '@repo/common/services/features-book';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';

import { welcomeOfferStorageGetters, welcomeOfferStorageSetters } from '@/entities/subscription';

export const useWelcomeOffer = () => {
  const isWelcomeOffer = useFeature('welcome-offer', {
    enabled: false,
  });

  const { data: viewer } = useGetMeQuery();

  const isWelcomeOfferShow =
    isWelcomeOffer.enabled && welcomeOfferStorageGetters.getWelcomeOfferState() === 'show' && !viewer?.is_subscribed;

  const handleWelcomeOfferState = () => {
    if (isWelcomeOffer.enabled && welcomeOfferStorageGetters.getWelcomeOfferState() !== 'hide') {
      welcomeOfferStorageSetters.setWelcomeOfferShowState();
    }
  };

  return {
    isWelcomeOffer,
    isWelcomeOfferShow,
    handleWelcomeOfferState,
  };
};
