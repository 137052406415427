import { z } from 'zod';

import { ChangeFields, Nullable } from '@/shared/types';

export type Character = {
  id: number;
  name: string;
  age: number;
  picture_url: string;
  bio: string;
  is_default: boolean;
  is_premium: boolean;
  public_pic_urls: Nullable<string[]>;
  private_pic_urls: CharacterPrivatePictures[];
  chat_id: number;
  character_variant: number;
  message_price: number;
  msg_count: number;
  interests: Nullable<string[]>;
  suggestions: string[];
  default_suggests: string[];
  override: Nullable<CharacterOverride>;
  default: Nullable<CharacterOverride>;
};

export type CharacterAdapted = ChangeFields<Character, { public_pic_urls: string[]; interests: string[] }> & {
  character?: Record<string, number>;
};

export type CharacterPrivatePictures = {
  key: string;
  url: string;
};

export type CharacterOverride = {
  name?: string;
  picture_url?: string;
  bio?: string;
  age?: number;
  interests?: string[];
  public_pic_urls?: string[];
  sexy_meter?: number;
  fun_meter?: number;
  mystic_meter?: number;
};

export type UploadPhotoResponse = {
  blurUrl: string;
  photoUrl: string;
  thumbUrl: string;
};

export const UpdateCharacterSchema = z.object({
  name: z.string().min(1, { message: 'Name must be at least 2 characters.' }),
  picture_url: z.string(),
  bio: z.string().min(1, { message: 'Bio must not be empty.' }),
  age: z.number().min(18).max(99),
  interests: z.array(z.string()),
  public_pic_urls: z.array(z.string()),
  character: z.record(z.string(), z.number().min(0).max(10)),
});

export type UpdateCharacterDto = z.infer<typeof UpdateCharacterSchema>;

export type UpdateCharacterParams = {
  chatId: string;

  character: UpdateCharacterDto;
};

export type CreateCharacterDto = {
  id: number;
  name: string;
  picture_url: string;
  bio: string;
  age: number;
  interests: string[];
  face_picture_url: string;
  character_variant: number;
  initial_messages: any;
  initial_prompt: string;
  img_prompt: string;
  attributes: CharacterAttributes;
};

export type CharacterAttributes = {
  ethnicity: string;
  age: string;
  eyes_color: string;
  hair_color: string;
  hair_style: string;
  body_type: string;
  breast_size: string;
  butt_size: string;
  tattoo: string;
  interests: string[];
  relationship: string;
  clothing: string;
};

export type GenerateCharacterPhotoDTO = {
  attributes: any;
  face_picture_url?: string;
};
