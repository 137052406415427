import type { BaserQueryOptions } from '@/core/config/react-query';
import { QUERY_KEYS } from '@/core/constants';
import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { type BrandConfigModel, brandRepository } from '@/entities/brand/lib/repositories';

import { useReadyState } from '@/shared/services/ready-state';

export const getBrandConfigQueryOptions = (query?: string, options?: BaserQueryOptions<BrandConfigModel>) => {
  const patchShared = useReadyState.getState().patchShared;
  const startLoad = useReadyState.getState().startLoad;
  const finishLoad = useReadyState.getState().finishLoad;

  return queryOptions({
    queryKey: [QUERY_KEYS.BrandConfig],
    queryFn: async () => {
      startLoad();
      const data = await brandRepository.getBrandConfig(query);

      patchShared({
        ...data.payload.constants,
      });

      finishLoad();

      return data;
    },
    ...options,
  });
};

export const useGetBrandConfigQuery = (query?: string, options?: BaserQueryOptions<BrandConfigModel>) => {
  return useQuery(getBrandConfigQueryOptions(query, options));
};

export const useSelectBrandConfig = () => {
  const value = useGetBrandConfigQuery(undefined, {
    enabled: false,
  });

  return {
    version: value.data?.version,
    ...value.data?.payload,
  };
};

export const useSuspenseGetBrandConfig = (query?: string, options?: BaserQueryOptions<BrandConfigModel>) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEYS.BrandConfig],
    queryFn: () => brandRepository.getBrandConfig(query),
    ...options,
  });
};
