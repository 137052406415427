import { UiAvatarFallback } from './ui-avatar-fallback';
import { UiAvatarImage } from './ui-avatar-image';
import { UiAvatarLazyImage } from './ui-avatar-lazy-image';
import { UiAvatarRoot } from './ui-avatar-root';

export const UiAvatar = {
  Root: UiAvatarRoot,
  Fallback: UiAvatarFallback,
  Image: UiAvatarImage,
  LazyImage: UiAvatarLazyImage,
};
