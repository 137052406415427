import { useRouteContext } from '@tanstack/react-router';

export const useBrandConfig = () => {
  const { brandConfig } = useRouteContext({ strict: false });
  return brandConfig;
};

export const useBrandFeatures = () => {
  const brandConfig = useBrandConfig();
  return brandConfig?.features;
};

export const useBrandConstants = () => {
  const brandConfig = useBrandConfig();
  return brandConfig?.constants;
};
