import { ButtonHTMLAttributes, useCallback } from 'react';

import { useDialogContext } from '../../libs';
import { VariantProps, cva } from 'class-variance-authority';
import { X } from 'lucide-react';

import { cn } from '@/shared/libs/utils';

const closeIcon = cva('', {
  variants: {
    iconSize: {
      xs: 'size-1',
      sm: 'size-2',
      md: 'size-3',
      lg: 'size-4',
      xl: 'size-5',
    },
  },
  defaultVariants: {
    iconSize: 'lg',
  },
});

const closeBtn = cva('text-white/80 flex justify-center items-center z-[2]  p-1.5', {
  variants: {
    variant: {
      transparent: 'text-grey bg-transparent',
      default: 'text-white/80 bg-black/20',
      grey: 'text-black bg-grey-600',
      dark: 'text-white bg-black',
    },
    rounded: {
      full: 'rounded-full',
      xs: 'rounded-[5px]',
    },
    size: {
      default: 'min-w-4 min-h-4',
      sm: 'min-w-2 min-h-2 p-0.5',
      lg: 'min-w-5 min-h-5',
      xl: 'min-w-6 min-h-6',
    },
  },
  defaultVariants: {
    variant: 'default',
    rounded: 'xs',
    size: 'default',
  },
});

export interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof closeIcon>,
    VariantProps<typeof closeBtn> {
  onEventClose?: () => void;
}

export const CloseBtn = (props: Props) => {
  const { className, iconSize, variant, rounded, size, onEventClose, ...restProps } = props;

  const { onClose } = useDialogContext();

  const handleClickBtn = useCallback(() => {
    onEventClose?.();
    onClose();
  }, [onEventClose, onClose]);

  return (
    <button className={cn(closeBtn({ variant, rounded, size, className }))} onClick={handleClickBtn} {...restProps}>
      <X className={closeIcon({ iconSize })} />
    </button>
  );
};
