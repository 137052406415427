import type { OpenAlertPayload } from '../types';
import type { BaseOpenDialogPayload } from '../types';
import type { AlertProps } from '../types';
import type { ModalProps } from '../types';
import { DialogItem, DialogType, OpenDialogPayload } from '../types';
import { randomId } from '@repo/common/utils/base';
import { createSelectors } from '@repo/common/utils/zustand';
import { create } from 'zustand';

type DialogState = {
  stack: DialogItem[];
};

const defaultState: DialogState = {
  stack: [],
};

const useBaseDialogState = create<DialogState>(() => defaultState);

const clearDialog = () => {
  useBaseDialogState.setState(defaultState);
};

const closeDialog = (id: string) => {
  useBaseDialogState.setState((state) => {
    const newStack = state.stack
      .filter((item) => item.id !== id)
      .map((item, idx, arr) =>
        idx === arr.length - 1
          ? {
              ...item,
              isHidden: false,
            }
          : item,
      );

    return {
      stack: newStack,
    };
  });
};

type UpdateDialogStackPayload<T extends DialogType> = {
  stack: DialogItem[];
  type: T;
  props: T extends DialogType.Modal ? ModalProps : AlertProps;
} & BaseOpenDialogPayload;

const updateDialogStack = <T extends DialogType>({
  content,
  props,
  stack,
  id = randomId(),
  hidePrevDialogs = true,
  type,
}: UpdateDialogStackPayload<T>) => {
  const isExist = stack.some((item) => item.id === id);

  if (isExist) {
    closeDialog(id);
  }

  const newDialog = {
    id,
    content,
    isHidden: false,
    onClose: () => closeDialog(id),
    type,
    props,
  } as DialogItem;

  const newStack = hidePrevDialogs ? stack.map((item) => ({ ...item, isHidden: true })) : stack;

  return [...newStack, newDialog];
};

const openAlert = (payload: Omit<OpenAlertPayload, 'type'>) => {
  const { id = randomId(), content, hidePrevDialogs, props, ...restPayload } = payload;
  const _props: AlertProps = {
    id,
    ...props,
    ...restPayload,
  };

  useBaseDialogState.setState((state) => {
    return {
      stack: updateDialogStack({
        stack: state.stack,
        content,
        id,
        hidePrevDialogs,
        type: DialogType.Alert,
        props: _props,
      }),
    };
  });

  return () => closeDialog(id);
};

const openModal = (payload: Omit<OpenDialogPayload, 'type'>) => {
  const { id = randomId(), content, hidePrevDialogs, props } = payload;
  const _props: ModalProps = {
    id,
    ...props,
  };

  useBaseDialogState.setState((state) => {
    return {
      stack: updateDialogStack({
        stack: state.stack,
        content,
        id,
        hidePrevDialogs,
        type: DialogType.Modal,
        props: _props,
      }),
    };
  });

  return () => closeDialog(id);
};

const openDialog = (payload: OpenDialogPayload) => {
  if (payload.type === DialogType.Alert) {
    return openAlert(payload);
  }

  return openModal(payload);
};

export const useDialogState = createSelectors(useBaseDialogState);

export const dialog = {
  open: openDialog,
  alert: openAlert,
  modal: openModal,
  close: closeDialog,
  clear: clearDialog,
};
