import { getClient } from '../../api/api-client';
import { authEndpoints } from '../../api/api-dictionary';
import {
  ConfirmEmailQuery,
  CreateTokenDTO,
  GoogleSignUpDTO,
  ResetPasswordDTO,
  SignInDTO,
  SignUpDTO,
  TokenResponse,
} from './entities';
import { ApiEndpoint } from '../../types/utility';
import { dataExtractor } from '../../utils/data-extractor';

const createToken: ApiEndpoint<CreateTokenDTO, undefined, TokenResponse> = (dto, config) => {
  const { client_token, ...restDto } = dto;

  const { headers, ...restConfig } = config || {};

  const innerConfig = {
    ...restConfig,
    headers: {
      ...headers,
      withAuth: false,
      Authorization: `Bearer ${client_token}`,
    },
  };

  return getClient().post(authEndpoints.createToken(), restDto, innerConfig).then(dataExtractor);
};

const signUp: ApiEndpoint<SignUpDTO, undefined, TokenResponse> = (dto, config) => {
  return getClient().post(authEndpoints.signUpWithEmail(), dto, config).then(dataExtractor);
};

const googleSignUp: ApiEndpoint<GoogleSignUpDTO, undefined, TokenResponse> = (dto, config) => {
  return getClient().post(authEndpoints.signUpWithGoogle(), dto, config).then(dataExtractor);
};

const signIn: ApiEndpoint<SignInDTO, undefined, TokenResponse> = (dto, config) => {
  const { clientToken, ...restDto } = dto;

  return getClient()
    .post(authEndpoints.signInWithEmail(), restDto, {
      ...config,
      headers: {
        ...config?.headers,
        withAuth: false,
        Authorization: `Bearer ${clientToken}`,
      },
    })
    .then(dataExtractor);
};

const confirmEmail: ApiEndpoint<undefined, undefined, unknown, ConfirmEmailQuery> = (query, config) => {
  return getClient()
    .post(authEndpoints.confirmEmail(), undefined, { ...config, params: query })
    .then(dataExtractor);
};

const resetPassword: ApiEndpoint<ResetPasswordDTO, undefined, string> = (dto, config) => {
  const { clientToken, ...restDto } = dto;
  return getClient()
    .post(authEndpoints.resetPassword(), restDto, {
      ...config,
      headers: {
        ...config?.headers,
        withAuth: false,
        Authorization: `Bearer ${clientToken}`,
      },
    })
    .then(dataExtractor);
};

export const requests = {
  createToken,
  signUp,
  signIn,
  googleSignUp,
  confirmEmail,
  resetPassword,
};
