import { Children, HTMLAttributes } from 'react';

import { cn } from '@repo/common/utils/component';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  animated?: boolean;

  as?: keyof JSX.IntrinsicElements;
}

export const UiIndicator = (props: Props) => {
  const { as: Component = 'div', children, animated, className, style, ...restProps } = props;

  const hasChildren = Children.count(children) > 0;
  const innerStyle = {
    '--color': 'var(--ui-color-red)',
    '--size': '8px',
    ...style,
  };

  return (
    <Component
      style={innerStyle}
      className={cn(
        'ui-indicator ui-relative',
        {
          'ui-inline-block ui-size-[--size] ui-bg-[--color] ui-rounded-full': !hasChildren,
          'before:ui-absolute before:ui-size-[--size] before:ui-bg-[--color] before:ui-rounded-full before:ui-right-0 before:ui-top-0 before:ui-z-[9999]':
            hasChildren,
          'before:ui-animate-ping before:ui-duration-2000 before:ui-ease-in-out before:ui-repeat-infinite before:ui-animate-infinite':
            hasChildren && animated,
          'ui-animate-ping ui-duration-2000 ui-ease-in-out ui-repeat-infinite ui-animate-infinite':
            !hasChildren && animated,
        },
        className,
      )}
      {...(restProps as any)}
    >
      {children}
    </Component>
  );
};
