import { ApiEndpoint } from '../../types/utility';
import { UpdateUserDTO, UserEntity } from './entities';
import { getClient } from '../../api/api-client';
import { userEndpoints } from '../../api/api-dictionary';
import { dataExtractor } from '../../utils/data-extractor';

const getMe: ApiEndpoint<undefined, undefined, UserEntity> = (config) => {
  return getClient().get(userEndpoints.me(), config).then(dataExtractor);
};

const updateMe: ApiEndpoint<UpdateUserDTO, undefined, UserEntity> = (dto, config) => {
  return getClient().post(userEndpoints.updateMe(), dto, config);
};

export const Repository = {
  getMe,
  updateMe,
};
