import { useLayoutEffect } from 'react';

import { createScript } from '@/shared/libs/utils';

export const useSnapchatPixel = (id?: string) => {
  useLayoutEffect(() => {
    if (!id) return;

    const script = createScript(
      `
        (function(e,t,n){
          if(e.snaptr) return;
          var a = e.snaptr = function(){
            a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments);
          };
          a.queue = [];
          var s = 'script';
          var r = t.createElement(s);
          r.async = !0;
          r.src = n;
          var u = t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r,u);
        })(window,document,'https://sc-static.net/scevent.min.js');

        snaptr('init', '${id}', {
          'user_email': '__INSERT_USER_EMAIL__'
        });
        snaptr('track', 'PAGE_VIEW');
      `,
      'script',
      { async: true, id: 'snapchat-pixel-script' },
    );

    script.mount();

    return () => {
      script.remove();
    };
  }, [id]);
};
