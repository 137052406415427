import { useCallback } from 'react';

import InternetConnection from '@/assets/icons/internet-connection.svg?react';
import { DIALOG_KEYS } from '@/core/constants';
import { useEventListener } from 'usehooks-ts';

import { Alert, dialog } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

export const useListenInternetConnection = () => {
  const offlineHandler = () => {
    dialog.open({
      key: DIALOG_KEYS.NO_INTERNET,
      component: (
        <Alert
          withCloseButton={false}
          closeOnClickOutside={false}
          type="error"
          title="No internet connection"
          description="Make sure you are connected to Wi-Fi or your mobile network!"
          icon={
            <span className="font-primary text-[56px]">
              <InternetConnection />
            </span>
          }
          footer={
            <div className="w-full pt-6">
              <Button onClick={onClickRefresh} className="w-full" size="lg">
                Refresh
              </Button>
            </div>
          }
        />
      ),
    });
  };

  const onlineHandler = () => {
    dialog.close({ key: DIALOG_KEYS.NO_INTERNET });
  };

  const onClickRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  useEventListener('offline', offlineHandler);
  useEventListener('online', onlineHandler);
};
