import { useCallback, useEffect, useState } from 'react';

import { useCarousel } from '../carousel.context';

import { CarouselApi } from '@/shared/ui';

export const usePaginationController = () => {
  const { carouselApi } = useCarousel();

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onInit = (api: CarouselApi) => {
    setScrollSnaps(api.scrollSnapList());
  };

  const onSelect = (api: CarouselApi) => {
    setSelectedIndex(api.selectedScrollSnap());
  };

  const onClickItem = useCallback(
    (idx: number) => {
      if (!carouselApi) return;
      carouselApi.scrollTo(idx);
    },
    [carouselApi],
  );

  useEffect(() => {
    if (!carouselApi) return;
    onInit(carouselApi);
    onSelect(carouselApi);
    carouselApi.on('reInit', onInit);
    carouselApi.on('reInit', onSelect);
    carouselApi.on('select', onSelect);
  }, [carouselApi]);

  return { scrollSnaps, selectedIndex, onClickItem };
};
