import { SpinnerProps } from '../type';

export const CircleKey = 'circle';

export interface Props extends SpinnerProps {
  size?: number;
}

export const Circle = (props: Props) => {
  const { size = 40, ...restProps } = props;
  return (
    <span data-variant={CircleKey} {...restProps}>
      <svg
        className="ui-origin-center ui-will-change-transform ui-text-[--primary-color-spinner]"
        viewBox={`0 0 ${size} ${size}`}
        height={size}
        width={size}
      >
        <circle className="track" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="5px" fill="currentColor" />
        <circle className="car" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="5px" fill="currentColor" />
      </svg>
    </span>
  );
};
