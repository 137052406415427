import {SUBSCRIPTION_PLAN} from "@repo/api/payment";
import {useMemo} from 'react';
import {useGetMeQuery} from './use-get-me';

export const useSelectMe = () => {
  return (
    useGetMeQuery({
      enabled: false,
    })?.data ?? null
  );
};

export const useIsSubscribed = () => {
  const me = useSelectMe();
  return useMemo(() => me?.is_subscribed ?? false, [me?.is_subscribed]);
};

export const useViewerSubscriptionPlan = () => {
  const me = useSelectMe();
  return useMemo(() => me?.subscription_plan ?? SUBSCRIPTION_PLAN.BASIC, [me?.subscription_plan]);
}

export const useIsRegistered = () => {
  const me = useSelectMe();
  return useMemo(() => me?.is_registered ?? false, [me?.is_registered]);
};

export const useCurrentCreditsAmount = () => {
  const me = useSelectMe();
  return useMemo(() => me?.credits ?? 0, [me?.credits]);
};
