import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCredential } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';

export type FirebaseParams = {
  apiKey?: string;
  authDomain?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
  measurementId?: string;
};

export class FirebaseService {
  private messaging: any;

  private auth: any;

  constructor(private readonly config: FirebaseParams) {
    this.config = config;
    this.initializeFirebase();
    this.registerServiceWorker();
  }

  public async getCurrentUserToken(credential: any) {
    try {
      await signInWithCredential(this.auth, credential);
      return this.auth.currentUser.getIdToken(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getToken(vapidKey?: string) {
    try {
      const registration = await this.registerServiceWorker();

      if (registration?.active?.state === 'activated') {
        const token = await getToken(this.messaging, {
          vapidKey: vapidKey,
          serviceWorkerRegistration: registration,
        });

        if (token) {
          return token;
        } else {
          return null;
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  private initializeFirebase() {
    try {
      if (!this.config.projectId) return;
      const app = initializeApp(this.config);
      this.messaging = getMessaging(app);
      this.auth = getAuth(app);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        return await navigator?.serviceWorker?.register('/firebase-messaging-sw.js');
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject('Service workers are not supported in this browser');
    }
  }
}

function runFirebase() {
  let firebaseInstance: FirebaseService | null = null;

  return (config: FirebaseParams) => {
    if (!firebaseInstance) {
      firebaseInstance = new FirebaseService(config);
    }
    return firebaseInstance;
  };
}

export const getFirebase = runFirebase();
