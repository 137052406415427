import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const card = cva('', {
  variants: {
    rounded: {
      sm: 'rounded-sm',
      md: 'rounded-md',
      lg: 'rounded-lg',
      xl: 'rounded-xl',
    },
    isActive: {
      true: 'bg-brand-light text-brand-light-content border-brand-light',
    },
  },

  defaultVariants: {
    rounded: 'lg',
  },
});

export interface Props extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof card> {}

export const Card = (props: Props) => {
  const { rounded, className, isActive, ...restProps } = props;

  return (
    <div
      className={cn(
        'transition-all duration-150 ease-linear border border-grey-700 bg-grey-700 text-grey-content-700 overflow-hidden',
        className,
        card({
          rounded,
          isActive,
        }),
      )}
      {...restProps}
    />
  );
};
