import Coinage1 from '@/assets/credits/coinage_1.png';
import Coinage2 from '@/assets/credits/coinage_2.png';
import Coinage3 from '@/assets/credits/coinage_3.png';
import Coupon1 from '@/assets/credits/coupon_1.png';
import Coupon2 from '@/assets/credits/coupon_2.png';
import Coupon3 from '@/assets/credits/coupon_3.png';
import Coinage from '@/assets/credits/credit_coinage.png';
import Coupon from '@/assets/credits/credit_coupon.png';
import Crystal from '@/assets/credits/credit_crystal.png';
import Crystal1 from '@/assets/credits/crystal_1.png';
import Crystal2 from '@/assets/credits/crystal_2.png';
import Crystal3 from '@/assets/credits/crystal_3.png';
import Gold1 from '@/assets/credits/gold_1.png';
import Gold2 from '@/assets/credits/gold_2.png';
import Gold3 from '@/assets/credits/gold_3.png';

const CouponsPack = [Coupon1, Coupon2, Coupon3] as const;

const CrystalsPack = [Crystal1, Crystal2, Crystal3] as const;

const CoinagesPack = [Coinage1, Coinage2, Coinage3] as const;

const GoldsPack = [Gold1, Gold2, Gold3] as const;

export const CreditIconPacks = {
  coupon: CouponsPack,
  crystal: CrystalsPack,
  coinage: CoinagesPack,
  gold: GoldsPack,
} as const;

export type CreditIconPackKey = keyof typeof CreditIconPacks;

export const CreditSingleImg = {
  coinage: Coinage,
  gold: Coinage,
  coupon: Coupon,
  crystal: Crystal,
} satisfies Record<CreditIconPackKey, string>;
