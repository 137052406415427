import { useLayoutEffect } from 'react';

import { useUpdateMeMutation } from '@repo/modules/entity/user-queries';
import { useSearch } from '@tanstack/react-router';

import { useBrandConstants } from '@/entities/brand';
import { useFirebase } from '@/entities/firebase';
import { useNotificationPermission } from '@/entities/push-notifications';

import { Analytic } from '@/shared/services/analytic';

export const useConnectUserToNotification = () => {
  const updateMe = useUpdateMeMutation();
  const permissions = useNotificationPermission();

  const firebase = useFirebase();
  const constants = useBrandConstants();
  const isFirebaseKeys = constants?.firebase_web_keys && Object.entries(constants?.firebase_web_keys).length;

  const searchParams = useSearch({ strict: false }) as any;
  const notificationOpen = searchParams?.notification_open;

  const sendToken = async () => {
    const token = await firebase?.getToken(constants?.firebase_web_keys?.vapid_key);

    if (token) {
      await updateMe.mutateAsync({
        registration_id: token,
      });
    }
  };

  useLayoutEffect(() => {
    if (permissions === 'granted' && isFirebaseKeys) {
      sendToken();
    }
  }, [permissions, constants?.firebase_web_keys]);

  useLayoutEffect(() => {
    if (notificationOpen) {
      Analytic.pushNotificationOpen();
    }
  }, [notificationOpen]);
};
