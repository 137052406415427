import { ComponentPropsWithoutRef } from 'react';

import { Track as PrimitiveTrack } from '@radix-ui/react-slider';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<typeof PrimitiveTrack> {}

export const Track = (props: Props) => {
  const { className, ...restProps } = props;

  return (
    <PrimitiveTrack
      className={cn('relative h-1.5 w-full grow overflow-hidden rounded-full bg-grey-600', className)}
      {...restProps}
    />
  );
};
