import { RefObject } from 'react';

import { createSafeContext } from '@/shared/libs/context';

export interface ConversationDrawerContextValue {
  isOpen: boolean;

  rootRef: RefObject<HTMLDivElement>;

  onOpen: () => void;

  onClose: () => void;

  onToggle: () => void;
}

export const [ConversationDrawerProvider, useConversationDrawerContext] =
  createSafeContext<ConversationDrawerContextValue>('ConversationDrawerContext');
