import { DIALOG_KEYS } from '@/core/constants';

import { ResetPasswordForm } from '@/features/reset-password-form';

import { Alert, CloseDialogArgs, Modal, OpenDialogArgs, Typography, dialog } from '@/shared/ui';

export interface Props {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

export const ResetPasswordModal = (props: Props) => {
  const { onSuccess, onError } = props;

  const handleOnSuccess = (email?: string) => {
    dialog.close({ key: DIALOG_KEYS.RESET_PASSWORD });
    dialog.open({
      key: DIALOG_KEYS.SUCCESS_RESET_PASSWORD,
      component: (
        <Alert
          type="success"
          title="Mail sent"
          description={`Please check your inbox ${email}`}
          onOkClick={() => {
            dialog.close({ key: DIALOG_KEYS.SUCCESS_RESET_PASSWORD });
          }}
          cancelBtnProps={{ className: 'hidden' }}
          withActions
        >
          {/*<Typography className="text-base">Please check your inbox {email}</Typography>*/}
        </Alert>
      ),
    });
    onSuccess?.();
    dialog.close({ key: DIALOG_KEYS.RESET_PASSWORD });
  };

  return (
    <Modal.Root
      className="justify-center"
      bodyClassName="rounded-modal px-4 pb-6 pt-16 w-full max-w-[93.4vw] md:max-w-[400px] relative"
    >
      <Modal.CloseBtn variant="grey" size="sm" className="absolute top-6 right-4 bg-white/20" />
      <Modal.Content className="w-screen w-full grow sm:grow-0">
        <div className="text-center pb-4">
          <Typography weight="bold" variant="4xl" className="text-primary">
            Password Recovery
          </Typography>
          <Typography weight="medium" className="text-secondary pt-2 max-w-[288px] mx-auto">
            Enter your e-mail and we will send you the instructions to reset password
          </Typography>
        </div>
        <ResetPasswordForm onSuccess={handleOnSuccess} onError={onError} />
      </Modal.Content>
    </Modal.Root>
  );
};

const openResetPasswordModal = (props?: Props, other?: Omit<Partial<OpenDialogArgs>, 'component'>) => {
  dialog.open({
    key: DIALOG_KEYS.RESET_PASSWORD,
    component: <ResetPasswordModal {...(props || {})} />,
    ...other,
  });
};

const closeResetPasswordModal = (other?: CloseDialogArgs) => {
  dialog.close({ key: DIALOG_KEYS.RESET_PASSWORD, ...other });
};

export const resetPasswordModalAction = {
  open: openResetPasswordModal,
  close: closeResetPasswordModal,
} as const;
