import { v4 as uuidv4 } from 'uuid';

export function randomId() {
  return uuidv4();
}

export function groupBy<T extends Record<string, any>>(array: T[], key: keyof T): Record<string, T[]> {
  return array.reduce(
    (result, currentValue) => {
      const groupKey = currentValue[key] as string;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    },
    {} as Record<string, T[]>,
  );
}

export const isPrimitiveArray = (array: any[]): array is string[] | number[] => {
  return array.every((item) => typeof item === 'string' || typeof item === 'number');
};

export const isObject = <T>(value: T): boolean => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

export function uppercaseFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isNull(value: any): value is null {
  return value === null;
}
