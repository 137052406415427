import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useUpdateMeMutation } from '@repo/modules/entity/user-queries';
import { z } from 'zod';

export interface Props {
  onSubmitted?: () => void;
}

const schema = z.object({
  email: z.string().min(1, { message: 'Email must not be empty' }).email({ message: 'Invalid email format' }),
});

type ViewerSchema = z.infer<typeof schema>;

export const useEmailFormController = (props: Props) => {
  const { onSubmitted } = props;

  const {
    handleSubmit,
    formState: { isDirty, errors, isValid },
    reset,
    control,
  } = useForm<ViewerSchema>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(schema),
  });

  const { mutateAsync: updateMe } = useUpdateMeMutation();

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await updateMe({
        email: email,
      });
      reset();
      onSubmitted?.();
      return await Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  });

  const isDisabledSendBtn = !isDirty || !isValid;

  return {
    control,
    isDisabledSendBtn,
    onSubmit,
    errors,
  };
};
