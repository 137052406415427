export type SubscribeDisposer = () => void;

export type SocketSubscriber = <T = unknown>(...args: T[]) => void;

export type ConnectSocketParams = {
  opened: (socket: SocketService) => void;
  closed?: () => void;
};

class SocketService {
  private _socket: WebSocket | null = null;

  private subscribers: Set<SocketSubscriber> = new Set();

  public isClosed = false;

  constructor(private socketUrl: string) {
    this.socketUrl = socketUrl;
  }

  public status() {
    return this._socket?.readyState;
  }

  public connect = (params?: ConnectSocketParams) => {
    const socket = new WebSocket(this.socketUrl);
    this._socket = socket;
    socket.addEventListener('open', () => {
      this.onOpen();
      params?.opened?.(this);
    });
    socket.onopen = this.onOpen;
    socket.onmessage = this.onMessage;
    socket.onclose = () => {
      this.onClose();
      params?.closed?.();
    };
    socket.onerror = this.onError;
  };

  get socket() {
    return this._socket;
  }

  public send = <T>(data: T) => {
    if (this.socket) {
      this.socket.send(JSON.stringify(data));
    }
  };

  private onOpen = () => {};

  private onClose = () => {
    this.isClosed = true;
  };

  private onError = () => {};

  public subscribe = <T extends SocketSubscriber>(subscriber: T): SubscribeDisposer => {
    this.subscribers.add(subscriber);
    return () => {
      this.subscribers.delete(subscriber);
    };
  };

  private onMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    this.subscribers.forEach((sub) => {
      sub(data);
    });
  };

  public reconnect = () => {
    this._socket?.close();
    this._socket = null;
    this.connect();
  };

  public disconnect = () => {
    this._socket?.close();
    this._socket = null;
  };
}

export const createSocket = (socketUrl: string) => {
  return new SocketService(socketUrl);
};
