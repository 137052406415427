import { SPLIT_TEST_GROUPS } from '@/core/constants/ab-test';
import type { UserEntity } from '@repo/api/user';

export const useABTestGroup = (splitTestGroup?: UserEntity['split_test_groups']) => {
  const testGroup = splitTestGroup?.find((group) =>
    Object.values(SPLIT_TEST_GROUPS).includes(group as SPLIT_TEST_GROUPS),
  );

  return testGroup || null;
};

export const useTestGroupA = (splitTestGroup?: UserEntity['split_test_groups']) => {
  const testGroup = useABTestGroup(splitTestGroup);
  return testGroup === SPLIT_TEST_GROUPS.DISABLE_ONBOARDING_PAYWALL_A || false;
};

export const useTestGroupB = (splitTestGroup?: UserEntity['split_test_groups']) => {
  const testGroup = useABTestGroup(splitTestGroup);
  return testGroup === SPLIT_TEST_GROUPS.DISABLE_ONBOARDING_PAYWALL_B || false;
};

export const useTestGroupC = (splitTestGroup?: UserEntity['split_test_groups']) => {
  const testGroup = useABTestGroup(splitTestGroup);
  return testGroup === SPLIT_TEST_GROUPS.DISABLE_ONBOARDING_PAYWALL_C || false;
};

export const useTestGroupPaywallGifEnabled = (splitTestGroup?: UserEntity['split_test_groups']) => {
  const testGroup = useABTestGroup(splitTestGroup);
  return testGroup === SPLIT_TEST_GROUPS.AB_TEST_GIF_PAYWALL_ENABLED || false;
};
