import { createContext, ReactNode, useContext } from 'react';

export function createSafeContext<ContextValue>(componentName: string, errorMessage?: string) {
  const Context = createContext<ContextValue | null>(null);

  const useSafeContext = () => {
    const ctx = useContext(Context);

    if (ctx === null) {
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      throw new Error(`${componentName} component was not found in the tree`);
    }

    return ctx;
  };

  const Provider = ({ children, value }: { children: ReactNode; value: ContextValue }) => {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  return [Provider, useSafeContext, Context] as const;
}

export function createOptionalContext<ContextValue>(initialValue: ContextValue | null = null) {
  const Context = createContext<ContextValue | null>(initialValue);

  const useOptionalContext = () => useContext(Context);

  const Provider = ({ children, value }: { value: ContextValue; children: ReactNode }) => (
    <Context.Provider value={value}>{children}</Context.Provider>
  );

  return [Provider, useOptionalContext] as const;
}
