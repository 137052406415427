import { memo } from 'react';

import type { SpinnerProps } from '../types';
import './styles.scss';

import { cn } from '@/shared/libs/utils';

export const LineWobbleKey = 'line-wobble';

export interface Props extends SpinnerProps {}

export const LineWobble = memo((props: Props) => {
  const { className, ...restProps } = props;
  return <div className={cn('line-wobble', className)} {...restProps}></div>;
});
