export enum CURRENCY_LOCALE {
  USD = 'en-US',
  INR = 'en-IN',
}

export enum CURRENCY_CODE {
  USD = 'USD',
  INR = 'INR',
}

export const CURRENCY_DICTIONARY = {
  [CURRENCY_CODE.USD]: {
    code: CURRENCY_CODE.USD,
    locale: CURRENCY_LOCALE.USD,
  },
  [CURRENCY_CODE.INR]: {
    code: CURRENCY_CODE.INR,
    locale: CURRENCY_LOCALE.INR,
  },
} as const;
