import { useGetCharacterList } from './use-get-character-list';
import { useGetChatsQuery } from './use-get-chats';

export const useSelectChatById = (id: string) => {
  const chats = useGetChatsQuery({
    enabled: false,
    select: (chat) => chat.filter((c) => c.id.toString() === id),
  });

  return chats.data?.[0] ?? null;
};

export const useSelectChats = () => {
  const chats = useGetChatsQuery({
    enabled: false,
  });
  return chats?.data ?? [];
};

export const useSelectCharacterList = () => {
  const characters = useGetCharacterList({
    enabled: false,
  });
  return characters.data ?? [];
};

export const useSelectCharacterByChatId = (chatId?: string | number) => {
  const characters = useGetCharacterList({
    enabled: false,
    select: (chat) =>
      chat.filter((c) => {
        if (!c.chat_id) return false;

        return c.chat_id.toString() === chatId?.toString();
      }),
  });
  return characters.data?.[0] ?? null;
};

export const useSelectCharacterById = (id?: number | string) => {
  const characters = useGetCharacterList({
    enabled: false,
    select: (chat) => chat.filter((c) => c.id.toString() === id?.toString()),
  });
  return characters.data?.[0] ?? null;
};
