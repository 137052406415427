import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

const search = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/onboarding/')({
  validateSearch: search,
  beforeLoad: ({ context }) => {
    const { isPassOnboarding, brandConfig } = context;
    if (isPassOnboarding()) {
      throw redirect({
        to: brandConfig?.features?.system?.root_path ?? '/conversations',
      });
    }
  },
});
