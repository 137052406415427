export enum CONTENT_TYPE {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
  TEXT = 'text/plain',
}

export enum AUTHORIZATION {
  BEARER = 'Bearer',
  NONE = 'None',
}
