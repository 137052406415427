import { ConversationPreview, CouponPack, Message, conversationRepository } from '../repositories';
import { BaserQueryOptions, queryClient } from '@/core/config/react-query';
import { QUERY_KEYS } from '@/core/constants';
import { PACK_TYPE } from '@repo/api/payment';
import { characterListQueryKey } from '@repo/modules/entity/chat-queries';
import { queryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { CharacterAdapted } from '@/entities/character';

import { Undefined } from '@/shared/types';

export const useGetConversations = (options?: BaserQueryOptions<ConversationPreview[]>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.Conversations],
    queryFn: () => conversationRepository.getConversations(),
    ...options,
  });
};

export const invalidateGetConversations = () => {
  return queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.Conversations] });
};

export const useGetConversationById = (chatId: Undefined<string>) => {
  const { data, isLoading } = useGetConversations();

  return {
    conversation: data?.find((conversation) => conversation.id.toString() === chatId) ?? null,
    isLoading,
  };
};

export const useGetConversationMessagesQuery = (chatId: string | undefined, options?: BaserQueryOptions<Message[]>) => {
  return useQuery({
    enabled: Boolean(chatId),
    staleTime: 0,
    queryKey: [QUERY_KEYS.Messages, chatId],
    queryFn: () => conversationRepository.getConversationMessages(chatId as string),
    ...options,
  });
};

export const getCouponPacksQueryOptions = (packType: PACK_TYPE, options?: BaserQueryOptions<CouponPack[]>) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.CouponPacks, packType],
    queryFn: () => conversationRepository.getCouponPacks(packType),
    ...options,
  });
};

export const useGetCouponPacksQuery = (packType: PACK_TYPE, options?: BaserQueryOptions<CouponPack[]>) => {
  return useQuery(getCouponPacksQueryOptions(packType, options));
};

export const useSendMessageMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.sendMessage,
  });
};

export const useSendRandomMessageMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.sendRandomMessage,
  });
};

export const useCreateConversationMutation = () => {
  return useMutation({
    mutationFn: conversationRepository.createConversation,
    onSuccess: async (data) => {
      queryClient.setQueryData(characterListQueryKey(), (prev: CharacterAdapted[] = []) => {
        return prev.map((c) => {
          if (c.id === data.character_id)
            return {
              ...c,
              chat_id: data.id,
            };
          return c;
        });
      });
      await invalidateGetConversations();
    },
  });
};

export const useEditMessageMutation = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: conversationRepository.editMessage,

    onSuccess: (editedMessage) => {
      qc.setQueryData([QUERY_KEYS.Messages, editedMessage.chat_id.toString()], (prev: Message[] | undefined) => {
        const _prev = prev ?? [];
        return _prev.map((message) => (message.id === editedMessage.id ? editedMessage : message));
      });
    },
  });
};
