import { useFeatureGetter } from '../api';

const KEY = 'create-ai';

export type CreateAiBanner = {
  title: string;
  description: string;
  bgUrl: string,
  bgGradient: string,
  btnText: string,
  leftGirlImgUrl: {
    sm: string,
    md: string
  },
  rightGirlImgUrl: {
    sm: string,
    md: string
  }
}

export type CreateAiFeaturesFlag = {
  enabled: boolean;
  banner: CreateAiBanner;
};

export const useCreateAiFeaturesFlag = () => {
  return useFeatureGetter<CreateAiFeaturesFlag>(KEY, {
    enabled: false,
    banner: {
      title: 'Create Custom AI Girlfriend',
      description: 'Craft your dream girlfriend with a few clicks. Customize her your way and bring her to life.',
      bgUrl: '',
      bgGradient: '',
      btnText: 'Create AI Girl',
      leftGirlImgUrl: {
        sm: '',
        md: '',
      },
      rightGirlImgUrl: {
        sm: '',
        md: '',
      },
    },
  });
};
