class EventEmitter<T extends Record<string, any>> {
  private listeners: Map<keyof T, Function[]> = new Map();

  on<K extends keyof T>(eventType: K, listener: (data: T[K]) => void) {
    const listeners = this.listeners.get(eventType) || [];
    listeners.push(listener);
    this.listeners.set(eventType, listeners);
    return () => this.off(eventType, listener);
  }

  off<K extends keyof T>(eventType: K, listenerToRemove: (data: T[K]) => void) {
    const listeners = this.listeners.get(eventType);
    if (!listeners) return;
    const filteredListeners = listeners.filter((listener) => listener !== listenerToRemove);
    this.listeners.set(eventType, filteredListeners);
  }

  emit<K extends keyof T>(eventType: K, data: T[K]) {
    const listeners = this.listeners.get(eventType);
    if (!listeners) return;
    listeners.forEach((listener) => listener(data));
  }
}

export const createEventEmitter = <T extends Record<string, any>>() => new EventEmitter<T>();
