import type { CSSProperties } from 'react';
import { HTMLAttributes, memo } from 'react';

import { CHARACTER_STATUS } from '@repo/api/chat';
import { CharacterStatus } from '@repo/modules/character-status';
import { useSelectCharacterById } from '@repo/modules/entity/chat-queries';
import { useIsSubscribed } from '@repo/modules/entity/user-queries';
import { UiAvatar } from '@repo/ui-kit/ui-avatar';

import { useBrandFeatures } from '@/entities/brand';
import { ConversationItemInfo, ConversationLastMessage, Message } from '@/entities/conversation';

import { cn } from '@/shared/libs/utils';
import { Nullable } from '@/shared/types';
import { Badge, PremiumBadge } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  status: CHARACTER_STATUS;

  characterId: number;

  lastMessage: Nullable<Message>;

  unreadCount: number;
}

export const ConversationItem = memo((props: Props) => {
  const { className, characterId, lastMessage, status, unreadCount, ...restProps } = props;

  const character = useSelectCharacterById(characterId);
  const userHasSubscription = useIsSubscribed();

  const features = useBrandFeatures();

  const premiumBadgeIcon =
    typeof features?.premium_badge_content !== 'string' ? features?.premium_badge_content?.icon : undefined;
  const premiumBadgeLabel =
    typeof features?.premium_badge_content === 'string' ? features?.premium_badge_content : undefined;

  const name = character?.name ?? '';

  return (
    <div
      className={cn(
        'grid w-full grid-cols-[60px_1fr] gap-2.5 px-3 py-2 relative',
        'before:absolute before:right-0 before:bottom-0 before:w-[calc(100%-82px)] before:h-[1px] before:bg-grey-600',
        className,
      )}
      {...restProps}
    >
      <UiAvatar.Root size="xl">
        <UiAvatar.LazyImage src={character?.picture_url ?? ''} alt={name} />
        <UiAvatar.Fallback>{name.slice(0, 2).toUpperCase()}</UiAvatar.Fallback>
      </UiAvatar.Root>
      <div className="flex flex-col gap-1 grow h-full justify-between">
        <ConversationItemInfo name={character?.name ?? ''} createdDate={lastMessage?.created_at} />
        <div className="flex items-center gap-2 grow shrink-0 ">
          <div className="flex items-center gap-2 grow">
            {status > CHARACTER_STATUS.ONLINE ? (
              <CharacterStatus
                style={
                  {
                    '--color-icon': 'rgb(var(--color-brand-primary))',
                    '--color-text': 'rgb(var(--color-primary-font, rgb(38,38,38)), 0.7)',
                  } as CSSProperties
                }
                status={status}
              />
            ) : (
              <ConversationLastMessage hasSubscription={userHasSubscription} lastMessage={lastMessage} />
            )}
          </div>

          <div>
            {!userHasSubscription && character?.is_premium ? (
              <PremiumBadge iconKey={premiumBadgeIcon} label={premiumBadgeLabel} />
            ) : (
              <Badge count={unreadCount} maxCount={99} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
