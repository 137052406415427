import { useEffect, useState } from 'react';

import { useBrandConstants } from '@/entities/brand';

import { Analytic } from '@/shared/services/analytic';

export const useNotificationPermission = () => {
  const [permission, setPermission] = useState<NotificationPermission | null>(null);

  const constants = useBrandConstants();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        setPermission(permission);
        if (permission === 'granted') {
          Analytic.pushNotificationEnabled();
          console.log('Notification permission granted.');
        } else {
          Analytic.pushNotificationDisabled();
          console.log('Notification permission denied.');
        }
      } catch (error) {
        console.error('An error occurred while requesting notification permission.', error);
      }
    };

    requestPermission();
  }, []);

  if (!constants?.firebase_web_keys || !Object.entries(constants?.firebase_web_keys).length) {
    return null;
  }

  return permission;
};
