import {ChatRepository, ResetCharacterInfoParams} from '@repo/api/chat';
import {useMutation} from '@tanstack/react-query';
import {InnerUseMutationOptions} from "@repo/common/types/react-query";

export const useResetCharacterInfoMutation = (
  options?: InnerUseMutationOptions<unknown, unknown, ResetCharacterInfoParams>,
) => {
  return useMutation({
    ...options,
    mutationFn: (params) => ChatRepository.resetCharacterInfo(params),
  });
};
