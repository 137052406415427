import { SubscriptionInterval, SubscriptionType } from '@/core/constants';

import { Nullable } from '@/shared/types';

export type SubscriptionModel = {
  id: string;
  object: string;
  active: boolean;
  /**
   * UNIX timestamp
   */
  created: number;
  currency: string;
  custom_unit_amount: Nullable<unknown>;
  lookup_key: SubscriptionType;
  product: string;
  nickname: Nullable<string>;
  tax_behavior: string;
  metadata: unknown;
  tiers_mode: Nullable<unknown>;
  transform_quantity: Nullable<unknown>;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  recurring: SubscriptionRecurring;
  sale_percentage: number;
  initial_amount?: number;
  valid_until_period?: number;
  period_until_unit?: string;
};

export type FintmPack = {
  product_id: string;
  type: string;
  value: number;
  price: number;
  sale_percentage: number;
  is_best: boolean;
};

export type InternalPackView = {
  id: string;
  value: number;
  price: number;
  salePercentage: number;
  isBest: boolean;
};

export type CreateFintmPaymentDTO = {
  name?: Nullable<string>;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  city?: Nullable<string>;
  country?: Nullable<string>;
  zip_code?: Nullable<string>;
  product_id: string;
  rtkclickid_store?: Nullable<string>;
};

export type CreatedFintmPaymentModel = {
  url: string;
  uuid: string;
  expiry_datetime: string;
  order_id: string;
  vpa: string;
  upi_intent_url: string;
  payment_request_id: string;
};

export type SubscriptionRecurring = {
  aggregate_usage: Nullable<unknown>;
  interval: SubscriptionInterval;
  interval_count: number;
  meter: Nullable<unknown>;
  trial_period_days: Nullable<unknown>;
  usage_type: string;
};

export type CustomerModel = {
  customerId: string;
};

export type CreatedSubscriptionModel = {
  clientSecret: string;
};

export type CreateSubscriptionDto = {
  customerId: string;

  priceId: string;

  rtkclickid_store?: Nullable<string>;
};

export type CreatePaymentIntentDto = {
  pack: string;
  rtkclickid_store?: Nullable<string>;
};

export enum SUBSCRIPTION_PERIOD {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export enum RECURRING_PRICE_RECALCULATION {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  NONE = 'none',
}

export enum SUBSCRIPTION_PLAN {
  PRO = 'PRO',
  PRO_PLUS = 'PRO_PLUS',
}

export enum SUBSCRIPTIONS_VIEW {
  DEFAULT = 0,
  SQUARE = 1,
}

export type SubscriptionQuery = {
  lookup_keys?: SUBSCRIPTION_PLAN[];
};

export type FintmSubscription = {
  product_id: string;
  type: string;
  price: number;
  currency: string;
  subscription_plan: SUBSCRIPTION_PLAN;
  subscription_period: SUBSCRIPTION_PERIOD;
  initial_amount?: number;
  valid_until_period?: number;
  period_until_unit?: string;
  sale_percentage: number;
  is_best: boolean;
};

export type ConstantposPack = {
  product_id: string;
  id: string;
  type: string;
  value: number;
  price: number;
  sale_percentage: number;
  is_best: boolean;
};

export type ConstantposSubscription = {
  id: string;
  product_id: string;
  type: string;
  price: number;
  currency: string;
  subscription_plan: SUBSCRIPTION_PLAN;
  subscription_period: SUBSCRIPTION_PERIOD;
  sale_percentage: number;
  initial_amount?: number;
  valid_until_period?: number;
  period_until_unit?: string;
  is_best: boolean;
};

export type CreateConstantposPaymentModel = {
  pack_id: string;
  email: string;
  type: string;
  account: string;
  first_name: string;
  last_name: string;
  rtkclickid_store?: Nullable<string>;
};

export type PaymentGatewayPack = {
  product_id: string;
  id: string;
  type: string;
  value: number;
  price: number;
  currency: string;
  sale_percentage: number;
  is_best: boolean;
};

export type PaymentGatewaySubscription = {
  id: string;
  product_id: string;
  type: string;
  price: number;
  currency: string;
  subscription_plan: SUBSCRIPTION_PLAN;
  subscription_period: SUBSCRIPTION_PERIOD;
  sale_percentage: number;
  is_best: boolean;
};

export type PaymentGatewayCreateDto = {
  product_id: string;
  email: string;
  rtkclickid_store?: Nullable<string>;
};

export type EpochPack = {
  product_id: string;
  id: string;
  type: string;
  value: number;
  price: number;
  currency: string;
  sale_percentage: number;
  is_best: boolean;
};

export type EpochSubscription = {
  id: string;
  product_id: string;
  type: string;
  price: number;
  currency: string;
  subscription_plan: SUBSCRIPTION_PLAN;
  subscription_period: SUBSCRIPTION_PERIOD;
  sale_percentage: number;
  is_best: boolean;
  frequency: number;
  initial_amount?: number;
  valid_until_period?: number;
  period_until_unit?: string;
  timedelta_days: number;
};

export type EpochCreateDto = {
  pack: string;
  rtkclickid_store?: Nullable<string>;
};
