import { chatEndpoints, messageEndpoints } from '../../api/api-dictionary';
import { FirstParam, Nullable } from '../../types/utility';

export enum MESSAGE_SENDER {
  VIEWER = 1,
  CHARACTER,
}

export enum MESSAGE_CONTENT {
  TEXT = 1,
  VOICE,
  PICTURE,
}

export enum CHARACTER_STATUS {
  UNPAID = 1,
  OFFLINE,
  ONLINE,
  TYPING,
  RECORDING_VOICE,
  SENDING_PHOTO,
  RECORDING_VIDEO,
}

export type CharacterEntity = {
  id: number;
  name: string;
  age: number;
  picture_url: string;
  bio: string;
  is_default: boolean;
  is_premium: boolean;
  public_pic_urls: Nullable<string[]>;
  private_pic_urls: CharacterPrivatePictureEntity[];
  chat_id: number;
  character_variant: number;
  message_price: number;
  msg_count: number;
  interests: Nullable<string[]>;
  suggestions: string[];
  default_suggests: string[];
  override: Nullable<CharacterOverrideEntity>;
  default: Nullable<CharacterOverrideEntity>;
};

export type CharacterPrivatePictureEntity = {
  key: string;
  url: string;
};

export type CharacterOverrideEntity = {
  name?: string;
  picture_url?: string;
  bio?: string;
  age?: number;
  interests?: string[];
  public_pic_urls?: string[];
  sexy_meter?: number;
  fun_meter?: number;
  mystic_meter?: number;
};

export type UploadCharacterPictureResponse = {
  blurUrl: string;
  photoUrl: string;
  thumbUrl: string;
};

export type UpdateCharacterInfoParams = FirstParam<typeof chatEndpoints.updateCharacterInfo>;

export type ResetCharacterInfoParams = FirstParam<typeof chatEndpoints.resetCharacterInfo>;

export type UploadCharacterPictureDTO = { file: File | Blob };

export type UpdateCharacterInfoDTO = {
  name: string;
  picture_url: string;
  bio: string;
  age: number;
  interests: string[];
  public_pic_urls: string[];
  character: Record<string, number>;
};

export type MessageFeedbackEntity = {
  is_feedback_positive: boolean;
  negative_feedback_reason: Nullable<string>;
};

export type ChatEntity = {
  id: number;
  character_id: number;
  type: number;
  name: string;
  unread_count: number;
  status: CHARACTER_STATUS;
  last_message: Nullable<MessageEntity>;
  is_delivered: boolean;
  is_premium: boolean;
  has_deposits: boolean;
  is_subscribed: boolean;
  subscription_plan: number;
  credits: number;
};

export type GetCharacterQuery = {
  include_user_created?: boolean;
};

export type MessageEntity = {
  id: number;
  chat_id: number;
  sender: MESSAGE_SENDER;
  body: string;
  type: MESSAGE_CONTENT;
  is_read: boolean;
  is_processed: boolean;
  is_sent: boolean;
  had_subscription: boolean;
  meta?: MessageMetaEntity;
  reactions: MessageReactionEntity[];
  created_at: string;
  feedback: Nullable<MessageFeedbackEntity>;
};

export type MessageReactionEntity = {
  sender: MESSAGE_SENDER;
  emoji: string;
};

export type MessageMetaEntity = {
  is_NSFW: boolean;
  blurUrl: string;
  photoUrl: string;
  thumbUrl: string;
  voiceUrl: string;
};

export type CreateChatDTO = {
  character_id: string;
};

export type SendMessageDTO = {
  body: string;
  type: MESSAGE_CONTENT;
  meta?: MessageMetaEntity;
};

export type EditMessageDTO = {
  body: string;
  reactions: MessageReactionEntity[];
  is_read: boolean;
};

export type VoiceMessageDTO = {
  body: string;
  type: number;
  meta: {};
};

export type VoiceMessageResponse = {
  url: string;
};

export type VoiceMessageParams = FirstParam<typeof chatEndpoints.voiceMessage>;

export type GetMessagesParams = FirstParam<typeof chatEndpoints.getMessages>;

export type EditMessageParams = FirstParam<typeof messageEndpoints.editMessage>;

export type SendMessageParams = FirstParam<typeof chatEndpoints.sendMessage>;

export type SendMessageFeedbackParams = FirstParam<typeof messageEndpoints.sendMessageFeedback>;

export type CreateChatResponse = {
  id: number;
  user_id: number;
  character_id: number;
  status: CHARACTER_STATUS;
};

export type MessageFeedbackDTO = {
  is_feedback_positive: Nullable<boolean>;
  negative_feedback_reason?: Nullable<string>;
};
