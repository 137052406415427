import { ReactNode, memo } from 'react';

import { DialogProvider, useDialogState } from './libs';
import { AnimatePresence } from 'framer-motion';

interface Props {
  children: ReactNode;
}

const DialogComponent = memo(() => {
  const stack = useDialogState.use.stack();

  return (
    <AnimatePresence initial={false} mode="wait">
      {stack.map((dialog) => {
        if (dialog.isHidden) return null;
        return (
          <DialogProvider key={dialog.key} dialogKey={dialog.key} onClose={dialog.close} isHidden={dialog.isHidden}>
            {dialog.component}
          </DialogProvider>
        );
      })}
    </AnimatePresence>
  );
});

export const DialogContainer = (props: Props) => {
  const { children } = props;

  return (
    <>
      {children}
      <DialogComponent />
    </>
  );
};
