import { useFeature } from '../api';
import { useFeatureGetter } from '../api';

export enum DiscoverViewType {
  Default = 1,
  ChatWithProfile,
}

export const useDiscoverViewFlagGetter = () => {
  return useFeatureGetter('discover-view-type', DiscoverViewType.Default);
};

export const useDiscoverViewFlag = () => {
  return useFeature('discover-view-type', DiscoverViewType.Default);
};
