import { CircleKey } from './circle';
import { HeartBeat3DKey } from './heart-beat-3d';
import { LineWobbleKey } from './line-wobble';
import { SimpleKey } from './simple';
import './styles.css';
import { SpinnerProps } from './type';
import loadable from '@loadable/component';
import { cn } from '@repo/common/utils/component';

export type SpinnerKey = typeof HeartBeat3DKey | typeof SimpleKey | typeof LineWobbleKey | typeof CircleKey;

export interface Props extends SpinnerProps {
  k: SpinnerKey;
}

export const UiSpinner = (props: Props) => {
  const { k, className, ...restProps } = props;

  const innerClassName = cn('ui-spinner', className);

  switch (k) {
    case HeartBeat3DKey:
      const HeartBeat3D = loadable(() => import('./heart-beat-3d'), {
        resolveComponent: (module) => module.HeartBeat3D,
      });
      return <HeartBeat3D className={innerClassName} {...restProps} />;
    case SimpleKey:
      const Simple = loadable(() => import('./simple'), {
        resolveComponent: (module) => module.Simple,
      });
      return <Simple className={innerClassName} {...restProps} />;
    case LineWobbleKey:
      const LineWobble = loadable(() => import('./line-wobble'), {
        resolveComponent: (module) => module.LineWobble,
      });
      return <LineWobble className={innerClassName} {...restProps} />;
    case CircleKey:
      const Circle = loadable(() => import('./circle'), {
        resolveComponent: (module) => module.Circle,
      });
      return <Circle className={innerClassName} {...restProps} />;
    default:
      return null;
  }
};
