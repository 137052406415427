import { useEffect } from 'react';

import { DIALOG_KEYS } from '@/core/constants';
import { Navigate, createFileRoute, useNavigate } from '@tanstack/react-router';

import { type EmailConfirmSearch, emailConfirmSearchSchema, useConfirmEmailMutation } from '@/entities/auth';

import { Alert, SplashScreen, dialog } from '@/shared/ui';

export const Route = createFileRoute('/email/confirm')({
  component: EmailConfirmComponent,
  errorComponent: () => <Navigate to="/conversations" />,
  validateSearch: (search): EmailConfirmSearch => emailConfirmSearchSchema.parse(search),
});

function EmailConfirmComponent() {
  const { t } = Route.useSearch();

  const navigate = useNavigate({ from: '/email/confirm' });

  const { mutateAsync: confirmEmail } = useConfirmEmailMutation();

  useEffect(() => {
    confirmEmail(t, {
      onSuccess: () => {
        dialog.open({
          key: DIALOG_KEYS.EMAIL_CONFIRM,
          component: (
            <Alert
              withCloseButton={false}
              type="success"
              title="Email confirmed"
              onOkClick={() => {
                dialog.close({ key: DIALOG_KEYS.EMAIL_CONFIRM });
                navigate({ to: '/conversations', search: { fromConfirm: true } });
              }}
              cancelBtnProps={{ className: 'hidden' }}
              withActions
            />
          ),
        });
      },
    });
  }, []);

  return <SplashScreen spinnerKey="line-wobble" />;
}
