import { CONTENT_TYPE } from '../../constants/content-type';
import { InternalAxiosRequestConfig } from 'axios';

export const requestFulfilledInterceptor = (config: InternalAxiosRequestConfig, extractor?: () => string | null) => {
  const contentType = config.headers['Content-Type'] ?? CONTENT_TYPE.JSON;

  const withAuth: boolean = config.headers.withAuth ?? true;

  const token = extractor?.();

  if (withAuth && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Content-Type'] = contentType;

  return config;
};
