import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentPropsWithoutRef<'textarea'> {}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { className, ...restProps } = props;

  return (
    <textarea
      ref={ref}
      className={cn(
        ' border border-grey-600/15 p-3 rounded-lg bg-grey-800 ring-offset-brand-primary text-title6 text-grey-content-800 py-3 px-4 resize-none outline-none',
        className,
      )}
      rows={4}
      {...restProps}
    />
  );
});
