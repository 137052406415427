import { useFeatureGetter } from '../api';

const KEY = 'face-swap';

export type FaceSwapFlag = {
  customiseAi: boolean;
  chat: boolean
};

export const useFaceSwapGetter = () => {
  return useFeatureGetter<FaceSwapFlag>(KEY, {
    customiseAi: true,
    chat: true,
  });
};
