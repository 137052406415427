import { OnboardingStage, OnboardingStageSchema, OnboardingStageVariant } from '../types';

import { randomUuid, removeNullUndefined } from '@/shared/libs/utils';

class OnboardingStageFactory {
  createStage = (details: OnboardingStage, stepNumber: number) => {
    const data = this.validate(details);

    if (!data.success) {
      throw new Error(data.error.toString());
    }

    const value = data.data;

    const additionalData = {
      stepNumber,
    };

    switch (value.variant) {
      case OnboardingStageVariant.BadgeGrid:
        return removeNullUndefined({
          ...additionalData,
          ...value,
          options: value.options.map((option) => ({ ...option, id: randomUuid() })),
        });
      case OnboardingStageVariant.BadgeGridWithImage:
        return removeNullUndefined({
          ...additionalData,
          ...value,
          options: value.options.map((option) => ({ ...option, id: randomUuid() })),
        });
      case OnboardingStageVariant.ButtonMaxWidth:
        return removeNullUndefined({
          ...additionalData,
          ...value,
          options: value.options.map((option) => ({ ...option, id: randomUuid() })),
        });
      case OnboardingStageVariant.Badge:
        return removeNullUndefined({
          ...additionalData,
          ...value,
          options: value.options.map((option) => ({ ...option, id: randomUuid() })),
        });
      case OnboardingStageVariant.Sliders:
        return removeNullUndefined({
          ...additionalData,
          ...value,
          options: value.options.map((option) => ({ ...option, id: randomUuid() })),
        });
      default:
        return removeNullUndefined({
          ...additionalData,
          ...value,
        });
    }
  };

  createStages = (details: OnboardingStage[] | string) => {
    const data = typeof details === 'string' ? JSON.parse(details) : details;

    if (!Array.isArray(data)) {
      throw new Error('createStages expects an array or a JSON string representing an array');
    }

    return data.map((detail, index) => this.createStage(detail, index));
  };

  isStartStepStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.StartStep }> {
    return stage.variant === OnboardingStageVariant.StartStep;
  }

  isBadgeStage = (
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.Badge }> => {
    return stage.variant === OnboardingStageVariant.Badge;
  };

  isBadgeGridStage = (
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.BadgeGrid }> => {
    return stage.variant === OnboardingStageVariant.BadgeGrid;
  };

  isBadgeGridWithImageStage = (
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.BadgeGridWithImage }> => {
    return stage.variant === OnboardingStageVariant.BadgeGridWithImage;
  };

  isSlidersStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.Sliders }> {
    return stage.variant === OnboardingStageVariant.Sliders;
  }

  isButtonMaxWidthStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.ButtonMaxWidth }> {
    return stage.variant === OnboardingStageVariant.ButtonMaxWidth;
  }

  isChoosePartnerStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.ChoosePartner }> {
    return stage.variant === OnboardingStageVariant.ChoosePartner;
  }

  isPreparingStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.Preparing }> {
    return stage.variant === OnboardingStageVariant.Preparing;
  }

  isCompleteCreatingStage(
    stage: OnboardingCreateStageResult,
  ): stage is Extract<OnboardingCreateStageResult, { variant: OnboardingStageVariant.CompleteCreating }> {
    return stage.variant === OnboardingStageVariant.CompleteCreating;
  }

  private validate = (details: OnboardingStage) => {
    return OnboardingStageSchema.safeParse(details);
  };
}

export type OnboardingCreateStageResult = ReturnType<OnboardingStageFactory['createStage']>;

export const onboardingStageFactory = new OnboardingStageFactory();
