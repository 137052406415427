import { ReceiveChatsResponse, ReceiveInfoMessage, ResponseSocket } from '../models';
import { queryClient } from '@/core/config/react-query';
import { QUERY_KEYS, SubscriptionPlan } from '@/core/constants';
import { SUBSCRIPTION_PLAN } from '@repo/api/payment';
import type { UserEntity } from '@repo/api/user';
import { meQueryKey } from '@repo/modules/entity/user-queries';

import { type ConversationPreview, Message } from '@/entities/conversation';

import type { Nullable } from '@/shared/types';

const refreshChatsMessages = (chats: ConversationPreview[]) => {
  for (const chat of chats) {
    if (!chat.last_message) continue;
    const messages: Message[] = queryClient.getQueryData([QUERY_KEYS.Messages, chat.id.toString()]) ?? [];
    const isExistMessage = messages.some((message) => message.id === chat.last_message?.id);

    if (!isExistMessage) {
      queryClient.setQueryData([QUERY_KEYS.Messages, chat.id.toString()], (prev: Message[] | undefined) => {
        const _prev = prev ?? [];
        return [..._prev, chat.last_message];
      });
    }
  }
};

type RefreshViewerDTO = {
  creditsAmount: Nullable<number>;
  hasSubscription: boolean;
  subscriptionPlan: SubscriptionPlan;
};

const refreshViewerData = (dto: RefreshViewerDTO) => {
  const { creditsAmount, subscriptionPlan, hasSubscription } = dto;

  queryClient.setQueryData(meQueryKey(), (viewer: UserEntity) => {
    return viewer
      ? {
          ...viewer,
          credits: typeof creditsAmount === 'number' ? creditsAmount : viewer.credits,
          is_subscribed: hasSubscription,
          subscriptionPlan: subscriptionPlan,
        }
      : viewer;
  });
};

const refreshChats = (chats: ConversationPreview[]) => {
  queryClient.setQueryData([QUERY_KEYS.Conversations], chats);
};

export const updateChats = (event: ReceiveChatsResponse) => {
  const { chats } = event;

  const firstChat = chats?.[0];

  refreshChats(chats);
  refreshViewerData({
    creditsAmount: firstChat?.credits ?? null,
    hasSubscription: firstChat?.is_subscribed ?? false,
    subscriptionPlan: firstChat?.subscription_plan ?? SUBSCRIPTION_PLAN.BASIC,
  });
  refreshChatsMessages(chats);
};

const isChatsEvent = (event: ResponseSocket): event is ReceiveChatsResponse => {
  return Object.keys(event).includes('chats');
};

const isMessageEvent = (event: ResponseSocket): event is ReceiveInfoMessage => {
  return Object.keys(event).includes('message');
};

export const socketHandlerManager = (data: ResponseSocket) => {
  if (isChatsEvent(data)) {
    updateChats(data);
    return;
  }

  if (isMessageEvent(data)) {
    return;
  }
};
