import { Nullable } from '../../types/utility';

export enum SUBSCRIPTION_VARIANT {
  PRO_MONTHLY = 'PRO_MONTHLY',
  PRO_ANNUAL = 'PRO_ANNUAL',
  PRO_QUARTERLY = 'PRO_QUARTERLY',
  PRO_WEEKLY = 'PRO_WEEKLY',
  PRO_PLUS_MONTHLY = 'PRO_PLUS_MONTHLY',
  PRO_PLUS_ANNUAL = 'PRO_PLUS_ANNUAL',
  PRO_PLUS_QUARTERLY = 'PRO_PLUS_QUARTERLY',
  PRO_PLUS_WEEKLY = 'PRO_PLUS_WEEKLY',
}

export enum SUBSCRIPTION_TYPE {
  PRO = 'PRO',
  PRO_PLUS = 'PRO_PLUS',
}

export enum SUBSCRIPTION_PLAN {
  BASIC = 1,
  PRO,
  PRO_PLUS,
}

export enum SUBSCRIPTION_INTERVAL {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export enum SUBSCRIPTION_PERIOD {
  MONTH = 'month',
  YEAR = 'year',
  QUARTER = 'quarter',
  WEEK = 'week',
}

export enum PACK_TYPE {
  CREDITS = 'credits',
  CALLS = 'calls',
}

export type StripeSubscriptionEntity = {
  id: string;
  object: string;
  active: boolean;
  created: number;
  currency: string;
  custom_unit_amount: Nullable<unknown>;
  lookup_key: SUBSCRIPTION_VARIANT;
  product: string;
  nickname: Nullable<string>;
  tax_behavior: string;
  metadata: unknown;
  tiers_mode: Nullable<unknown>;
  transform_quantity: Nullable<unknown>;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  recurring: StripeSubscriptionRecurringEntity;
};

export type StripeCreditPack = {
  id: string;
  value: number;
  price: number;
  sale_percentage: number;
  is_best: boolean;
};

export type StripeSubscriptionRecurringEntity = {
  aggregate_usage: Nullable<unknown>;
  interval: SUBSCRIPTION_PERIOD;
  interval_count: number;
  meter: Nullable<unknown>;
  trial_period_days: Nullable<unknown>;
  usage_type: string;
};

export type FintmSubscriptionEntity = {
  product_id: string;
  type: string;
  price: number;
  currency: string;
  subscription_plan: SUBSCRIPTION_PLAN;
  subscription_period: SUBSCRIPTION_INTERVAL;
  is_best: boolean;
};

export type FintmCreditPack = {
  product_id: string;
  type: string;
  value: number;
  price: number;
  sale_percentage: number;
  is_best: boolean;
};

export type CreateFintmPaymentDTO = {
  name?: Nullable<string>;
  email?: Nullable<string>;
  phone?: Nullable<string>;
  city?: Nullable<string>;
  country?: Nullable<string>;
  zip_code?: Nullable<string>;
  product_id: string;
  rtkclickid_store?: Nullable<string>;
};

export type CreateStripeSubscriptionDTO = {
  customerId: string;
  priceId: string;
  rtkclickid_store?: string;
};

export type CreateStripePaymentDTO = {
  pack: string;
  rtkclickid_store?: string;
};

export type CreateStripeCustomerResponse = {
  customerId: string;
};

export type CreateStripeSubscriptionResponse = {
  clientSecret: string;
};

export type CreateFintmPaymentResponse = {
  url: string;
  uuid: string;
  expiry_datetime: string;
  order_id: string;
  vpa: string;
  upi_intent_url: string;
  payment_request_id: string;
};

// Epoch
export type EpochBasePackEntity = {
  id: string;
  type: 'credits' | 'subscription';
  price: number;
  currency: string;
  is_best: boolean;
  product_id: string;
  sale_percentage: number;
};

export type EpochCreditPackEntity = EpochBasePackEntity & {
  value: number;
};

export type EpochSubscriptionPackEntity = EpochBasePackEntity & {
  subscription_plan: SUBSCRIPTION_TYPE;
  subscription_period: SUBSCRIPTION_INTERVAL;
  frequency: Nullable<number>;
  initial_amount: number;
};

export type EpochCreatePaymentIntentDTO = {
  pack: string;
  rtkclickid_store?: string;
};

export type EpochCreatePaymentIntentResponse = {
  redirectUrl: string;
};

// Common
export type GetSubscriptionPacksQuery = {
  lookup_keys: SUBSCRIPTION_TYPE;
};
