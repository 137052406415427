import { useMemo } from 'react';
import { cn } from '@repo/common/utils/component';
import { UiButton, UiButtonProps } from '@repo/ui-kit/ui-button';
import { UiIcon } from '@repo/ui-kit/ui-icon';
import './style.css';

export interface Props extends Omit<UiButtonProps, 'children' | 'size'> {
  label?: string;

  animated?: boolean;
}

export const WelcomeOfferButton = (props: Props) => {
  const { animated = true, label, className, ...restProps } = props;

  const hasLabel = Boolean(label);

  const sizeBtn = useMemo((): UiButtonProps['size'] => {
    if (!hasLabel) return { phone: 'icon', desktop: 'icon-lg' };
    return 'sm';
  }, [hasLabel]);

  return (
    <UiButton
      w="fit"
      data-animated={animated}
      rounded={hasLabel ? 'md' : 'full'}
      className={cn('welcome-offer-button  mod-relative', className)}
      size={sizeBtn}
      {...restProps}
    >
      {!hasLabel ? (
        <UiIcon k="gift-filled" className="mod-relative mod-z-2 mod-text-[20px] tablet:mod-text-[24px]" />
      ) : (
        label
      )}
    </UiButton>
  );
};
