import { UiImageLazy } from '../ui-image';
import type { UiImageLazyProps } from '../ui-image';
import { cn } from '@repo/common/utils/component';

export interface Props extends UiImageLazyProps {}

export const UiAvatarLazyImage = (props: Props) => {
  const { className, ...restProps } = props;
  return <UiImageLazy className={cn('ui-avatar-lazy-image', className)} {...restProps} />;
};
