import { useFeature, useFeatureGetter } from '../api';

const KEY = 'enable-generate-image-page';

export const useEnableGenerateImagePageGetter = () => {
  return useFeatureGetter(KEY, true);
};

export const useEnableGenerateImagePage = () => {
  return useFeature(KEY, true);
};

export const enableGenerateImagePageKey = () => KEY;
