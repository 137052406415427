import { useRef, useState } from 'react';
import { useIsomorphicEffect } from '../../hooks/use-isomorphic-effect';
import { useLatest } from '../../hooks/use-latest';
import { useFeaturesBookContext } from './context';

export const useInvalidateFeaturesBook = () => {
  const { invalidate } = useFeaturesBookContext();

  return invalidate;
};

export const useFeatures = () => {
  const { features } = useFeaturesBookContext();

  return features;
};

export function useFeature<T>(key: string, defaultValue?: T): T {
  const features = useFeatures();

  const [val, setVal] = useState<T>(defaultValue!);
  const memoVal = useRef<T>();

  const getter = () => {
    if (Object.keys(features).length === 0 || !(key in features)) {
      return defaultValue;
    }

    if (memoVal.current !== undefined) return memoVal.current;

    const val = features?.[key].value;

    memoVal.current = val;

    if (val === undefined) {
      return defaultValue;
    }

    return val ?? defaultValue;
  };

  useIsomorphicEffect(() => {
    setVal((prev) => {
      const result = getter();
      return result === prev ? prev : result;
    });
  }, [features]);

  return val;
}

export function useFeatureGetter<T>(key: string, defaultValue?: T): () => T {
  const features = useFeatures();

  const val = useRef<T>();

  const getter = useLatest(() => {
    if (Object.keys(features).length === 0 || !(key in features)) {
      return defaultValue;
    }

    if (val.current !== undefined) return val.current;

    val.current = features?.[key].value;

    return val.current ?? defaultValue;
  });

  return getter.current as () => T;
}

export function useFeaturesBookStatus() {
  const { status } = useFeaturesBookContext();
  return status;
}
