import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { parseConfigToRootVars } from '../helpers';
import { ThemeConfig } from '../types';
import { create } from 'zustand';

import { createSafeContext } from '@/shared/libs/context';
import { createSelectors } from '@/shared/libs/zustand-helpers';

type ThemeVariant = 'light' | 'dark';

export interface ThemeContextValue {
  config: ThemeConfig;

  theme: () => ThemeVariant;

  onHandleAppearance: () => void;
}

const { 0: Provider, 1: useThemeContext } = createSafeContext<ThemeContextValue>('ThemeProvider');

interface Props {
  children: ReactNode;

  config?: ThemeConfig;
}

interface ThemeState {
  theme: ThemeVariant;
}

interface ThemeActions {
  onHandleTheme: (theme?: ThemeVariant) => void;
}

const defaultState: ThemeState = {
  theme: 'light',
};

const useThemeStateBase = create<ThemeActions & ThemeState>()((set) => ({
  ...defaultState,
  onHandleTheme: (theme?: ThemeVariant) =>
    set((state) => {
      if (theme) {
        return { ...state, theme };
      }

      return { ...state, theme: state.theme === 'light' ? 'dark' : 'light' };
    }),
}));

const useThemeState = createSelectors(useThemeStateBase);

export const ThemeProvider = (props: Props) => {
  const { children, config } = props;

  const value: ThemeContextValue = {
    config: config ?? ({} as ThemeConfig),
    onHandleAppearance: useThemeState.use.onHandleTheme,
    theme: useThemeState.use.theme,
  };

  return (
    <>
      <Helmet>
        <style>{parseConfigToRootVars(config as any)}</style>
      </Helmet>
      <Provider value={value}>{children}</Provider>
    </>
  );
};

export { useThemeContext };
