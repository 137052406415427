import { useLayoutEffect, useState } from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const handleCalculateTime = (createdAt: string, cycleDurationSeconds: number): Time => {
  const now = dayjs.utc(new Date().toISOString());
  const createdTime = dayjs.utc(createdAt);

  const elapsedMilliseconds = now.diff(createdTime);
  const totalCycleMilliseconds = cycleDurationSeconds * 1000;
  const remainingMilliseconds = totalCycleMilliseconds - (elapsedMilliseconds % totalCycleMilliseconds);

  return {
    hours: Math.floor(remainingMilliseconds / (1000 * 60 * 60)),
    minutes: Math.floor((remainingMilliseconds / (1000 * 60)) % 60),
    seconds: Math.floor((remainingMilliseconds / 1000) % 60),
  };
};

export const useTimer = (createdAt: string, cycleDurationSeconds: number): Time => {
  const [time, setTime] = useState<Time>(handleCalculateTime(createdAt, cycleDurationSeconds));

  const updateTimer = () => {
    setTime(handleCalculateTime(createdAt, cycleDurationSeconds));
  };

  useLayoutEffect(() => {
    const timer = setInterval(updateTimer, 1000);
    return () => clearInterval(timer);
  }, [createdAt, cycleDurationSeconds]);

  return time;
};
