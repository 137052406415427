import { HTMLAttributes } from 'react';

import { UiIcon, UiIconDictionary } from '@repo/ui-kit/ui-icon';
import { cva } from 'class-variance-authority';

import { SUBSCRIPTIONS_VIEW } from '@/entities/subscription';

import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';

export type BenefitType = { key: string; label: string; icon: UiIconDictionary };

export interface Props<T extends BenefitType> extends HTMLAttributes<HTMLUListElement> {
  benefits: T[];

  subscriptionView?: SUBSCRIPTIONS_VIEW;
}

export const benefitsStyles = cva('text-[--color-dialog]', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: '',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'pb-3',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const SubscriptionBenefitList = <T extends BenefitType>(props: Props<T>) => {
  const { benefits, className, subscriptionView = 0, ...restProps } = props;

  return (
    <ul className={cn('flex flex-col gap-1', className)} {...restProps}>
      {benefits.map((benefit) => {
        return (
          <li key={benefit.label} className="flex items-start gap-2">
            <Typography as="span" variant="3xl" className="inline-flex justify-center items-center text-brand-default">
              <UiIcon k={benefit.icon} />
            </Typography>
            <Typography as="span" weight="semibold" variant="2sm" className={cn(benefitsStyles({ subscriptionView }))}>
              {benefit.label}
            </Typography>
          </li>
        );
      })}
    </ul>
  );
};
