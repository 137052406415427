import type { CSSProperties } from 'react';
import { HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { NAVIGATION_LINKS_TAB_BAR } from './config';
import { NAVIGATION_LINKS } from './config';
import { useEnableGenerateImagePageGetter } from '@repo/common/services/features-book';
import { useCreateAiFeaturesFlag } from '@repo/common/services/features-book';
import { WelcomeOfferButton } from '@repo/modules/entity/payment/ui/welcome-offer-button';
import { useIsSubscribed } from '@repo/modules/entity/user-queries';
import { useMatchRoute } from '@tanstack/react-router';
import { useBreakpoint } from 'use-breakpoint';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import { welcomeOfferModalAction } from '@/features/modals';

import { useCustomiseAi } from '@/entities/customise-ai';
import { Navbar, RootLayoutContextProvider, RootLayoutContextValue } from '@/entities/layout';
import { TabBar } from '@/entities/layout/ui/tab-bar';

import { cn } from '@/shared/libs/utils';
import type { PathTo } from '@/shared/types';

export interface Props extends HTMLAttributes<HTMLDivElement> {}

const tapBarExcludedPaths: PathTo[] = ['/conversations/$chatId', '/chats/$characterId', '/404', '/docs/$doc'];
const welcomeOfferExcludedPaths: PathTo[] = [
  '/conversations/$chatId',
  '/chats/$characterId',
  '/404',
  '/docs/$doc',
  '/create-ai',
];

export const RootLayout = (props: Props) => {
  const { className, children, ...restProps } = props;

  const { 0: isOpenSidebar, 1: setIsOpenSidebar } = useState(false);

  const { breakpoint } = useBreakpoint({ mobile: 0, tablet: 768, desktop: 1280 });

  const isMobile = useMemo(() => breakpoint === 'mobile', [breakpoint]);

  const isSubscribed = useIsSubscribed();

  const matchRoute = useMatchRoute();

  const isHiddenTabBar = tapBarExcludedPaths.some((path) => matchRoute({ to: path }));
  const isHiddenWelcomeOfferButton = welcomeOfferExcludedPaths.some((path) => matchRoute({ to: path }));

  const customiseAi = useCustomiseAi();

  const createAi = useCreateAiFeaturesFlag();

  const enableGenerateImagePage = useEnableGenerateImagePageGetter()();

  const onToggleSidebar = useCallback(() => {
    setIsOpenSidebar((open) => !open);
  }, []);

  const onCloseSidebar = useCallback(() => {
    setIsOpenSidebar(false);
  }, []);

  const onOpenSidebar = useCallback(() => {
    setIsOpenSidebar(true);
  }, []);

  const onWelcomeOfferButtonClick = useCallback(() => {
    welcomeOfferModalAction.open({
      onSuccessPayment: () => {
        welcomeOfferModalAction.close();
      },
      analyticData: {
        placement: 'welcome_offer',
      },
    });
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isOpenSidebar && breakpoint === 'mobile') {
      setIsOpenSidebar(false);
    }
  }, [breakpoint]);

  const value: RootLayoutContextValue = useMemo(
    () => ({
      isMobile,
      isOpenSidebar,
      onToggleSidebar,
      onCloseSidebar,
      onOpenSidebar,
    }),
    [isMobile, isOpenSidebar],
  );

  return (
    <RootLayoutContextProvider value={value}>
      <div className={cn('relative flex h-full transition-all', className)} {...restProps}>
        <Navbar
          navList={NAVIGATION_LINKS({
            isCreateAi: createAi()?.enabled,
            isCustomizeAi: customiseAi?.enabled,
            enabledGenerateImage: enableGenerateImagePage,
          })}
          className={cn(
            'hidden transition-all w-full md:block grow shrink-0 basis-[--sidebar-collapsed-width] max-w-[--sidebar-collapsed-width]',
            {
              'md:basis-[--sidebar-width] md:max-w-[--sidebar-width]': isOpenSidebar,
            },
          )}
        />
        <div className={cn('flex flex-col h-full transition-all grow')}>
          <div
            className={cn('relative grow h-full pb-[68px] tablet:pb-0', {
              'pb-0': isHiddenTabBar,
            })}
          >
            {children}
          </div>
          {!isHiddenTabBar && (
            <TabBar
              className="fixed bottom-0 inset-x-0 tablet:hidden"
              navList={NAVIGATION_LINKS_TAB_BAR({
                isCreateAi: createAi()?.enabled,
                isCustomizeAi: customiseAi?.enabled,
                enabledGenerateImage: enableGenerateImagePage,
              })}
            />
          )}
        </div>
        {!isSubscribed && (
          <WelcomeOfferButton
            onClick={onWelcomeOfferButtonClick}
            className={cn('fixed right-3 bottom-3 tablet:!bottom-3 z-2', {
              'bottom-[80px]': !isHiddenTabBar,
              hidden: isHiddenWelcomeOfferButton,
            })}
            style={
              {
                '--pulse-color': 'var(--background-button-primary)',
              } as CSSProperties
            }
          />
        )}
      </div>
    </RootLayoutContextProvider>
  );
};
