import {
  BaseConversation,
  ConversationPreview,
  CouponPack,
  EditMessageDto,
  Message,
  SendMessageDto,
  SendMessageSchema,
} from './models';
import { HTTP_DICTIONARY } from '@/core/constants';
import { httpClient } from '@/core/services';
import { PACK_TYPE } from '@repo/api/payment';

const getConversations = async () => {
  return httpClient.get<ConversationPreview[]>(HTTP_DICTIONARY.conversations).then((r) => r.data);
};

const getConversationMessages = async (chatId: string) => {
  return httpClient
    .get<Message[]>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.messages, { chatId }))
    .then((r) => r.data);
};

const getCouponPacks = async (packType?: PACK_TYPE) => {
  return httpClient.get<CouponPack[]>(`${HTTP_DICTIONARY.couponPacks}?pack_type=${packType}`).then((r) => r.data);
};

const sendMessage = async (params: SendMessageDto) => {
  const dto = SendMessageSchema.parse(params);
  return httpClient
    .post<Message>(
      HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.sendMessage, { chatId: params.chat_id.toString() }),
      dto,
    )
    .then((r) => r.data);
};

const sendRandomMessage = async (chatId: string) => {
  return httpClient
    .post<Message>(HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.randomMessage, { chatId: chatId }))
    .then((r) => r.data);
};

const createConversation = async (characterId: string) => {
  return httpClient
    .post<BaseConversation>(HTTP_DICTIONARY.conversations, { character_id: characterId })
    .then((r) => r.data);
};

const editMessage = async (dto: EditMessageDto) => {
  return httpClient
    .post<Message>(
      HTTP_DICTIONARY.replaceParams(HTTP_DICTIONARY.editMessage, { messageId: dto.message_id.toString() }),
      dto,
    )
    .then((r) => r.data);
};

export const conversationRepository = {
  getConversations,
  getConversationMessages,
  getCouponPacks,
  sendMessage,
  sendRandomMessage,
  createConversation,
  editMessage,
} as const;
