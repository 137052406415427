import { useMemo } from 'react';

import {
  UseFloatingReturn,
  UseInteractionsReturn,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

interface Payload {
  isOpen: boolean;
  id: string;
  closeOnEsc?: boolean;
  onClose?: (id: string) => void;
}

export const useDialog = (payload: Payload): UseFloatingReturn & UseInteractionsReturn => {
  const { isOpen, id, closeOnEsc, onClose } = payload;

  const data = useFloating({
    open: isOpen,
    onOpenChange: () => onClose?.(id),
    whileElementsMounted: autoUpdate,
    middleware: [offset(0), shift(), flip()],
    strategy: 'fixed',
    nodeId: id,
  });

  const context = data.context;

  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown', escapeKey: closeOnEsc });
  const role = useRole(context, { role: 'dialog' });

  const interactions = useInteractions([dismiss, role]);

  return useMemo(
    () => ({
      ...interactions,
      ...data,
    }),
    [interactions, data],
  );
};
