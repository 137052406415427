import type { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { queryClient } from '@/core/config/react-query';
import { ErrorBoundary, Profiler } from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

interface Props {
  children: ReactNode;
}

export const AppProviders = (props: Props) => {
  const { children } = props;
  return (
    <Profiler>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>{children}</HelmetProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </Profiler>
  );
};
