import { z } from 'zod';

export enum OnboardingStageVariant {
  StartStep = 'start_step',
  Badge = 'badge',
  BadgeGridWithImage = 'badge_grid_image',
  Sliders = 'sliders',
  BadgeGrid = 'badge_grid',
  ButtonMaxWidth = 'button_max_width',
  ChoosePartner = 'choose_partner',
  Preparing = 'preparing',
  CompleteCreating = 'complete_creating',
}

export enum OnboardingSelectionVariant {
  Single = 'single',
  Multiple = 'multiple',
}

export const BaseStageOptionSchema = z.object({
  text: z.string(),
});

export const BadgeGridWithImageOptionSchema = BaseStageOptionSchema.extend({
  image: z.string(),
});

export const SliderOptionSchema = BaseStageOptionSchema.extend({
  subText: z.string(),
  meter: z.string(),
});

export const ButtonOptionSchema = BaseStageOptionSchema.extend({
  value: z.string(),
});

export const BaseStageSchema = z.object({
  title: z.string(),
  headImage: z.string().optional(),
});

export const BaseStageWithSelectionSchema = BaseStageSchema.extend({
  selectionVariant: z.nativeEnum(OnboardingSelectionVariant),
  id: z.string().optional(),
});

export const StartStepStageSchema = BaseStageSchema.extend({
  variant: z.literal(OnboardingStageVariant.StartStep),
  buttonText: z.string(),
});

export const BadgeGridWithImageStageSchema = BaseStageWithSelectionSchema.extend({
  variant: z.literal(OnboardingStageVariant.BadgeGridWithImage),
  options: z.array(BadgeGridWithImageOptionSchema),
});

export const SlidersStageSchema = BaseStageWithSelectionSchema.extend({
  variant: z.literal(OnboardingStageVariant.Sliders),
  options: z.array(SliderOptionSchema),
});

export const BadgeStageSchema = BaseStageWithSelectionSchema.extend({
  variant: z.literal(OnboardingStageVariant.Badge),
  options: z.array(BaseStageOptionSchema),
});

export const BadgeGridStageSchema = BaseStageWithSelectionSchema.extend({
  variant: z.literal(OnboardingStageVariant.BadgeGrid),
  options: z.array(BaseStageOptionSchema),
});

export const ButtonStageSchema = BaseStageWithSelectionSchema.extend({
  variant: z.literal(OnboardingStageVariant.ButtonMaxWidth),
  property: z.string(),
  options: z.array(ButtonOptionSchema),
});

export const ChoosePartnerStageSchema = BaseStageSchema.extend({
  variant: z.literal(OnboardingStageVariant.ChoosePartner),
});

export const PreparingStageSchema = BaseStageSchema.extend({
  variant: z.literal(OnboardingStageVariant.Preparing),
  duration: z.number(),
  loader_type: z.string().optional(),
});

export const CompleteCreatingStageSchema = BaseStageSchema.extend({
  variant: z.literal(OnboardingStageVariant.CompleteCreating),
  buttonText: z.string(),
});

export const OnboardingStageSchema = z.discriminatedUnion('variant', [
  StartStepStageSchema,
  BadgeStageSchema,
  BadgeGridWithImageStageSchema,
  BadgeGridStageSchema,
  SlidersStageSchema,
  ButtonStageSchema,
  ChoosePartnerStageSchema,
  PreparingStageSchema,
  CompleteCreatingStageSchema,
]);

export type OnboardingStage = z.infer<typeof OnboardingStageSchema>;

export type BaseStageOption = z.infer<typeof BaseStageOptionSchema>;
export type BadgeGridWithImageOption = z.infer<typeof BadgeGridWithImageOptionSchema>;
export type SliderOption = z.infer<typeof SliderOptionSchema>;

export type BadgeStage = z.infer<typeof BadgeStageSchema>;
export type BaseStage = z.infer<typeof BaseStageSchema>;
export type BaseStageWithSelection = z.infer<typeof BaseStageWithSelectionSchema>;
export type BadgeGridWithImageStage = z.infer<typeof BadgeGridWithImageStageSchema>;
export type SlidersStage = z.infer<typeof SlidersStageSchema>;
export type BadgeGridStage = z.infer<typeof BadgeGridStageSchema>;
export type ButtonStage = z.infer<typeof ButtonStageSchema>;
export type ChoosePartnerStage = z.infer<typeof ChoosePartnerStageSchema>;
export type PreparingStage = z.infer<typeof PreparingStageSchema>;
export type CompleteCreatingStage = z.infer<typeof CompleteCreatingStageSchema>;
export type StartStepStage = z.infer<typeof StartStepStageSchema>;

export const isBadgeStage = (stage: OnboardingStage): stage is BadgeStage =>
  stage.variant === OnboardingStageVariant.Badge;

export const isBadgeGridWithImageStage = (stage: OnboardingStage): stage is BadgeGridWithImageStage =>
  stage.variant === OnboardingStageVariant.BadgeGridWithImage;

export const isSlidersStage = (stage: OnboardingStage): stage is SlidersStage =>
  stage.variant === OnboardingStageVariant.Sliders;

export const isBadgeGridStage = (stage: OnboardingStage): stage is BadgeGridStage =>
  stage.variant === OnboardingStageVariant.BadgeGrid;
