import { UserEntity, UserRepository } from '@repo/api/user';
import { InnerUseQueryOptions } from '@repo/common/types/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const KEY = 'me';

export const useGetMeQuery = (options?: InnerUseQueryOptions<UserEntity>) => {
  return useQuery({
    ...options,
    queryKey: [KEY],
    queryFn: async () => UserRepository.getMe(),
  });
};

export const useInvalidatedGetMeQuery = () => {
  const qc = useQueryClient();
  return () => qc.invalidateQueries({ queryKey: [KEY] });
};

export const invalidateGetMeQuery = (qc: QueryClient) => {
  return qc.invalidateQueries({ queryKey: [KEY] });
};

export const meQueryKey = () => [KEY];
