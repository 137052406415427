import { ReactNode, useCallback, useState } from 'react';

import { useBreakpoint } from 'use-breakpoint';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

import { createSafeContext } from '@/shared/libs/context';

interface ConversationSidebarContextValue {
  sidebarOpen: boolean;

  isSidebarAbsolute: boolean;

  onOpenSidebar: () => void;

  onCloseSidebar: () => void;

  onToggleSidebar: () => void;
}

export interface ConversationSidebarContextProps {
  children: ReactNode;

  chatId?: string;
}

const [Provider, useConversationSidebarContext] = createSafeContext<ConversationSidebarContextValue>('LayoutContext');

const ConversationSidebarProvider = (props: ConversationSidebarContextProps) => {
  const { chatId, children } = props;

  const { breakpoint } = useBreakpoint({
    mobile: 0,
    tablet: 768,
    desktop: 1280,
  });

  const { 0: sidebarOpen, 1: setSidebarOpen } = useState(false);

  const { 0: isSidebarAbsolute, 1: setIsSidebarAbsolute } = useState(false);

  const onOpenSidebar = useCallback(() => setSidebarOpen(true), []);

  const onCloseSidebar = useCallback(() => setSidebarOpen(false), []);

  const onToggleSidebar = useCallback(() => setSidebarOpen((open) => !open), []);

  const value: ConversationSidebarContextValue = {
    sidebarOpen,
    isSidebarAbsolute,
    onOpenSidebar,
    onCloseSidebar,
    onToggleSidebar,
  };

  useIsomorphicLayoutEffect(() => {
    if (breakpoint === 'mobile' && !isSidebarAbsolute) {
      setIsSidebarAbsolute(true);
    } else {
      setIsSidebarAbsolute(false);
    }
  }, [breakpoint]);

  useIsomorphicLayoutEffect(() => {
    if (!chatId && isSidebarAbsolute) {
      onOpenSidebar();
    }
  }, [isSidebarAbsolute, chatId]);

  return <Provider value={value}>{children}</Provider>;
};

export { ConversationSidebarProvider, useConversationSidebarContext };
