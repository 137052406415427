import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { HTMLAttributes, useEffect } from 'react';

import { radioGroupStyles } from './buy-subscription-modal.config';
import { subscriptionsContent, titleStyles } from './buy-subscription-modal.config';
import { DIALOG_KEYS, SubscriptionTier } from '@/core/constants';
import { SUBSCRIPTION_INTERVAL } from '@repo/api/payment';
import { useFeature } from '@repo/common/services/features-book';

import { DiscountBanner } from '@/features/discount-banner';

import { useBrandFeatures } from '@/entities/brand';
import { ModalPreviewImg } from '@/entities/modals';
import { SUBSCRIPTIONS_VIEW } from '@/entities/subscription';
import { PriceRadioBtn, SubscriptionBenefitList, useSubscription } from '@/entities/subscription';

import { CURRENCY_CODE } from '@/shared/constants';
import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Alert, Button, SpinnerIcon, Typography, dialog } from '@/shared/ui';
import { CloseButton } from '@/shared/ui/close-button';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  subscriptionVariant?: SubscriptionTier;

  imgSrc?: string;

  characterPicture?: string;

  analyticData?: any;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err: string | undefined) => void;

  isPaymentModal?: boolean;

  onSuccessOnboarding?: () => void;
}

export const BuySubscription = (props: Props) => {
  const {
    subscriptionVariant = SubscriptionTier.PRO,
    analyticData,
    imgSrc,
    characterPicture,
    onSuccessPayment,
    onFailedPayment,
    className,
    isPaymentModal,
    onSuccessOnboarding,
  } = props;

  const features = useBrandFeatures();

  const currentContent = subscriptionsContent(features)[subscriptionVariant];

  const currencyCode = features?.currency_code ?? CURRENCY_CODE.USD;

  const subscriptionView = useFeature<SUBSCRIPTIONS_VIEW>('subscription_paywall_view', SUBSCRIPTIONS_VIEW.DEFAULT);

  const { subscriptionList, selectedSubscription, setSelectedSubscription, handleClickContinue, isFetching } =
    useSubscription({
      subscriptionVariant,
      analyticData,
      onSuccessPayment,
      onFailedPayment,
      onSuccessOnboarding,
    });

  const isBlurred = Boolean(imgSrc);

  const isTrialPeriod = selectedSubscription?.initialAmount === 0;

  const onChangeSelectedSubscription = (subscriptionId: string) => {
    const sub = subscriptionList.find((sub) => sub.id === subscriptionId);
    setSelectedSubscription(sub as any);
  };

  const isDisabledContinue = !selectedSubscription || isFetching;

  const handleDeleteProgressAlert = () => {
    if (analyticData) {
      Analytic.paywallSkipClick(analyticData);
    }
    dialog.open({
      key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE,
      component: (
        <Alert
          type="error"
          onOkTitle="No"
          onCancelTitle="Delete"
          title="Are you sure you want to delete all the progress?"
          onCancelClick={() => {
            dialog.close({ key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE });
            onSuccessOnboarding?.();
          }}
          onOkClick={() => dialog.close({ key: DIALOG_KEYS.PREVENT_PROGRESS_DELETE })}
          withActions
        />
      ),
      beforeOpen: () => Analytic.quizDeleteProgressView(),
    });
  };

  useEffect(() => {
    if (subscriptionList.length > 0) {
      const bestSubs = subscriptionList.find((sub) => sub.isBest);
      setSelectedSubscription(bestSubs ?? subscriptionList[0]);
    }
  }, [subscriptionList]);

  useEffect(() => {
    if (analyticData) {
      Analytic.paywallScreenView(analyticData);
    }
  }, []);

  const paywallImage = useMemo(() => {
    if (characterPicture) {
      return characterPicture;
    }
    if (imgSrc) {
      return imgSrc;
    }
    return currentContent.picture;
  }, [characterPicture, imgSrc, currentContent.picture]);

  const priceViewVariant = useMemo(() => {
    if (subscriptionList.length >= 3) {
      return SUBSCRIPTIONS_VIEW.SQUARE;
    }
    return subscriptionView;
  }, [subscriptionView, subscriptionList]);

  return (
    <>
      <ModalPreviewImg
        imgSrc={paywallImage}
        isBlurred={isBlurred}
        className={cn(
          'absolute z-[1] w-full h-[calc(100%-370px)] sm:w-1/2 sm:h-full',
          { 'relative grow h-full px-3 sm:px-0 pt-14 sm:pt-0': isBlurred },
          className,
        )}
      />
      {isPaymentModal && (
        <CloseButton className="w-4 h-6 p-0.5 z-10 opacity-70 mt-3 ml-3" onClick={handleDeleteProgressAlert} />
      )}
      <div className={cn('basis-2/3 sm:basis-1/2 grow', { hidden: isBlurred })} />
      <div
        className={cn(
          'relative z-[3] py-3 pt-2 sm:pt-6 -mt-11 sm:mt-0',
          'flex flex-col justify-between shrink-0 gap-4',
          'sm:justify-between sm:basis-1/2 sm:grow',
        )}
      >
        {isBlurred ? (
          <div className="hidden sm:block px-3">
            <Typography as="h6" weight="bold" variant="2xl" className="text-primary-font pb-2">
              {features?.modal_blurred_info.title}
            </Typography>
            <Typography as="span" weight="semibold" variant="base" className="text-secondary">
              {features?.modal_blurred_info.text}
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <Typography
              as="h6"
              weight="bold"
              variant="3xl"
              className={cn(titleStyles({ subscriptionView: priceViewVariant }))}
            >
              {isTrialPeriod ? 'Unlock Trial Period' : currentContent.title[priceViewVariant]}
            </Typography>
            {features?.discount_banner && currentContent.isDiscountBanner[priceViewVariant] && <DiscountBanner />}
            <SubscriptionBenefitList
              subscriptionView={subscriptionView}
              benefits={currentContent.benefits}
              className={cn(
                'px-3',
                features?.discount_banner && currentContent.isDiscountBanner[subscriptionView] && 'grid grid-cols-2',
              )}
            />
          </div>
        )}
        <div className={cn('flex flex-col px-3 gap-2 max-h-[370px] ')}>
          <div
            style={
              {
                '--cols': subscriptionList.length,
              } as CSSProperties
            }
            className={cn(radioGroupStyles({ subscriptionView: priceViewVariant }))}
          >
            {subscriptionList.map((subscription) => {
              const isActive = selectedSubscription?.id === subscription.id;
              return (
                <PriceRadioBtn
                  className="cursor-pointer"
                  onClick={() => onChangeSelectedSubscription(subscription.id)}
                  subscriptionView={priceViewVariant}
                  discount={
                    features?.subscription_discount
                      ? features?.subscription_discount[subscription.subscriptionPeriod]
                      : subscription.salePercentage
                  }
                  initialAmount={subscription.initialAmount}
                  validUntilPeriod={subscription.validUntilPeriod}
                  periodUntilUnit={subscription.periodUntilUnit}
                  currencyCode={currencyCode}
                  key={subscription.id}
                  isActive={isActive}
                  period={subscription.subscriptionPeriod as unknown as SUBSCRIPTION_INTERVAL}
                  price={subscription.price}
                  isBest={subscription.isBest}
                  isTrialPeriod={subscription.initialAmount === 0}
                />
              );
            })}
          </div>
          <Button className="w-full" size="lg" onClick={handleClickContinue} disabled={isDisabledContinue}>
            {isTrialPeriod ? 'Unlock Trial' : currentContent.button[priceViewVariant]}{' '}
            {isDisabledContinue && <SpinnerIcon />}
          </Button>
          <span className="px-3 text-muted-foreground text-xs text-center">
            {features?.subscription_bottom_text
              ? currentContent.bottomText
              : 'You can cancel your subscription at any time'}
          </span>
        </div>
      </div>
    </>
  );
};
