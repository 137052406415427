import { ReactNode } from 'react';

import { CxOptions, cx } from 'class-variance-authority';
import { nanoid } from 'nanoid';
import { twMerge } from 'tailwind-merge';

import { NonNullable } from '@/shared/types';

export function cn(...inputs: CxOptions) {
  return twMerge(cx(inputs));
}

export function randomUuid(size?: number) {
  return nanoid(size);
}

export function removeNullUndefined<T extends Record<string, unknown>>(obj: T): NonNullable<T> {
  return Object.entries(obj).reduce(
    (acc, [k, v]) => {
      if (v !== null && v !== undefined) {
        acc[k] = v;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  ) as NonNullable<T>;
}

export const separateEmojiAndText = (input: string): [string | null, string] => {
  const regex = /\p{Emoji}/u;
  const emojiIndex = input.search(regex);

  if (emojiIndex !== -1) {
    const emoji = input.substring(emojiIndex, emojiIndex + Array.from(input)[emojiIndex].length);
    const text = input.substring(0, emojiIndex) + input.substring(emojiIndex + emoji.length);
    return [emoji, text];
  } else {
    return [null, input];
  }
};

export function shuffleArray<T>(array: T[]): T[] {
  if (array.length <= 1) return array;
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export const mergeComponents = <T extends Function, O extends Record<string, any>>(component: T, rest: O): T & O => {
  return Object.assign(component, rest);
};

export const getURLParamByKey = (params: Record<string, string>, key: string) => {
  return key in params ? params[key] : undefined;
};

export const fetchUrlAsBlob = (url: string) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => blob);
};

export const processReactNode = <T extends ReactNode | ((...args: any) => ReactNode)>(
  node: T,
  args?: T extends (...args: infer A) => ReactNode ? A : never,
) => {
  if (typeof node === 'function') {
    return node(args);
  } else {
    return node;
  }
};

export const calculateDiscountPrice = (price: number, sale: number) => {
  return Math.floor(price * (1 - sale / 100));
};

export const nextRenderTick = (cb: () => void, timeout = 0) => setTimeout(cb, timeout);

export const unixTimestamp = () => Math.floor(Date.now() / 1000);

export const unixTimestampToDate = (timestamp: number) => new Date(timestamp * 1000);

export const isEmptyArr = (arr: unknown[]) => {
  return arr.length === 0;
};

export const extractCookieValue = (cookieName: string) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];
  return cookieValue ?? null;
};

export const submitForm = (formData: any) => {
  const form = document.createElement('form');
  form.method = formData.method;
  form.action = formData.action;

  formData.fields.forEach((field: any) => {
    const input = document.createElement('input');
    input.type = field.type;
    input.name = field.name;
    input.value = field.value;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
};

export const createScript = <Name extends keyof HTMLElementTagNameMap = 'script'>(
  content: string,
  name?: Name,
  payload: Partial<HTMLElementTagNameMap[Name]> = {},
) => {
  const script = document.createElement(name ?? 'script');
  script.innerHTML = content;
  Object.assign(script, payload);
  return {
    mount: () => document.body.appendChild(script),
    remove: () => document.body.removeChild(script),
  };
};
