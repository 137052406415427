import { UiScrollAreaRoot } from './ui-scroll-area-root';
import { UiScrollAreaScrollbar } from './ui-scroll-area-scrollbar';
import { UiScrollAreaThumb } from './ui-scroll-area-thumb';
import { UiScrollAreaViewport } from './ui-scroll-area-viewport';

export const UiScrollArea = {
  Root: UiScrollAreaRoot,
  Viewport: UiScrollAreaViewport,
  Scrollbar: UiScrollAreaScrollbar,
  Thumb: UiScrollAreaThumb,
};
