import { authRequests, GoogleSignUpDTO } from '@repo/api/auth';
import { extractCookieValue } from '@repo/common/utils/helpers';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { CookiesDictionary } from '../../../shared/constants/cookies-dictionary';

export const useGoogleSignUpMutation = (options?: Partial<UseMutationOptions<unknown, unknown, GoogleSignUpDTO>>) => {
  const redTrackId = extractCookieValue(CookiesDictionary.RED_TRACK);
  return useMutation({
    ...options,
    mutationFn: (dto) =>
      authRequests.googleSignUp({
        ...dto,
        ...(redTrackId && { rtkclickid_store: redTrackId }),
      }),
  });
};
