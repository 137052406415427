import type { ReactNode } from 'react';
import { createSafeContext } from '../../utils/context';
import {
  AuthFlowVariantFlag,
  PaymentSystemFlag,
  useAuthFlowVariantGetter,
  usePaymentSystemGetter,
} from './feature-flags';

interface GlobalFeatureFlagContext {
  paymentSystem: PaymentSystemFlag;
  authFlowVariant: AuthFlowVariantFlag;
}

const [Provider, useGlobalFeatureFlagContext] = createSafeContext<GlobalFeatureFlagContext>('GlobalFeatureFlagContext');

const GlobalFeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const paymentSystem = usePaymentSystemGetter();

  const authFlowVariant = useAuthFlowVariantGetter();

  const value = {
    paymentSystem: paymentSystem(),
    authFlowVariant: authFlowVariant(),
  };
  return <Provider value={value}>{children}</Provider>;
};

export { GlobalFeatureFlagProvider, useGlobalFeatureFlagContext };
