import { useCallback, useEffect, useMemo, useState } from 'react';

import { SignInForm } from '../../sign-in-form';
import { SignUpForm } from '../../sign-up-form';
import { DIALOG_KEYS } from '@/core/constants';
import type { UserEntity } from '@repo/api/user';
import { useFeatureGetter } from '@repo/common/services/features-book';
import { meQueryKey } from '@repo/modules/entity/user-queries';
import { UiScrollArea } from '@repo/ui-kit/ui-scroll-area';
import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';

import { GoogleSignUp } from '@/features/google-sign-up';
import { resetPasswordModalAction } from '@/features/modals';

import { useBrandConstants, useBrandFeatures } from '@/entities/brand';

import { renderFromTemplate } from '@/shared/libs/parsers';
import { cn, randomUuid } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { CardWithImage, type CloseDialogArgs, Icon, Modal, type OpenDialogArgs, Typography, dialog } from '@/shared/ui';

export enum AuthType {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
}

export interface Props {
  variant?: AuthType;

  analyticData?: any;

  imgSrc?: string;

  characterPicture?: string;

  onSuccess?: (user: UserEntity) => void;

  onError?: () => void;

  isRequired?: boolean;
}

export const AuthModal = (props: Props) => {
  const {
    imgSrc = '/onbg-chat-about-step-bg.jpg',
    variant = AuthType.SIGNUP,
    analyticData,
    characterPicture,
    onSuccess,
    onError,
    isRequired,
  } = props;

  const { 0: authVariant, 1: setAuthVariant } = useState<AuthType>(variant);

  const handleClickSwitchAuth = useCallback(() => {
    setAuthVariant((prevState) => (prevState === AuthType.SIGNUP ? AuthType.LOGIN : AuthType.SIGNUP));
  }, []);

  const qc = useQueryClient();

  const features = useBrandFeatures();

  const coinsOnSignUp = useFeatureGetter('coins-on-signup', 0);

  const constants = useBrandConstants();

  const signUpWithGoogle = useFeatureGetter('sign-up-with-google', false);

  const isGoogleSignUpEnabled = signUpWithGoogle() && constants?.firebase_web_keys?.client_id;

  const authTitle = useMemo(() => {
    if (authVariant === AuthType.LOGIN) return 'Log In';

    const amountCoinsSignUp = coinsOnSignUp();

    if (amountCoinsSignUp > 0 && authVariant === AuthType.SIGNUP) {
      return (
        <div className="grid grid-cols-1 gap-1 pt-4">
          <Typography weight="bold" variant="4xl">
            Register & Get
            <Typography as="span" weight="bold" variant="4xl" className="pl-1.5 text-brand-primary">
              {amountCoinsSignUp} Coins
            </Typography>
          </Typography>
          <Typography className="text-grey-300">For Chatting With Models!</Typography>
        </div>
      );
    }

    if (authVariant === AuthType.SIGNUP) return 'Create Account';

    return null;
  }, [authVariant]);

  const description = features?.auth_modal?.description?.[authVariant] ?? null;

  const handleOnSuccess = async () => {
    const user = qc.getQueryData<UserEntity>(meQueryKey());

    if (user) {
      onSuccess?.(user);
    }
    dialog.close({ key: DIALOG_KEYS.AUTH });
  };

  useEffect(() => {
    if (authVariant === AuthType.SIGNUP) Analytic.signUpScreenView(analyticData);
    if (authVariant === AuthType.LOGIN) Analytic.loginScreenView(analyticData);
  }, [authVariant]);

  const authForm = useMemo(() => {
    switch (authVariant) {
      case AuthType.LOGIN:
        return (
          <SignInForm
            onClickForgotPassword={() => resetPasswordModalAction.open()}
            analyticData={analyticData}
            onSuccess={handleOnSuccess}
            onError={onError}
          />
        );
      case AuthType.SIGNUP:
        return <SignUpForm analyticData={analyticData} onSuccess={handleOnSuccess} onError={onError} />;
    }
  }, [authVariant]);

  const templateDescription = renderFromTemplate(
    { vars: {}, value: description?.text ?? '' },
    {
      span: (el) => (
        <Typography
          key={randomUuid()}
          weight="semibold"
          className="bg-brand-default px-2 py-1 mx-1 rounded-md text-sm text-brand-default-content flex items-center"
        >
          {description?.icon && <Icon k={description?.icon} className="text-xl pr-1" />}
          {el}
        </Typography>
      ),
    },
  );

  const dialogImgSrc = useMemo(() => {
    if (characterPicture) return characterPicture;
    if (features?.auth_modal?.[authVariant]) return features?.auth_modal?.[authVariant];

    return imgSrc;
  }, [characterPicture, imgSrc, features?.auth_modal, authVariant]);

  return (
    <Modal.Root
      closeOnEscape={!isRequired}
      closeOnClickOutside={!isRequired}
      bodyClassName="h-full sm:h-auto grow sm:grow-0"
    >
      <Modal.CloseBtn
        variant="grey"
        size="sm"
        className={cn('absolute sm:max-w-5 top-4 left-4 sm:left-auto sm:right-4 bg-white/20', { hidden: isRequired })}
      />
      <Modal.Content className="h-full w-screen sm:max-w-[740px] grow sm:grow-0">
        <UiScrollArea.Root className="overflow-hidden h-full">
          <UiScrollArea.Viewport className="scroll-smooth h-full">
            <CardWithImage
              imgSrc={dialogImgSrc}
              label={authTitle}
              description={description ? templateDescription : null}
              className="h-full sm:h-auto"
            >
              <AnimatePresence mode="wait" initial={false}>
                <motion.div
                  key={authVariant}
                  initial={{ transform: 'translateX(-100%)' }}
                  animate={{
                    transform: 'translateX(0)',
                  }}
                  exit={{
                    transform: 'translateX(120%)',
                  }}
                  className="flex flex-col justify-between grow gap-4 overflow-hidden pt-4"
                >
                  <div className="flex flex-col gap-4">
                    <div className="transition-all">{authForm}</div>
                    {isGoogleSignUpEnabled && (
                      <>
                        <div className="flex items-center">
                          <div className="bg-grey-600 h-px w-full" />
                          <span className="px-2 font-medium text-xs">or</span>
                          <div className="bg-grey-600 h-px w-full" />
                        </div>
                        <div className="flex justify-center">
                          <GoogleSignUp analyticData={analyticData} onSuccess={handleOnSuccess} onError={onError} />
                        </div>
                      </>
                    )}
                    <Typography variant="2xs" weight="medium" className="text-center text-secondary">
                      {authVariant === AuthType.SIGNUP ? 'Already have an account yet?' : "Don't have an account yet?"}{' '}
                      <button className="text-primary-font font-semibold" onClick={handleClickSwitchAuth}>
                        {authVariant === AuthType.SIGNUP ? 'Log In' : 'Sign Up'}
                      </button>
                    </Typography>
                  </div>
                  {authVariant === AuthType.SIGNUP && (
                    <div className="text-center">
                      <div className="bg-grey-600 h-px w-full" />
                      <Typography as="span" variant="xs" className="text-secondary">
                        By signing up, you agree to our
                        <Typography variant="xs" as="span" weight="semibold" className="pl-0.5">
                          Terms of Use
                        </Typography>
                      </Typography>
                    </div>
                  )}
                </motion.div>
              </AnimatePresence>
            </CardWithImage>
          </UiScrollArea.Viewport>
          <UiScrollArea.Scrollbar>
            <UiScrollArea.Thumb />
          </UiScrollArea.Scrollbar>
        </UiScrollArea.Root>
      </Modal.Content>
    </Modal.Root>
  );
};

const openAuthModal = (props?: Props, other?: Omit<Partial<OpenDialogArgs>, 'component'>) => {
  dialog.open({
    key: DIALOG_KEYS.AUTH,
    component: <AuthModal {...(props || {})} />,
    ...other,
  });
};

const closeAuthModal = (other?: CloseDialogArgs) => {
  dialog.close({ key: DIALOG_KEYS.AUTH, ...other });
};

export const authModalAction = {
  open: openAuthModal,
  close: closeAuthModal,
} as const;
