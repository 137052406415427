import { CharacterStatus, MessageContentType, MessageSenderType } from '@/core/constants';
import { z } from 'zod';

import { Nullable } from '@/shared/types';

export type MessageMeta = {
  is_NSFW: boolean;
  blurUrl: string;
  photoUrl: string;
  thumbUrl: string;
  voiceUrl: string;
};
export type Message = {
  id: number;
  chat_id: number;
  sender: MessageSenderType;
  body: string;
  type: MessageContentType;
  is_read: boolean;
  is_processed: boolean;
  is_sent: boolean;
  had_subscription: boolean;
  meta?: MessageMeta;
  reactions: [];
  created_at: string;
};

export type BaseConversation = {
  id: number;
  user_id: number;
  character_id: number;
  status: CharacterStatus;
};

export type ConversationPreview = {
  id: number;
  character_id: number;
  type: number;
  name: string;
  unread_count: number;
  status: CharacterStatus;
  last_message: Nullable<Message>;
  is_delivered: boolean;
  is_premium: boolean;
  has_deposits: boolean;
  is_subscribed: boolean;
  subscription_plan: number;
  credits: number;
};

export type CouponPack = {
  id: string;
  value: number;
  price: number;
  product_id: Nullable<number>;
  sale_percentage: number;
  is_best: boolean;
};

export type EditMessageDto = {
  message_id: number;

  is_read: boolean;
};

export const SendMessageSchema = z.object({
  chat_id: z.number(),
  body: z.string().min(1, { message: 'Message must not be empty.' }),
  type: z.nativeEnum(MessageContentType),
  meta: z.object({}),
});

export type SendMessageDto = z.infer<typeof SendMessageSchema>;

export type SendMessageParams = Omit<SendMessageDto, 'meta'>;
