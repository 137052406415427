import { memo } from 'react';

import type { SpinnerProps } from '../types';
import './index.css';

import { cn } from '@/shared/libs/utils';

export const HeartBeat3DKey = 'heart-beat-3d';
export interface Props extends SpinnerProps {}

export const HeartBeat3D = memo((props: Props) => {
  const { className, ...restProps } = props;

  return <span className={cn('heart-beat-3d', className)} {...restProps}></span>;
});
