import { HTMLAttributes, useEffect } from 'react';

import { useConversationSidebarContext } from '../../libs';
import { ReactNode } from '@tanstack/react-router';

import { cn, processReactNode } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen?: boolean;
  rightSection?: ReactNode | (() => ReactNode);
}

export const ConversationSidebar = (props: Props) => {
  const { className, children, rightSection, ...restProps } = props;

  const { sidebarOpen, isSidebarAbsolute } = useConversationSidebarContext();

  useEffect(() => {
    Analytic.conversationListView();
  }, []);

  return (
    <div
      className={cn(
        'relative flex flex-col grow transition-all bg-body z-1',
        {
          'absolute left-0 inset-y-0 w-full h-full': isSidebarAbsolute,
          '-translate-x-full': !sidebarOpen && isSidebarAbsolute,
        },
        className,
      )}
      {...restProps}
    >
      <div className="flex items-center border-b border-grey-600 p-3 gap-3">
        <Typography as="h5" weight="bold" variant="4xl" className="mr-auto text-grey-content-700">
          Chats
        </Typography>
        <div className="flex items-center gap-2">
          {processReactNode(rightSection)}
          {/*<Link className="md:hidden" to="/settings" onClick={Analytic.settingsClick}>*/}
          {/*  <Button asChild size="icon" variant="outline">*/}
          {/*    <Settings />*/}
          {/*  </Button>*/}
          {/*</Link>*/}
        </div>
      </div>
      {children}
    </div>
  );
};
