import { HTMLAttributes, ReactNode } from 'react';

import { ChevronLeftIcon } from 'lucide-react';

import { cn } from '@/shared/libs/utils';
import { Button, Typography } from '@/shared/ui';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  onClickEdit?: () => void;

  isEdit: boolean;

  btnLabel: string;

  btnDisabled?: boolean;

  btnChevron: ReactNode;

  onClose: () => void;

  onClickReset: () => void;
}

export const DrawerHeader = (props: Props) => {
  const { isEdit, btnLabel, btnDisabled, btnChevron, onClickEdit, onClose, onClickReset, className, ...restProps } =
    props;

  return (
    <div className={cn('w-full', className)} {...restProps}>
      <Button onClick={onClose} size="icon" variant="outline" className="bg-brand-default-content">
        <ChevronLeftIcon className="size-5 text-brand-primary" />
      </Button>
      {isEdit && (
        <Typography as="h5" weight="semibold" className="text-primary-font">
          Edit Profile
        </Typography>
      )}
      <Button
        onClick={isEdit ? onClickReset : onClickEdit}
        disabled={btnDisabled}
        w="auto"
        className="px-4"
        variant={isEdit ? 'outline' : 'primary'}
      >
        <Typography as="span" className={'inline-flex gap-1 text-inherit'}>
          {btnChevron}
          <Typography as="span" weight="medium" className="text-inherit">
            {btnLabel}
          </Typography>
        </Typography>
      </Button>
    </div>
  );
};
