import { CloseBtn } from '../_close-btn';
import { DialogLayout, DialogLayoutProps } from '../_dialog-layout';
import { ModalContent } from './content';
import { ModalHeader } from './header';

import { cn } from '@/shared/libs/utils';

export interface Props extends DialogLayoutProps {}

const _Modal = (props: Props) => {
  const { className, ...restProps } = props;

  return <DialogLayout className={cn('z-modal', className)} {...restProps} />;
};

export const Modal = {
  Root: _Modal,
  Content: ModalContent,
  CloseBtn,
  Header: ModalHeader,
};
