import { createSafeContext } from '@repo/common/utils/context';
import { StripeAddressElementOptions } from '@stripe/stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';

interface StipeConfig extends Omit<StripeElementsOptions, 'clientSecret'> {
  publicKey: string;
  addressElementOptions?: StripeAddressElementOptions;
}

export interface ModuleConfigValue {
  stripe: StipeConfig;
}

export const [ModuleConfigProvider, useModuleConfigContext] =
  createSafeContext<ModuleConfigValue>('ModuleConfigContext');
