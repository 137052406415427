import { memo } from 'react';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { cn } from '@repo/common/utils/component';

export interface Props extends LazyLoadImageProps {}

export const UiImageLazy = memo((props: Props) => {
  const { className, wrapperClassName, effect = 'blur', ...restProps } = props;
  return (
    <LazyLoadImage
      className={cn('ui-object-cover ui-w-full ui-h-full ui-basis-full ui-grow', className)}
      wrapperClassName={cn(
        '!ui-flex ui-flex-col ui-justify-center ui-items-center ui-w-full ui-h-full',
        wrapperClassName,
      )}
      effect={effect}
      {...restProps}
    />
  );
});
