import { ReactNode } from '@tanstack/react-router';

import { createSafeContext } from '@/shared/libs/context';

export interface RootLayoutContextValue {
  isOpenSidebar: boolean;

  isMobile: boolean;

  onOpenSidebar: () => void;

  onCloseSidebar: () => void;

  onToggleSidebar: () => void;
}

export interface RootLayoutProviderProps {
  children: ReactNode;
}

export const [RootLayoutContextProvider, useRootLayoutContext] =
  createSafeContext<RootLayoutContextValue>('RootLayoutContext');
