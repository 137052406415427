import { ReactNode, useMemo, useState } from 'react';

import { FeatureBookItems, FeaturesBookGetter, FeaturesBookNotifier, FeaturesBookStatus } from './types';

import { FeaturesBookContext } from './context';
import { useLatest } from '../../hooks/use-latest';
import { useIsomorphicEffect } from '../../hooks/use-isomorphic-effect';

const createProxyFeatures = (features: FeatureBookItems, notifier?: FeaturesBookNotifier) => {
  return new Proxy(features, {
    get(target, prop, receiver) {
      if (typeof prop === 'string') {
        notifier?.(prop, target[prop]);
      }
      return Reflect.get(target, prop, receiver);
    },
  });
};

export const FeaturesBookProvider = ({
  children,
  featuresGetter,
  readingNotifier,
}: {
  children: ReactNode;
  featuresGetter: FeaturesBookGetter;
  readingNotifier?: FeaturesBookNotifier;
}) => {
  const [status, setStatus] = useState<FeaturesBookStatus>('idle');
  const [features, setFeatures] = useState<FeatureBookItems>({});

  const invalidateFeatures = useLatest(async () => {
    try {
      setStatus('refreshing');
      const _features = await featuresGetter();
      setFeatures(createProxyFeatures(_features, readingNotifier));
      setStatus('success');
    } catch (error) {
      setStatus('error');
      return Promise.reject(error);
    }
  });

  const initFeatures = async () => {
    try {
      setStatus('loading');
      const _features = await featuresGetter();
      setFeatures(createProxyFeatures(_features, readingNotifier));
      setStatus('success');
    } catch (error) {
      setStatus('error');
      return Promise.reject(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      features,
      status,
      invalidate: invalidateFeatures.current,
    }),
    [features, status],
  );

  useIsomorphicEffect(() => {
    initFeatures();
  }, []);

  return <FeaturesBookContext.Provider value={contextValue}>{children}</FeaturesBookContext.Provider>;
};
