import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';

import { ReactNode } from '@tanstack/react-router';

import { createSafeContext } from '@/shared/libs/context';
import { Nullable } from '@/shared/types';

interface Value {}

interface Props {
  children: ReactNode;
}

const [_OnboardingFormProvider, useOnboardingFormContext] = createSafeContext<Value>('OnboardingFormContext');

export interface IOnboardingFormValue {
  selectedOption: { id?: string; option?: string }[];
  characterConfig: Record<string, number>;
  chatAbout: string[];
  partnerInterests: string[];
  buttonsValue: Record<string, string>;
  characterId: Nullable<number>;
}

const DEFAULT_VALUE: IOnboardingFormValue = {
  selectedOption: [],
  characterConfig: {},
  chatAbout: [],
  partnerInterests: [],
  buttonsValue: {},
  characterId: null,
};

const OnboardingFormProvider = (props: Props) => {
  const { children } = props;

  const form = useForm<IOnboardingFormValue>({ defaultValues: DEFAULT_VALUE });

  return (
    <_OnboardingFormProvider value={{}}>
      <FormProvider {...form}>{children}</FormProvider>
    </_OnboardingFormProvider>
  );
};

const useOnboardingForm = useFormContext<IOnboardingFormValue>;

const useOnboardingFormController = useController<IOnboardingFormValue>;

export { OnboardingFormProvider, useOnboardingForm, useOnboardingFormContext, useOnboardingFormController };
