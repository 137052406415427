import useEmblaCarousel from 'embla-carousel-react';

import { createSafeContext } from '@/shared/libs/context';

export type CarouselOptions = NonNullable<Parameters<typeof useEmblaCarousel>[0]>;
export type CarouselPlugins = NonNullable<Parameters<typeof useEmblaCarousel>[1]>;
export type CarouselApi = NonNullable<ReturnType<typeof useEmblaCarousel>[1]>;
export type CarouselRef = ReturnType<typeof useEmblaCarousel>[0];

export interface CarouselContextValue {
  options: CarouselOptions;
  carouselApi: CarouselApi | undefined;
  carouselRef: CarouselRef;
}

export const { 0: CarouselProvider, 1: useCarousel } = createSafeContext<CarouselContextValue>(
  'CarouselContext',
  'CarouselContext was not found in the tree',
);
