import type { ResetPasswordDto, SignInDto, SignUpDto, TokenResponse } from './models';
import { HTTP_DICTIONARY } from '@/core/constants';
import { httpClient } from '@/core/services/http';

import { CookiesDictionary } from '@/shared/constants';
import { extractCookieValue } from '@/shared/libs/utils';

const getToken = (
  dto: {
    sub: string;
    platform: string;
    version: string;
    web_to_app: boolean;
    client_token: string;
  },
  signal?: AbortSignal,
) => {
  const { client_token, ...restDto } = dto;

  return httpClient.post<TokenResponse>(HTTP_DICTIONARY.token, restDto, {
    signal,
    headers: {
      withAuth: false,
      Authorization: `Bearer ${client_token}`,
    },
  });
};

const signUp = (dto: SignUpDto) => {
  const redTrack = extractCookieValue(CookiesDictionary.RED_TRACK);
  return httpClient
    .post<TokenResponse>(HTTP_DICTIONARY.singUp, { ...dto, ...(redTrack && { rtkclickid_store: redTrack }) })
    .then((r) => r.data);
};

const signIn = (dto: SignInDto) => {
  const { client_token, ...restDto } = dto;

  return httpClient
    .post<TokenResponse>(HTTP_DICTIONARY.signIn, restDto, {
      headers: {
        withAuth: false,
        Authorization: `Bearer ${client_token}`,
      },
    })
    .then((r) => r.data);
};

const confirmEmail = (t: string) => {
  return httpClient.post<unknown>(HTTP_DICTIONARY.confirm + `?t=${t}`).then((r) => r.data);
};

const resetPassword = (dto: ResetPasswordDto) => {
  const { client_token, ...restDto } = dto;

  return httpClient
    .post<TokenResponse>(HTTP_DICTIONARY.resetPassword, restDto, {
      headers: {
        withAuth: false,
        Authorization: `Bearer ${client_token}`,
      },
    })
    .then((r) => r.data);
};

export const authRepository = {
  signUp,
  signIn,
  getToken,
  confirmEmail,
  resetPassword,
} as const;
