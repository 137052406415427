import { cn } from '@/shared/libs/utils';
import { Typography, UiSlider, type UiSliderProps } from '@/shared/ui';

export interface Props extends Partial<UiSliderProps> {
  minLabel?: string;
  maxLabel?: string;
  showThumbValue?: boolean;
}

export const PersonalitySlider = (props: Props) => {
  const { maxLabel, minLabel, max = 10, min = 0, step = 0.1, className, showThumbValue, ...restProps } = props;

  return (
    <div>
      <div className={cn('flex justify-between font-semibold pb-3', className)}>
        <Typography as="span" className="text-primary-font">
          {minLabel}
        </Typography>
        <Typography as="span" className="text-primary-font">
          {maxLabel}
        </Typography>
      </div>
      <div>
        <UiSlider min={min} max={max} step={step} {...restProps}>
          <UiSlider.Track className="h-1">
            <UiSlider.Range />
          </UiSlider.Track>
          <UiSlider.Thumb showValue={showThumbValue} />
        </UiSlider>
      </div>
    </div>
  );
};
