class StorageService<Schema extends Record<string, any>> {
  constructor(private readonly storage: Storage) {}

  public getItem<K extends keyof Schema>(key: K, initialValue: Schema[K] | (() => Schema[K])): Schema[K] {
    const data = this.storage.getItem(key as string);

    if (data === null) {
      return initialValue instanceof Function ? initialValue() : initialValue;
    }

    return JSON.parse(data);
  }

  public setItem<K extends keyof Schema>(key: K, value: Schema[K]): void {
    this.storage.setItem(key as string, JSON.stringify(value));
  }

  public removeItem<K extends keyof Schema>(key: K): void {
    this.storage.removeItem(key as string);
  }

  public clear(): void {
    this.storage.clear();
  }
}

export interface IStorage<Schema extends Record<string, any> = {}> extends StorageService<Schema> {}

export const createStorage = <Schema extends Record<string, any>>(storage: Storage): IStorage<Schema> => {
  return new StorageService(storage);
};
