import { z } from 'zod';

import { HEX, RGB } from '@/shared/constants';

const color = z.string().refine((v) => !HEX.test(v) || !RGB.test(v), { message: 'Invalid color' });
const radius = z.number();
const fontSize = z.array(z.number(), z.number());

export const colorGradientSchema = z.object({
  colors: z.record(color, color),
  angle: z.number(),
});

export const fontSizeSchema = z.object({
  '5xl': fontSize,
  '4xl': fontSize,
  '3xl': fontSize,
  '2xl': fontSize,
  xl: fontSize,
  base: fontSize,
  '2sm': fontSize,
  sm: fontSize,
  '2xs': fontSize,
  xs: fontSize,
});

export const colorsSchema = z.object({
  transparent: z.literal('transparent').optional(),
  primary_font: color,
  primary: color,
  primary_content: color,
  secondary: color,
  secondary_content: color,
  white: color,
  white_content: color,
  black: color,
  black_content: color,
  grey_100: color,
  grey_content_100: color,
  grey_200: color,
  grey_content_200: color,
  grey_300: color,
  grey_content_300: color,
  grey_400: color,
  grey_content_400: color,
  grey_500: color,
  grey_content_500: color,
  grey_600: color,
  grey_content_600: color,
  grey_700: color,
  grey_content_700: color,
  grey_800: color,
  grey_content_800: color,
  brand_default: color,
  brand_default_content: color,
  brand_primary: color,
  brand_primary_content: color,
  brand_primary_gradient: colorGradientSchema.or(z.string()).optional(),
  brand_primary_gradient_content: color,
  brand_secondary: color,
  brand_secondary_content: color,
  brand_secondary_gradient: colorGradientSchema.or(z.string()).optional(),
  brand_secondary_gradient_content: color,
  brand_bubble: color,
  brand_bubble_content: color,
  brand_light: color,
  brand_light_content: color,
  brand_green: color,
  brand_green_content: color,
  brand_red: color,
  brand_red_content: color,
  // accent_primary: color,
  // accent_secondary: color,
});

export const roundedSchema = z.object({
  sm: radius,
  md: radius,
  lg: radius,
  xl: radius,
  '2xl': radius,
  '3xl': radius,
  button_lg: radius,
  button_md: radius,
  button_sm: radius,
  input: radius,
  modal: radius,
  avatar: radius,
  avatar_xs: radius,
});

export const typographySchema = z.object({
  family: z.string().or(z.array(z.string())),
  default_size: z.number(),
  sizes: fontSizeSchema,
});

export const elementPropertyColorSchema = z.object({
  type: z.literal('colors'),
  default: colorsSchema.keyof(),
  active: colorsSchema.keyof().optional(),
});

export const elementPropertyTypographySchema = z.object({
  type: z.literal('typography'),
  default: typographySchema.keyof(),
  active: typographySchema.keyof().optional(),
});

export const elementPropertyRoundedSchema = z.object({
  type: z.literal('rounded'),
  default: roundedSchema.keyof(),
  active: roundedSchema.keyof().optional(),
});

export const elementPropertyGradientSchema = z.object({
  type: z.literal('gradient'),
  default: colorGradientSchema,
  active: colorGradientSchema.or(z.string()).optional(),
});

export const elementPropertySchema = elementPropertyColorSchema
  .or(elementPropertyRoundedSchema)
  .or(elementPropertyTypographySchema)
  .or(elementPropertyGradientSchema);

export const elementSchema = z.record(
  z.string(),
  z.record(z.string(), z.record(z.string(), elementPropertySchema.or(z.string()).or(z.number()))),
);

export const themeSchema = z.object({
  colors: colorsSchema,
  rounded: roundedSchema,
  typography: typographySchema,
  elements: elementSchema,
});

export type ThemeSchema = z.infer<typeof themeSchema>;

export type ColorsSchema = z.infer<typeof colorsSchema>;

export type RoundedSchema = z.infer<typeof roundedSchema>;

export type TypographySchema = z.infer<typeof typographySchema>;

export type FontSizeSchema = z.infer<typeof fontSizeSchema>;

export type ColorGradientSchemaSchema = z.infer<typeof colorGradientSchema>;

export type ElementPropertyColorSchema = z.infer<typeof elementPropertyColorSchema>;

export type ElementPropertyRoundedSchema = z.infer<typeof elementPropertyRoundedSchema>;

export type ElementPropertyTypographySchema = z.infer<typeof elementPropertyTypographySchema>;

export type ElementPropertySchema = z.infer<typeof elementPropertySchema>;

export type ElementSchema = z.infer<typeof elementSchema>;
