import { useEffect } from 'react';

import { DIALOG_KEYS } from '@/core/constants';

import { WelcomeOffer } from '@/features/modals';

import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { type CloseDialogArgs, Modal, type OpenDialogArgs, dialog } from '@/shared/ui';

export interface Props {
  onSuccessPayment?: () => void;

  analyticData?: any;
}

export const WelcomeOfferModal = (props: Props) => {
  const { analyticData, onSuccessPayment, ...restProps } = props;

  const handleCloseEvent = () => {
    if (analyticData) {
      Analytic.welcomeOfferSkipped();
    }
  };

  useEffect(() => {
    Analytic.welcomeOfferView();
  }, []);

  return (
    <Modal.Root
      onEventClose={handleCloseEvent}
      bodyClassName={cn('sm:h-[520px] sm:max-w-[740px] w-full h-full')}
      {...restProps}
    >
      <Modal.Content className="flex flex-col sm:flex-row h-full">
        <Modal.CloseBtn onEventClose={handleCloseEvent} className="absolute top-4 left-4" />
        <WelcomeOffer analyticData={analyticData} onSuccessPayment={onSuccessPayment} />
      </Modal.Content>
    </Modal.Root>
  );
};

const openWelcomeOfferModal = (props?: Props, other?: Omit<Partial<OpenDialogArgs>, 'component'>) => {
  dialog.open({
    key: DIALOG_KEYS.WELCOME_OFFER,
    component: <WelcomeOfferModal {...(props || {})} />,
    ...other,
  });
};

const closeWelcomeOfferModal = (other?: CloseDialogArgs) => {
  dialog.close({ key: DIALOG_KEYS.WELCOME_OFFER, ...other });
};

export const welcomeOfferModalAction = {
  open: openWelcomeOfferModal,
  close: closeWelcomeOfferModal,
} as const;
