export const isFile = (value: unknown): value is File => value instanceof File;

export const isBlob = (value: unknown): value is Blob => value instanceof Blob;

export const isFormData = (value: unknown): value is FormData => value instanceof FormData;

export const isFileList = (value: unknown): value is FileList => value instanceof FileList;

export const isMarkdownLink = (value: string) => {
  return value.includes('.md');
};
