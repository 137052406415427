import { StorageService } from '@/shared/services/storage';

type WelcomeOfferState = 'show' | 'hide' | null;

export enum WelcomeOfferStorageKey {
  WelcomeOfferState = 'welcome_offer_state',
}

interface IWelcomeOfferStorage {
  [WelcomeOfferStorageKey.WelcomeOfferState]: WelcomeOfferState;
}

export const welcomeOfferStorage = new StorageService<IWelcomeOfferStorage>(window.localStorage);

export const welcomeOfferStorageGetters = {
  getWelcomeOfferState: () => welcomeOfferStorage.getItem(WelcomeOfferStorageKey.WelcomeOfferState, null),
};

export const welcomeOfferStorageSetters = {
  setWelcomeOfferShowState: () => {
    welcomeOfferStorage.setItem(WelcomeOfferStorageKey.WelcomeOfferState, 'show');
  },
  setWelcomeOfferHideState: () => {
    welcomeOfferStorage.setItem(WelcomeOfferStorageKey.WelcomeOfferState, 'hide');
  },
};
