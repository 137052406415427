import { cva } from 'class-variance-authority';

import { SUBSCRIPTIONS_VIEW } from '@/entities/subscription';

export const priceRadioBtn = cva('group/btn relative rounded-[18px] transition-all border-grey-600', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]:
        'aria-[checked=true]:border-brand-primary aria-[checked=false]:hover:border-brand-primary/50 border-2 w-full  p-3 pl-4',
      [SUBSCRIPTIONS_VIEW.SQUARE]:
        'w-full px-3 py-4 aria-[checked=true]:border-brand-default aria-[checked=false]:hover:border-brand-default/50 border-2 relative before:bg-gradient-to-t before:absolute before:w-full before:h-1/2 ' +
        'before:from-brand-primary/20 before:bottom-0 before:left-0 before:-z-1 before:rounded-lg',
    },
    isActive: {
      true: 'border-brand-primary',
    },
    isBest: {
      true: '',
      false: '',
    },
  },

  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
    isActive: false,
    isBest: false,
  },
});

export const bestValueStyles = cva('absolute top-0 rounded-full -translate-y-1/2  px-3 py-0.5', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: 'right-4 bg-brand-primary !text-brand-primary-content',
      [SUBSCRIPTIONS_VIEW.SQUARE]:
        'bg-brand-default !text-brand-default-content transform left-1/2 -translate-x-1/2  w-max',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const priceLabelStyles = cva('flex flex-col', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: 'items-start',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'h-full w-full justify-between items-center text-center',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const discountBadgeStyles = cva('discount-subscription-badge px-2.5 py-0.5 rounded-sm', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: '',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'flex mb-3',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const priceStyles = cva('text-primary-font items-center', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: 'flex',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'text-[18px] w-full flex flex-wrap justify-center text-center grow',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const priceLabelItemStyles = cva('', {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: '',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'inline-block w-full border-b border-grey-600 pb-4 mb-3 tablet:text-sm',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});

export const radioBtnStyles = cva(``, {
  variants: {
    subscriptionView: {
      [SUBSCRIPTIONS_VIEW.DEFAULT]: 'border-2 rounded-full size-6 flex justify-center items-center',
      [SUBSCRIPTIONS_VIEW.SQUARE]: 'hidden',
    },
  },
  defaultVariants: {
    subscriptionView: SUBSCRIPTIONS_VIEW.DEFAULT,
  },
});
