// Function to replace placeholders with values from vars
import type { ReactNode } from 'react';

function replacePlaceholders(str: string, vars: Record<string, string>): string {
  return str.replace(/{{(.*?)}}/g, (_, key) => vars[key.trim()] ?? '');
}

// Function to process the string for custom tags and handle new lines
function processStr(str: string, intent: Record<string, (el: ReactNode) => ReactNode>): ReactNode {
  const tagRegex = /<(\w+)>(.*?)<\/\1>/g;
  const lineBreakRegex = /\n/g;

  let lastIndex = 0;
  const elements: ReactNode[] = [];

  let match;
  while ((match = tagRegex.exec(str)) !== null) {
    const [fullMatch, tagName, innerText] = match;

    // Push text before the current match
    if (lastIndex < match.index) {
      const textBeforeMatch = str.slice(lastIndex, match.index);
      // Handle new lines in the text before the match
      const textParts = textBeforeMatch.split(lineBreakRegex);
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      textParts.forEach((part, index) => {
        if (index > 0) elements.push(<br key={`br-${lastIndex}-${index}`} />);
        elements.push(part);
      });
    }

    // Replace the tag with the corresponding component from intent
    if (intent[tagName]) {
      elements.push(intent[tagName](processStr(innerText, intent)));
    } else {
      elements.push(fullMatch);
    }

    lastIndex = tagRegex.lastIndex;
  }

  // Push remaining text after the last match
  if (lastIndex < str.length) {
    const remainingText = str.slice(lastIndex);
    // Handle new lines in the remaining text
    const textParts = remainingText.split(lineBreakRegex);
    textParts.forEach((part, index) => {
      if (index > 0) elements.push(<br key={`br-${lastIndex}-${index}`} />);
      elements.push(part);
    });
  }

  return <>{elements}</>;
}

export function renderFromTemplate(
  template: {
    vars: Record<string, string>;
    value: string;
  },
  intent: Record<string, (el: ReactNode) => ReactNode>,
): ReactNode {
  const replacedString = replacePlaceholders(template.value, template.vars);
  return processStr(replacedString, intent);
}
