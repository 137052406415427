import type { ReactNode } from 'react';
import type { CSSProperties } from 'react';
import { CHARACTER_STATUS } from '@repo/api/chat';
import { UiAnimatedIcon } from '@repo/ui-kit/ui-icon';
import { UiTypography } from '@repo/ui-kit/ui-typography';

export interface Props {
  status: CHARACTER_STATUS;

  style?: CSSProperties;
}

const CharacterStatusMap = {
  [CHARACTER_STATUS.OFFLINE]: {
    text: 'online',
  },
  [CHARACTER_STATUS.ONLINE]: {
    text: 'online',
  },
  [CHARACTER_STATUS.TYPING]: {
    icon: <UiAnimatedIcon k="typing-secondary" className="mod-text-[--color-icon] text-3xl" />,
    text: 'typing',
  },
  [CHARACTER_STATUS.SENDING_PHOTO]: {
    icon: <UiAnimatedIcon k="spinner" className="mod-text-[--color-icon] text-3xl" />,
    text: 'photo...',
  },
  [CHARACTER_STATUS.RECORDING_VOICE]: {
    icon: <UiAnimatedIcon k="recording" className="mod-text-[--color-icon] text-3xl" />,
    text: 'voice...',
  },
  [CHARACTER_STATUS.RECORDING_VIDEO]: {
    icon: <UiAnimatedIcon k="recording" className="mod-text-[--color-icon] text-3xl" />,
    text: 'video...',
  },
} as Record<CHARACTER_STATUS, { icon?: ReactNode; text: string }>;

export const CharacterStatus = (props: Props) => {
  const { status, style } = props;

  const Icon = CharacterStatusMap?.[status]?.icon;
  const text = CharacterStatusMap?.[status]?.text;

  const innerStyle = {
    '--color-icon': 'var(--ui-color-brand-secondary)',
    '--color-text': '#fff',
    ...style,
  };

  return (
    <UiTypography style={innerStyle} as="span" className="module-character-status mod-inline-flex mod-items-center">
      <span className="mod-h-5 mod-inline-block">{Icon}</span>
      <UiTypography as="span" variant="sm" className="mod-inline-block mod-pl-1 mod-text-[--color-text]">
        {text}
      </UiTypography>
    </UiTypography>
  );

  // return <StatusAnimation animationData={data} text={text} loop />;
};
