import { GoogleSignUpDTO } from '@repo/api/auth';
import { useGoogleSignUpMutation } from '../../../../entities/auth/queries';

export const useGoogleSignUp = () => {
  const googleSignUp = useGoogleSignUpMutation();

  const onGoogleSignUp = async (dto: GoogleSignUpDTO) => {
    return await googleSignUp.mutateAsync(dto);
  };

  return { onGoogleSignUp, isPending: googleSignUp.isPending };
};
