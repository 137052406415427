import { SubscriptionTier } from '@/core/constants';

import { BuySubscription } from '@/features/modals/components/buy-subscription';

import { useBrandFeatures } from '@/entities/brand';

import { cn } from '@/shared/libs/utils';
import { Analytic } from '@/shared/services/analytic';
import { Modal, ModalRootProps } from '@/shared/ui';

export interface Props extends ModalRootProps {
  imgSrc?: string;

  characterPicture?: string;

  subscriptionVariant?: SubscriptionTier;

  analyticData?: any;

  onSuccessPayment?: () => void;
}

export const BuySubscriptionModal = (props: Props) => {
  const {
    subscriptionVariant,
    bodyClassName,
    analyticData,
    characterPicture,
    onEventClose,
    onSuccessPayment,
    imgSrc,
    ...restProps
  } = props;

  const features = useBrandFeatures();

  const handleCloseEvent = () => {
    onEventClose?.();
    if (analyticData) {
      Analytic.paywallSkipClick(analyticData);
    }
  };

  return (
    <Modal.Root
      onEventClose={handleCloseEvent}
      bodyClassName={cn(
        'sm:h-[454px] sm:max-w-[740px] w-full h-full',
        features?.discount_banner && 'sm:h-[506px]',
        bodyClassName,
      )}
      {...restProps}
    >
      <Modal.Content className="flex flex-col sm:flex-row h-full">
        <Modal.CloseBtn onEventClose={handleCloseEvent} className="absolute top-4 left-4" />
        <BuySubscription
          characterPicture={characterPicture}
          subscriptionVariant={subscriptionVariant}
          analyticData={analyticData}
          onSuccessPayment={onSuccessPayment}
          imgSrc={imgSrc}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
