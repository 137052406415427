export enum CharacterStatus {
  UNPAID = 1,
  OFFLINE = 2,
  ONLINE = 3,
  TYPING = 4,
  RECORDING_VOICE = 5,
  SENDING_PHOTO = 6,
  RECORDING_VIDEO = 7,
}

export enum MessageContentType {
  TEXT = 1,
  VOICE = 2,
  PICTURE = 3,
}

export enum MessageType {
  REGULAR = 'regular',
  SUGGESTED = 'suggested',
  PICTURE = 'picture',
}

export enum MessageSenderType {
  OUTGOING = 1,
  INCOMING = 2,
}

export enum SubscriptionTier {
  PRO = 'PRO_MONTHLY,PRO_ANNUAL,PRO_QUARTERLY,PRO_WEEKLY',
  PRO_PLUS = 'PRO_PLUS_MONTHLY,PRO_PLUS_ANNUAL,PRO_PLUS_QUARTERLY,PRO_PLUS_WEEKLY',
}

export enum SubscriptionType {
  PRO_MONTHLY = 'PRO_MONTHLY',
  PRO_ANNUAL = 'PRO_ANNUAL',
  PRO_QUARTERLY = 'PRO_QUARTERLY',
  PRO_WEEKLY = 'PRO_WEEKLY',
  PRO_PLUS_MONTHLY = 'PRO_PLUS_MONTHLY',
  PRO_PLUS_ANNUAL = 'PRO_PLUS_ANNUAL',
  PRO_PLUS_QUARTERLY = 'PRO_PLUS_QUARTERLY',
  PRO_PLUS_WEEKLY = 'PRO_PLUS_WEEKLY',
}

export enum SubscriptionLabel {
  PRO_MONTHLY = 'Premium',
  PRO_ANNUAL = 'Premium',
  PRO_QUARTERLY = 'Premium',
  PRO_WEEKLY = 'Premium',
  PRO_PLUS_MONTHLY = 'VIP',
  PRO_PLUS_ANNUAL = 'VIP',
  PRO_PLUS_QUARTERLY = 'VIP',
  PRO_PLUS_WEEKLY = 'VIP',
}

export enum SubscriptionPlan {
  BASIC = 1,
  PRO = 2,
  PRO_PLUS = 3,
}

export enum SubscriptionInterval {
  MONTH = 'month',
  YEAR = 'year',
  QUARTER = 'quarter',
  WEEK = 'week',
}

export enum SocketStatusMessage {
  CONNECTED = 'connected',
  TOKE_REQUIRED = 'token required',
  AUTHENTICATED = 'authenticated',
}
