import { InputHTMLAttributes, type ReactNode, forwardRef, useMemo } from 'react';

import { FieldContainer } from '../field-container';
import './styles.scss';
import { VariantProps, cva } from 'class-variance-authority';

import { cn, randomUuid } from '@/shared/libs/utils';

const input = cva('', {
  variants: {
    rounded: {
      default: 'rounded-input',
      sm: 'rounded-sm',
      md: 'rounded-md',
      lg: 'rounded-lg',
    },
  },

  defaultVariants: {
    rounded: 'default',
  },
});

export interface Props extends InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof input> {
  label?: string;
  error?: string;

  chevron?: ReactNode;
}

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, chevron, rounded = 'default', label, error, id, name, ...restProps } = props;

  const _id = useMemo(() => {
    if (id) {
      return id;
    }
    if (name) {
      return name;
    }
    return randomUuid();
  }, [id, name]);

  return (
    <FieldContainer label={label} error={error} htmlFor={_id}>
      <div className="relative">
        <input
          ref={ref}
          name={name}
          id={_id}
          className={cn(
            'field-input w-full p-3  transition-all',
            'disabled:opacity-60 focus-visible:ring-offset-1 focus-visible:ring-1 focus-visible:ring-offset-brand-default focus-visible:ring-brand-default focus-visible:!outline-none',
            input({ rounded }),
            {
              'border-brand-red focus-visible:ring-offset-brand-red focus-visible:ring-brand-red': Boolean(error),
              'pr-11': Boolean(chevron),
            },
            className,
          )}
          {...restProps}
        />

        {chevron && (
          <span className="absolute right-0 top-0 translate-y-[12px] -translate-x-[16px] text-[24px] size-6">
            {chevron}
          </span>
        )}
      </div>
    </FieldContainer>
  );
});
