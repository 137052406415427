import { useForm } from 'react-hook-form';

import { HttpError } from '@/core/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useSignInMutation } from '@/entities/auth';
import { useBrandConfig } from '@/entities/brand';

import { Analytic } from '@/shared/services/analytic';

export const signInSchema = z.object({
  email: z
    .string({ required_error: 'The specified email address is invalid' })
    .email({ message: 'The specified email address is invalid' }),
  password: z
    .string({ required_error: 'Password must not be empty' })
    .min(8, { message: 'The value must be 8 or more characters' }),
});

export type SignInSchema = z.infer<typeof signInSchema>;

const defaultValues: Partial<SignInSchema> = {
  email: '',
  password: '',
};

export interface SignUpFormControllerParams {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  analyticData?: any;
  onClickForgotPassword?: () => void;
}

export const useSignInFormController = (params?: SignUpFormControllerParams) => {
  const { onSuccess, onError, analyticData } = params || {};

  const { mutateAsync: signIn, isPending } = useSignInMutation();

  const brandConfig = useBrandConfig();

  const {
    control,
    formState: { errors, isSubmitting },
    setError,
    handleSubmit,
    reset,
  } = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
    defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    Analytic.loginClick(analyticData);

    try {
      await signIn({ ...data, client_token: brandConfig?.constants?.api_token as string });

      onSuccess?.();
      reset();
    } catch (error) {
      if (HttpError.is(error)) {
        setError('email', { message: error.message });
      }
      onError?.(error);
      Analytic.loginError(error);
      return Promise.reject(error);
    }
  });

  const isPendingSignIn = isPending || isSubmitting;

  return {
    control,
    errors,
    isPendingSignIn,
    onSubmit,
  };
};
