import { ComponentPropsWithoutRef, memo } from 'react';

import { UiButton, type UiButtonProps } from '@repo/ui-kit/ui-button';
import { UiIcon } from '@repo/ui-kit/ui-icon';

import { OnboardingStageVariant } from '@/entities/onboarding';
import { useOnboardingContext } from '@/entities/onboarding';

import { cn } from '@/shared/libs/utils';
import { Typography } from '@/shared/ui';
import { Progress } from '@/shared/ui/progress';

export interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  currentStep: number;

  stepVariant: OnboardingStageVariant;

  totalSteps: number;

  hiddenPrevBtn?: boolean;

  btnProps?: UiButtonProps;

  onClickPrev?: () => void;

  onSkipClick?: () => void;
}

const allowSkipStep = [
  OnboardingStageVariant.CompleteCreating,
  OnboardingStageVariant.Preparing,
  OnboardingStageVariant.ChoosePartner,
];

export const OnboardingStepHeader = memo((props: Props) => {
  const {
    currentStep,
    totalSteps,
    hiddenPrevBtn,
    className,
    stepVariant,
    onClickPrev,
    onSkipClick,
    btnProps,
    ...restProps
  } = props;

  const { onboardingFeatures } = useOnboardingContext();

  const progressValue = (currentStep / totalSteps) * 100;

  return (
    <div
      className={cn(
        'grid gap-5  px-5 pt-3 grid-cols-[40px_auto]',
        {
          'grid-cols-[40px_auto]': onboardingFeatures.canSkipStep,
        },
        className,
      )}
      {...restProps}
    >
      <div className="h-[40px]">
        {!hiddenPrevBtn && (
          <UiButton onClick={onClickPrev} variant="outline" size="icon" {...btnProps}>
            <UiIcon k="arrow" className="text-[24px]" />
          </UiButton>
        )}
      </div>
      <div className="flex justify-between items-center">
        <Progress value={progressValue} />
        <Typography as="span" weight="medium" className="opacity-60 w-[40px] text-center text-primary-font">
          {currentStep}/{totalSteps}
        </Typography>
        {onboardingFeatures.canSkipStep && !allowSkipStep.includes(stepVariant) && (
          <div className="pl-1">
            <UiButton onClick={onSkipClick} variant="outline" size="icon" className="transform rotate-180">
              <UiIcon k="arrow" className="text-[24px]" />
            </UiButton>
          </div>
        )}
      </div>
    </div>
  );
});
