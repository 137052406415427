export enum AGE_RANGE {
  MIN = 18,
  MAX = 80,
}

export enum PAYMENT_STATUS {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
