import { ReactNode, useCallback, useMemo, useState } from 'react';

import { Character } from '../../../character';
import { OnboardingStageVariant } from '../types';
import { OnboardingCreateStageResult } from '../utils';
import { LocalStorageKey, localStorageService } from '@/core/services';
import type { OnboardingFeaturesFlag } from '@repo/common/services/features-book';
import { useOnboardingFeaturesFlag } from '@repo/common/services/features-book';

import { createSafeContext } from '@/shared/libs/context';
import { Analytic } from '@/shared/services/analytic';
import { Nullable } from '@/shared/types';

interface OnboardingContextValue {
  currentStep: number;

  stage: Nullable<OnboardingCreateStageResult>;

  availableNextStep: boolean;

  availablePrevStep: boolean;

  totalSteps: number;

  selectedCharacter: Nullable<Character>;

  selectCharacter: (character: Character) => void;

  nextStep: () => void;

  skipStep: () => void;

  prevStep: () => void;

  complete: () => void;

  onboardingFeatures: OnboardingFeaturesFlag;
}

interface Props {
  children: ReactNode;

  stages: OnboardingCreateStageResult[];
}

const EXCLUDED_STEP_VARIANTS = [OnboardingStageVariant.CompleteCreating, OnboardingStageVariant.Preparing];

const { 0: _OnboardingProvider, 1: useOnboardingContext } =
  createSafeContext<OnboardingContextValue>('OnboardingContext');

const OnboardingProvider = (props: Props) => {
  const { children, stages } = props;

  const onboardingFeatures = useOnboardingFeaturesFlag();

  const { 0: currentStep, 1: setCurrentStep } = useState(() => {
    const initial = 0;
    Analytic.onboardingScreen(initial + 1);
    return initial;
  });

  const { 0: selectedCharacter, 1: setSelectedCharacter } = useState<Nullable<Character>>(null);

  const selectCharacter = useCallback((character: Character) => {
    setSelectedCharacter(character);
  }, []);

  const nextStep = useCallback(() => {
    setCurrentStep((prev) => {
      if (prev === stages.length) {
        return prev;
      }
      Analytic.onboardingScreen(prev + 2);
      return prev + 1;
    });
  }, [stages]);

  const skipStep = useCallback(() => {
    setCurrentStep((prev) => {
      if (prev === stages.length) {
        return prev;
      }
      return prev + 1;
    });
  }, []);

  const prevStep = useCallback(() => {
    setCurrentStep((prev) => {
      if (prev === stages.length) {
        return prev;
      }
      Analytic.onboardingScreen(prev);
      return prev - 1;
    });
  }, [stages]);

  const complete = useCallback(() => {
    localStorageService.setItem(LocalStorageKey.IsPassOnboarding, true);
  }, [stages]);

  const availableNextStep = useMemo(() => currentStep < stages.length - 2, [currentStep, stages]);

  const availablePrevStep = useMemo(() => currentStep >= 2, [currentStep]);

  const totalSteps = useMemo(
    () => stages.filter((stage) => !EXCLUDED_STEP_VARIANTS.includes(stage.variant)).length,
    [stages],
  );

  const value: OnboardingContextValue = {
    currentStep,
    totalSteps,
    selectedCharacter,
    availableNextStep,
    availablePrevStep,
    selectCharacter,
    skipStep,
    nextStep,
    prevStep,
    complete,
    stage: stages.find((stage) => stage.stepNumber === currentStep) ?? null,
    onboardingFeatures: onboardingFeatures(),
  };

  return <_OnboardingProvider value={value}>{children}</_OnboardingProvider>;
};

export { OnboardingProvider, useOnboardingContext };
