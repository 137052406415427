import { AxiosError } from 'axios';

export class ApiError extends Error {
  constructor(
    public message: string,
    public code?: string,
    public status?: number,
    public url?: string,
    public stack?: string,
  ) {
    super(message);

    this.name = this.constructor.name;

    if (stack) {
      this.stack = stack;
    } else {
      this.stack = new Error().stack;
    }
  }

  static create = (err: unknown) => {
    if (this.isAxiosError(err)) {
      let msg = 'Unknown error';

      const res = err.response?.data;

      if (typeof res === 'object' && res !== null) {
        if ('detail' in res && typeof res.detail === 'string') {
          msg = res.detail;
        } else if ('error' in res && typeof res.error === 'string') {
          msg = res.error;
        }
      }

      return new ApiError(msg, err.code, err.response?.status, err.response?.config?.url, err.stack);
    }

    if (this.isNativeError(err)) {
      return new ApiError(err.message, '', 400, '', err.stack);
    }
  };

  static is = (err: unknown): err is ApiError => {
    return err instanceof ApiError;
  };

  private static isNativeError = (err: unknown): err is Error => {
    return err instanceof Error;
  };

  private static isAxiosError = (err: unknown): err is AxiosError => {
    return err instanceof AxiosError;
  };

  public toJson = () => {
    return JSON.stringify({
      message: this.message,
      code: this.code,
      status: this.status,
      url: this.url,
      stack: this.stack,
    });
  };
}
