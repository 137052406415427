import { useCallback } from 'react';

import { RECURRING_PRICE_RECALCULATION, SUBSCRIPTION_PERIOD } from '@/entities/subscription';

export const useSubscriptionPriceRecalculation = (period: SUBSCRIPTION_PERIOD, recalculationType: string) => {
  return useCallback(
    (currentPrice: number) => {
      switch (recalculationType) {
        case RECURRING_PRICE_RECALCULATION.DAY:
          if (period === SUBSCRIPTION_PERIOD.WEEKLY) {
            return currentPrice / 7;
          } else if (period === SUBSCRIPTION_PERIOD.QUARTERLY) {
            return currentPrice / 90;
          } else if (period === SUBSCRIPTION_PERIOD.MONTHLY) {
            return currentPrice / 30;
          } else if (period === SUBSCRIPTION_PERIOD.ANNUAL) {
            return currentPrice / 365;
          }
          break;

        case RECURRING_PRICE_RECALCULATION.WEEK:
          if (period === SUBSCRIPTION_PERIOD.QUARTERLY) {
            return currentPrice / 12;
          } else if (period === SUBSCRIPTION_PERIOD.MONTHLY) {
            return currentPrice / 4;
          } else if (period === SUBSCRIPTION_PERIOD.ANNUAL) {
            return currentPrice / 48;
          }
          break;

        case RECURRING_PRICE_RECALCULATION.MONTH:
          if (period === SUBSCRIPTION_PERIOD.QUARTERLY) {
            return currentPrice / 3;
          } else if (period === SUBSCRIPTION_PERIOD.ANNUAL) {
            return currentPrice / 12;
          }
          break;

        case RECURRING_PRICE_RECALCULATION.NONE:
        default:
          return currentPrice;
      }

      return currentPrice;
    },
    [period, recalculationType],
  );
};
