import { ComponentProps, memo } from 'react';

import './styles.scss';

import { cn } from '@/shared/libs/utils';

export interface Props extends ComponentProps<'svg'> {}

export const OnboardingBackground = memo((props: Props) => {
  const { className, ...restProps } = props;
  return (
    <svg
      viewBox="0 0 100 100"
      className={cn('onboarding-background', 'h-full w-full absolute inset-0 opacity-80 hidden md:block', className)}
      preserveAspectRatio="xMidYMid slice"
      {...restProps}
    >
      <defs>
        <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5">
          <animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate>
          <stop className="gradient-stop-1-1" offset="0%"></stop>
          <stop className="gradient-stop-1-2" offset="100%"></stop>
        </radialGradient>
        <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5">
          <animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate>
          <stop className="gradient-stop-2-1" offset="0%"></stop>
          <stop className="gradient-stop-2-2" offset="100%"></stop>
        </radialGradient>
        <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5">
          <animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate>
          <stop className="gradient-stop-3-1" offset="0%"></stop>
          <stop className="gradient-stop-3-2" offset="100%"></stop>
        </radialGradient>
      </defs>
      <rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)">
        <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate>
        <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="48s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
      <rect
        x="-2.17916%"
        y="35.4267%"
        width="100%"
        height="100%"
        fill="url(#Gradient2)"
        transform="rotate(255.072 50 50)"
      >
        <animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate>
        <animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          dur="48s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
      <rect
        x="9.00483%"
        y="14.5733%"
        width="100%"
        height="100%"
        fill="url(#Gradient3)"
        transform="rotate(139.903 50 50)"
      >
        <animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate>
        <animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate>
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="360 50 50"
          to="0 50 50"
          dur="36s"
          repeatCount="indefinite"
        ></animateTransform>
      </rect>
    </svg>
  );
});

OnboardingBackground.displayName = 'OnboardingBackground';
