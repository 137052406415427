import { useCallback, useState } from 'react';

export const useDisclosure = (initialValue = false) => {
  const { 0: isOpen, 1: setIsOpen } = useState(initialValue);

  const onOpen = useCallback(() => setIsOpen(true), []);

  const onClose = useCallback(() => setIsOpen(false), []);

  const onToggle = useCallback(() => setIsOpen((open) => !open), []);

  return [isOpen, { onOpen, onClose, onToggle }] as const;
};

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
