import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useGetMeQuery } from '@repo/modules/entity/user-queries';
import { z } from 'zod';

export const constantPaymentSchema = z.object({
  email: z
    .string({ required_error: 'The email field is required' })
    .email({ message: 'The specified email address is invalid' }),
  account: z
    .string({ required_error: 'The account field is required' })
    .regex(/^[\w.-]+@[\w-]+$/, { message: 'Wrong Virtual Payment Address format' })
    .transform((value) => value.replace(/\s/g, ''))
    .refine((value) => value.length > 0, {
      message: 'Account should be filled',
    }),
  firstName: z
    .string({ required_error: 'The first name field is required' })
    .min(1, { message: 'The first name is required' }),
  lastName: z
    .string({ required_error: 'The last name field is required' })
    .min(1, { message: 'The second name is required' }),
});

export type ConstantPaymentSchema = z.infer<typeof constantPaymentSchema>;

export interface ConstantPaymentFormControllerParams {
  onSubmitPayment?: (data: ConstantPaymentSchema) => Promise<void> | void;

  onSuccessPayment?: () => void;

  onFailedPayment?: (err?: string | unknown) => Promise<void> | void;

  email?: string;
}

export const useConstantPaymentFormController = (params?: ConstantPaymentFormControllerParams) => {
  const { onSubmitPayment, onSuccessPayment, onFailedPayment } = params || {};
  const [isPending, setIsPending] = useState(false);
  const me = useGetMeQuery();
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm<ConstantPaymentSchema>({
    resolver: zodResolver(constantPaymentSchema),
    defaultValues: {
      email: me?.data?.email ?? '',
      firstName: '',
      lastName: '',
      account: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsPending(true);
    try {
      await onSubmitPayment?.(data);
      onSuccessPayment?.();
      setIsPending(false);
      reset();
    } catch (error) {
      setIsPending(false);
      await onFailedPayment?.(error);
      return Promise.reject(error);
    }
  });

  const setEmailValue = (email: string) => {
    setValue('email', email);
  };

  return {
    control,
    errors,
    onSubmit,
    isPending,
    setEmailValue,
  };
};
