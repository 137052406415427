import { ButtonHTMLAttributes } from 'react';

import './styles.css';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/shared/libs/utils';

const button = cva(
  `
  relative
  inline-flex items-center justify-center text-center overflow-hidden
  transition-all duration-300
  font-semibold
  disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none
  px-6
  `,
  {
    variants: {
      variant: {
        primary: 'button-primary',
        secondary: 'button-secondary',
        tertiary: 'button-tertiary',
        outline: 'button-outline',
        ghost: 'button-ghost',
        light: 'button-light',
        black: 'button-black',
        success: 'bg-brand-green text-primary-font',
      },

      w: {
        full: 'w-full',
        auto: 'w-auto',
      },

      size: {
        xs: 'min-h-7',
        sm: 'min-h-9',
        md: 'min-h-11 px-7',
        lg: 'min-h-14 px-8',
        xl: 'min-h-20 px-8',
        icon: 'w-11 h-11 px-3',
        'icon-sm': 'w-8 h-8 py-1.5 ',
      },

      rounded: {
        xs: 'rounded-button-xs',
        sm: 'rounded-button-sm',
        md: 'rounded-button-md',
        lg: 'rounded-button-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      rounded: 'md',
      w: 'full',
    },
  },
);

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {
  asChild?: boolean;
  disableAnimation?: boolean;
}

export const Button = (props: Props) => {
  const {
    className,
    asChild = false,
    variant,
    size,
    children,
    rounded,
    w,
    disableAnimation = true,
    ...restProps
  } = props;

  const El = asChild ? Slot : 'button';

  return (
    <El
      data-disabled-animation={disableAnimation}
      className={cn(
        button({ variant, size, rounded, w }),
        'group font-semibold text-base md:text-xl',
        {
          'before:invisible': disableAnimation,
        },
        className,
      )}
      {...restProps}
    >
      {children}
    </El>
  );
};
