import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Viewport } from '@radix-ui/react-scroll-area';
import { cn } from '@repo/common/utils/component';

export interface Props extends ComponentPropsWithoutRef<typeof Viewport> {}

export const UiScrollAreaViewport = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, ...restProps } = props;
  return (
    <Viewport
      ref={ref}
      className={cn('ui-scroll-area-view-port ui-relative ui-rounded-[inherit] ui-size-full', className)}
      {...restProps}
    />
  );
});
