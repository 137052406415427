import {useMediaQuery} from './use-media-query';

const SCREEN_NAMES = {
  smallPhone: 'smallPhone',
  phone: 'phone',
  tablet: 'tablet',
  laptop: 'laptop',
  desktop: 'desktop',
} as const;

const breakpoints = {
  [SCREEN_NAMES.smallPhone]: '(max-width: 399px)',
  [SCREEN_NAMES.phone]: '(max-width: 768px)',
  [SCREEN_NAMES.tablet]: '(max-width: 1024px)',
  [SCREEN_NAMES.laptop]: '(max-width: 1279px)',
  [SCREEN_NAMES.desktop]: '(min-width: 1280px)',
};

const priority = [
  SCREEN_NAMES.smallPhone,
  SCREEN_NAMES.phone,
  SCREEN_NAMES.tablet,
  SCREEN_NAMES.laptop,
  SCREEN_NAMES.desktop,
] as const;

export const useIsSmallPhone = () => useMediaQuery(breakpoints.smallPhone);
export const useIsPhone = () => useMediaQuery(breakpoints.phone);
export const useIsTablet = () => useMediaQuery(breakpoints.tablet);
export const useIsLaptop = () => useMediaQuery(breakpoints.laptop);
export const useIsDesktop = () => useMediaQuery(breakpoints.desktop);

export const useBreakpoints = () => {
  const isSmallPhone = useMediaQuery(breakpoints.smallPhone);
  const isPhone = useMediaQuery(breakpoints.phone) || isSmallPhone;
  const isTablet = useMediaQuery(breakpoints.tablet) || isPhone;
  const isLaptop = useMediaQuery(breakpoints.laptop) || isTablet;
  const isDesktop = useMediaQuery(breakpoints.desktop) || isLaptop;

  return {
    smallPhone: isSmallPhone,
    phone: isPhone,
    tablet: isTablet,
    laptop: isLaptop,
    desktop: isDesktop,
  };
};

export type Breakpoints = keyof ReturnType<typeof useBreakpoints>;
export type ValueByMedia<T> =
  | {
  [_Key in Breakpoints]?: T;
}
  | T;

export function useValueByMedia<T, Default extends T>(
  value: ValueByMedia<T>,
  defaultValue: ValueByMedia<Default>,
): Default;
export function useValueByMedia<T, Default extends T>(value: ValueByMedia<T>, defaultValue: Default): Default;
export function useValueByMedia<T>(value: ValueByMedia<T>): T | undefined;

export function useValueByMedia<T, Default extends T>(
  value: ValueByMedia<T>,
  defaultValue?: ValueByMedia<Default> | Default,
): T | Default | undefined {
  const breakpoints = useBreakpoints();
  const isValueByMedia = (val: unknown): val is { [_key in Breakpoints]?: T } => {
    if (typeof val !== 'object' || val === null || Array.isArray(val)) {
      return false;
    }
    return Object.keys(val).some((key) => priority.includes(key as Breakpoints));
  };

  const getValueFromMedia = (mediaValue: ValueByMedia<T>): T | undefined => {
    if (!isValueByMedia(mediaValue)) {
      return mediaValue as T;
    }

    for (let i = 0; i < priority.length; i++) {
      const breakpoint = priority[i];
      if (breakpoints[breakpoint] && mediaValue[breakpoint] !== undefined) {
        return mediaValue[breakpoint];
      }
    }

    return undefined;
  };

  const resolvedValue = getValueFromMedia(value);
  if (resolvedValue !== undefined) {
    return resolvedValue as T;
  }

  if (defaultValue !== undefined) {
    const resolvedDefaultValue = getValueFromMedia(defaultValue as ValueByMedia<Default>);
    return resolvedDefaultValue as Default;
  }

  return undefined;
}
