import { getClient } from '../../api/api-client';
import { characterEndpoints, chatEndpoints, messageEndpoints, systemEndpoints } from '../../api/api-dictionary';
import { ApiEndpoint } from '../../types/utility';
import { dataExtractor } from '../../utils/data-extractor';
import type { VoiceMessageResponse } from './entities';
import type { VoiceMessageParams } from './entities';
import type { VoiceMessageDTO } from './entities';
import {
  CharacterEntity,
  CharacterOverrideEntity,
  ChatEntity,
  CreateChatDTO,
  CreateChatResponse,
  EditMessageDTO,
  EditMessageParams,
  GetMessagesParams,
  MessageEntity,
  MessageFeedbackDTO,
  ResetCharacterInfoParams,
  SendMessageDTO,
  SendMessageFeedbackParams,
  SendMessageParams,
  UpdateCharacterInfoDTO,
  UpdateCharacterInfoParams,
  UploadCharacterPictureDTO,
  UploadCharacterPictureResponse,
} from './entities';

const getCharacters: ApiEndpoint<undefined, undefined, CharacterEntity[]> = (config) => {
  return getClient()
    .get(characterEndpoints.getCharacters(), { ...config })
    .then(dataExtractor);
};

const updateCharacterInfo: ApiEndpoint<UpdateCharacterInfoDTO, UpdateCharacterInfoParams, CharacterOverrideEntity> = (
  { character, ...dto },
  params,
  config,
) => {
  return getClient()
    .post(chatEndpoints.updateCharacterInfo(params), { ...character, ...dto }, config)
    .then(dataExtractor);
};

const resetCharacterInfo: ApiEndpoint<undefined, ResetCharacterInfoParams, CharacterOverrideEntity> = (
  params,
  config,
) => {
  return getClient().post(chatEndpoints.resetCharacterInfo(params), {}, config).then(dataExtractor);
};

const uploadCharacterPicture: ApiEndpoint<UploadCharacterPictureDTO, undefined, UploadCharacterPictureResponse> = (
  dto,
  config,
) => {
  const formData = new FormData();
  formData.append('file', dto.file);

  const updatedConfig = {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  return getClient().post(systemEndpoints.uploadPhoto(), formData, updatedConfig).then(dataExtractor);
};

const getChats: ApiEndpoint<undefined, undefined, ChatEntity[]> = (config) => {
  return getClient().get(chatEndpoints.chats(), config).then(dataExtractor);
};

const createChat: ApiEndpoint<CreateChatDTO, undefined, CreateChatResponse> = (dto, config) => {
  return getClient().post(chatEndpoints.chats(), dto, config).then(dataExtractor);
};

const getChatMessages: ApiEndpoint<undefined, GetMessagesParams, MessageEntity[]> = (params, config) => {
  return getClient().get(chatEndpoints.getMessages(params), config).then(dataExtractor);
};

const sendMessage: ApiEndpoint<SendMessageDTO, SendMessageParams, MessageEntity> = (dto, params, config) => {
  return getClient().post(chatEndpoints.sendMessage(params), dto, config).then(dataExtractor);
};

const sendRandomMessage: ApiEndpoint<undefined, SendMessageParams, MessageEntity> = (params, config) => {
  return getClient().post(chatEndpoints.sendRandomMessage(params), {}, config).then(dataExtractor);
};

const editMessage: ApiEndpoint<EditMessageDTO, EditMessageParams, MessageEntity> = (dto, params, config) => {
  return getClient().post(messageEndpoints.editMessage(params), dto, config).then(dataExtractor);
};

const sendMessageFeedback: ApiEndpoint<MessageFeedbackDTO, SendMessageFeedbackParams, unknown> = (
  dto,
  params,
  config,
) => {
  return getClient().post(messageEndpoints.sendMessageFeedback(params), dto, config).then(dataExtractor);
};

const voiceMessage: ApiEndpoint<VoiceMessageDTO, VoiceMessageParams, VoiceMessageResponse> = (dto, params, config) => {
  return getClient().post(chatEndpoints.voiceMessage(params), dto, config).then(dataExtractor);
};

export const Repository = {
  getCharacters,
  updateCharacterInfo,
  resetCharacterInfo,
  uploadCharacterPicture,
  getChats,
  getChatMessages,
  sendMessage,
  sendRandomMessage,
  createChat,
  editMessage,
  sendMessageFeedback,
  voiceMessage,
};
