import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const search = z.object({
  withProfile: z.boolean().optional(),
});

export const Route = createFileRoute('/_layout/_layout-conversations/conversations/$chatId')({
  validateSearch: search,
  onEnter: () => {},
});
